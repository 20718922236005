import React, { useEffect, useState } from "react";
import "./Footer.css";
import { Row, Col } from "antd";
import { Link, useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const year = new Date().getFullYear();
  const navigate = useNavigate();
  const [currentCity, setCurrentCity] = useState("");
  const company = [
    {
      title: "About",
      url: "#",
    },
    {
      title: "Advertise with Us",
      url: "#",
    },
    {
      title: "Partner With Us - Add garages",
      url: "#",
    },
    {
      title: "Help",
      url: "#",
    },
    {
      title: "Career",
      url: "#",
    },
    {
      title: "Terms",
      url: "/terms-conditions",
    },
    {
      title: "Privacy",
      url: "#",
    },
  ];
  const carServices = [
    {
      title: "Car Washing",
      url: "/chandigarh/car-washing",
    },
    {
      title: "Car Repair",
      url: "/advertise",
    },
    {
      title: "Car Service",
      url: "/partner",
    },
    {
      title: "Car Tyre Alignment",
      url: "/help",
    },
    {
      title: "Car Painting",
      url: "/career",
    },
    {
      title: "Car Coating",
      url: "/about",
    },
    {
      title: "Car Denting",
      url: "/advertise",
    },
    {
      title: "Car AC Service",
      url: "/partner",
    },
    {
      title: "Car Central Locking",
      url: "/help",
    },
    {
      title: "Car Foam Washing",
      url: "/career",
    },
  ];
  const bikeServices = [
    {
      title: "Bike Washing",
      url: "/about",
    },
    {
      title: "Bike Repair",
      url: "/advertise",
    },
    {
      title: "Bike Service",
      url: "/partner",
    },
    {
      title: "Bike Tyre Alignment",
      url: "/help",
    },
    {
      title: "Bike Painting",
      url: "/career",
    },
    {
      title: "Bike Coating",
      url: "/about",
    },
    {
      title: "Bike Denting",
      url: "/advertise",
    },
    {
      title: "Bike Foam Washing",
      url: "/career",
    },
  ];
  const connect = [
    {
      id: 1,
      title: "Email",
      url: "admin@vecrep.com",
    },
    {
      id: 2,
      title: "Mobile No.",
      url: "+919056262909",
    },
  ];

  useEffect(() => {
    const currentCity = localStorage.getItem("currentCity")?.toLowerCase();
    if (currentCity) {
      setCurrentCity(currentCity);
    }
  }, []);
  const transformService = (service: string) => {
    return service
      .split(" ")
      .map((word) => word)
      .join("-");

    // navigate(`${currentCity}/${selectedService}`);
  };
  return (
    <>
      <footer>
        <div className="border-bottom resp-center">
          <Row className="footer-row">
            <Col md={6} sm={24} className="mb-3 text-center">
              <img
                className="img-fluid"
                src="/assets/images/logo.png"
                alt="Logo_image"
                width={200}
                height={60}
              />
              <p>Book Garage Online</p>
              <div className="social-media-icons">
                <a
                  href="https://www.youtube.com/channel/UCmNtrCMVJqU7Ws2fKf1H7eg"
                  target="_blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://www.facebook.com/vrvecrep" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a href="https://twitter.com/VR_vecrep" target="_blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="https://www.instagram.com/vr_vecrep/" target="_blank">
                  <i className="fab fa-instagram"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/vrvecrep/"
                  target="_blank"
                >
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
              <div>
                <ul className="list-group">
                  {connect.map((item, index) => (
                    <>
                      <li
                        key={index}
                        className="list-group-item bg-transparent border-0 p-0 mb-2"
                      >
                        <a
                          href={
                            item.id === 1
                              ? "mailto:" + item.url
                              : "tel:" + item.url
                          }
                        >
                          {item.title}
                        </a>
                      </li>
                    </>
                  ))}
                </ul>
              </div>
            </Col>
            <Col md={18} sm={24}>
              <Row className="service-row">
                <Col
                  md={6}
                  xs={24}
                  sm={12}
                  span={24}
                  className="p-0 float-left mb-3"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Company
                  </h5>
                  <ul className="list-group">
                    {company.map((item, index) => (
                      <>
                        <li
                          key={index}
                          className="list-group-item bg-transparent border-0 p-0 mb-2"
                        >
                          <Link to={item.url}>{item.title}</Link>
                        </li>
                      </>
                    ))}
                  </ul>
                </Col>

                <Col
                  md={6}
                  xs={24}
                  sm={12}
                  span={24}
                  className="p-0 mb-3 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Car Services
                  </h5>
                  <ul className="list-group">
                    {carServices.map((item, index) => (
                      <>
                        <li
                          key={index}
                          className="list-group-item bg-transparent border-0 p-0 mb-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          <a
                            href={
                              currentCity  + "/" + transformService(item.title)
                            }
                           
                          >
                            {item.title} In {currentCity}
                          </a>
                        </li>
                      </>
                    ))}
                  </ul>
                </Col>

                <Col
                  md={6}
                  xs={24}
                  sm={12}
                  span={24}
                  className="mb-3 p-0 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Bike Services
                  </h5>
                  <ul className="list-group">
                    {bikeServices.map((item, index) => (
                      <>
                        <li
                          key={index}
                          className="list-group-item bg-transparent border-0 p-0 mb-2"
                          style={{ textTransform: "capitalize" }}
                        >
                          <a
                            href={
                              currentCity + "/" +  transformService(item.title)
                            }
                          >
                            {item.title} In {currentCity}
                          </a>
                        </li>
                      </>
                    ))}
                  </ul>
                </Col>

                <Col
                  md={6}
                  xs={24}
                  sm={12}
                  span={24}
                  className="mb-3 p-0 float-left"
                >
                  <h5 className="mb-4 font-weight-bold text-uppercase">
                    Popular Searches
                  </h5>
                  <ul className="list-group">
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Car garages in Chandigarh
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Car garages in Mohali
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Car garages in Zirakpur
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Car garages in Kharar
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Car garages in Panchkula
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Bike garages in Chandigarh
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Bike garages in Mohali
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Bike garages in Zirakpur
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Bike garages in Kharar
                      </a>
                    </li>
                    <li className="list-group-item bg-transparent border-0 p-0 mb-2">
                      <a href="javascript:void(0)">
                        Bike garages in Panchkula
                      </a>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
        <div className="footer">
          <div className="left">
            {" "}
            @ {year} V2R autoinfinite private Pvt. Ltd
          </div>
          {/* <div className="right">
            <div className="footer-links">
              <a href="terms-conditions" target="_blank">Terms</a>
              <a href="privacy-policy" target="_blank">Privacy</a>
            </div>
          </div> */}
        </div>
      </footer>
    </>
  );
};

export default Footer;
