import React, { useState } from "react";
import "./searchServices.css";
import { Col } from "antd";
import parser from 'html-react-parser'

interface Content {
  title: string;
  description: string;
}

interface ServicesOfferedProps {
  WhyVecrepContent: Content[] | undefined;
}
const ServicesOffered: React.FC<ServicesOfferedProps> = ({
  WhyVecrepContent,
}) => {
  console.log("services offered 14", WhyVecrepContent);
  const [selectedTab, setSelectedTab] = useState("tab1");

  const handleTabChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedTab(e.target.value);
  };

  return (
    <>
      <Col span={24} className="tabset">
        <input
          type="radio"
          name="tabset"
          id="tab1"
          aria-controls="marzen"
          value="tab1"
          checked={selectedTab === "tab1"}
          onChange={handleTabChange}
        />
        <label htmlFor="tab1">Description</label>
        <input
          type="radio"
          name="tabset"
          id="tab2"
          aria-controls="rauchbier"
          value="tab2"
          checked={selectedTab === "tab2"}
          onChange={handleTabChange}
        />
        <label htmlFor="tab2">Services Offered</label>
        <input
          type="radio"
          name="tabset"
          id="tab3"
          aria-controls="rauchbier"
          value="tab3"
          checked={selectedTab === "tab3"}
          onChange={handleTabChange}
        />
        <label htmlFor="tab3">Common Problems</label>
        <input
          type="radio"
          name="tabset"
          id="tab4"
          aria-controls="tab4"
          value="tab4"
          checked={selectedTab === "tab4"}
          onChange={handleTabChange}
        />
        <label htmlFor="tab4">Maintenance Tips</label>
        <div className="tab-panels">
          {WhyVecrepContent &&
            WhyVecrepContent.map((item: any, index: number) => (
              <>
                <section
                  key={index} // Ensure each section has a unique key
                  id={`tab${index + 1}`} // Dynamically assign IDs based on the index
                  className={`tab-panel font-weight-400 ${
                    selectedTab === `tab${index + 1}` ? "active" : "not-active"
                  }`}
                >
                  <span>{parser(item.description)}</span>
                </section>
              </>
            ))}
          {/* <section
            id="marzen"
            className={`tab-panel font-weight-400 ${
              selectedTab === "tab1" ? "active" : "not-active"
            }`}
          >
            <span>
              Tyres are an essential part of your bike as they not only ensure
              proper traction with the road but also help you maintain a proper
              balance. Tyres also ensure that you can brake properly when
              required. You should definitely keep your bike tyres in check. If
              they are worn out or the tread is not good enough, you should not
              delay changing your bike’s tyres.
            </span>
            <span>
              VecRep in Chandigarh has partnered with various workshops that
              offer a wide range of bike tyre replacement options such as MRF,
              Bridgestone, Apollo, Michelin, CEAT, JK tyre, TVS, etc.
            </span>
          </section>
          <section
            id="rauchbier"
            className={`tab-panel font-weight-400 ${
              selectedTab === "tab2" ? "active" : "not-active"
            }`}
          >
            <p>
              <span>
                VecRep in Chandigarh offers various bike tyre care services
                through workshops spread across the city. You can choose any
                workshop from the list and get the following services:
              </span>
            </p>
            <ul className="font-weight-400">
              <li>
                <span>Bike tyre replacement in Chandigarh</span>
              </li>
              <li>
                <span>Bike wheel balancing in Chandigarh</span>
              </li>
              <li>
                <span>Bike wheel alignment in Chandigarh</span>
              </li>
              <li>
                <span>Bike wheel rotation service in Chandigarh</span>
              </li>
            </ul>
            <p className="mb-02">
              <span>
                We offer a wide range of bike tyre brands depending on your
                needs. You can choose from fuel-efficient tyres,
                performance-based tyres, off-road tyres etc.
              </span>
            </p>
          </section>
          <section
            id="dunkles"
            className={`tab-panel font-weight-400 ${
              selectedTab === "tab3" ? "active" : "not-active"
            }`}
          >
            <p>
              <span>
                Bike tyres are an essential part of your bike and you should not
                ignore the condition of bike tyres at all. You must keep a check
                on tyre pressure and maintain proper tyre pressure at all times.
                Here are some common problems that you might face if your bike
                tyres are not in proper shape.
              </span>
            </p>
            <ul>
              <li>
                <span>Reduced fuel economy even after proper servicing</span>
              </li>
              <li>
                <span>
                  Bike tyres are worn out, you feel that there is no proper grip
                  while driving.
                </span>
              </li>
              <li>
                <span>
                  Bike skidding while braking or braking distance increased
                </span>
              </li>
            </ul>
            <p>
              <span>
                If you see such signs, get your bike tyres replaced asap by
                garages listed on VecRep in Chandigarh.
              </span>
            </p>
          </section>
          <section
            id="tab4"
            className={`tab-panel font-weight-400 ${
              selectedTab === "tab4" ? "active" : "not-active"
            }`}
          >
            <p>
              <span>
                Want to maintain your bike tyres for a long? Here are some
                maintenance tips that can enhance the life of your bike tyres:
              </span>
            </p>
            <ul>
              <li>
                <span>
                  Maintain optimum pressure in the bike tyres. Too low or too
                  high can lead to uneven wear and tear.
                </span>
              </li>
              <li>
                <span>
                  Avoid bad roads. If not, drive slowly over such roads.
                </span>
              </li>
              <li>
                <span>Keep your wheels aligned and balanced.</span>
              </li>
              <li>
                <span>
                  Get your car bike rotated every 5,000-6,000kms for a better
                  life.
                </span>
              </li>
            </ul>
          </section> */}
        </div>
      </Col>
    </>
  );
};

export default ServicesOffered;
