import React, { useState, useEffect } from "react";
import "./searchServices.css";
import {
  AimOutlined,
  ArrowDownOutlined,
  ArrowUpOutlined,
  DownOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Modal, Input, Tabs, List, Avatar, Dropdown } from "antd";
import {
  faLocationDot,
  faSearch,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type ServiceType = {
  _id: string;
  distance: number;
  garageName: string;
  phoneNumber: string;
  email: string;
  address: string;
  accountStatus: string;
  aboutGarage: string;
  urlLink: string;
  services: {
    name: string;
    type: string;
    id: string;
  }[];
};

const TabPane = Tabs.TabPane;
interface TopHeaderProps {
  data: any;
  onClickSort: () => void;
  onClickNear: () => void;
  vehicleType: string;
  selectedService: string;
  searchcity: string;
  selectedArea: string;
  garageListSize: number;
}

interface MyObject {
  makeName: string;
  vehicleType: number;
  modelName: string;
  fuelType: string;
}

const TopHeader: React.FC<TopHeaderProps> = (props) => {
  console.log("propspropsprops topheader", props);
  const { data, onClickSort, onClickNear } = props;
  const totalGarages = localStorage.getItem('totalGarages');
  const [showVehicleModel, setShowVehicleModel] = useState(false);

  const [modalVisible, setModalVisible] = useState(false);
  const [isDropdownVisible2, setIsDropdownVisible2] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Car");
  const [responseData, setResponseData] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState<ItemType[]>([]);
  const [searchData, setSearchData] = useState<ItemType[]>([]);
  const [myObject, setMyObject] = useState<MyObject>({
    makeName: "",
    vehicleType: 0,
    modelName: "",
    fuelType: "",
  });
  const [selectedBrand, setSelectedBrand] = useState("brand Name");
  const [isFetchData2, setIsFetchData2] = useState<boolean>(false);
  const [isFetchData3, setIsFetchData3] = useState<boolean>(false);
  const [selectedMakeName, setSelectedMakeName] = useState<string>("");

  const [selectedModel, setSelectedModel] = useState<string>("");

  useEffect(() => {
    console.log('topheader useeffect', props.selectedService);
    fetchData(vehicle);
  }, []);

  const fetchData = async (vehicleType: number) => {
    console.log('topheader fetchData', props.selectedService);
    setIsLoading(true);
    console.log(vehicleType);

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_service_list",
        {
          vehicleType, // Use the selected vehicle type here
        }
      );
      console.log(response);
      if (response.status === 200) {
        setResponseData(response.data);
        setData(response.data.data);
        setMyObject((prevObject) => ({
          ...prevObject,
          vehicleType: vehicleType,
        }));
        setSearchData(response.data.data);
      } else if (response.status === 400) {
        setResponseData("Error while fetching");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    console.log(myObject);
  };
  console.log(responseData?.data);
  console.log(data);
  const vehicle = 1;

  const handleTabChange = (activeKey: string) => {
    // Determine the vehicle type based on the selected tab
    const vehicleType = activeKey === "Car" ? 1 : 2; // You can adjust this as needed
    setSelectedTab(activeKey);
    setMyObject((prevObject) => ({
      ...prevObject,
      makeName: "",
      modelName: "",
      fuelType: "",
    }));
    setIsFetchData2(false);
    setIsFetchData3(false);
    // Fetch data based on the selected tab
    fetchData(vehicleType);
  };

  const handlePopup = () => {
    setModalVisible(true);
  };

  const handleModalCancel = () => {
    setModalVisible(false);
  };

  const toggleDropdown2 = () => {
    setIsDropdownVisible2(!isDropdownVisible2);
  };

  const handleSearch = (searchValue: string) => {
    searchValue = searchValue.trim();

    if (searchValue === "") {
      setData(searchData);
    } else {
      const filteredData = searchData.filter((item: ItemType) => {
        return item.name.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
      });

      setData(filteredData);
    }
  };

  type ItemType = {
    logo: string;
    name: string;
    imagePath: string;
    garageName: string;
    type: string;
    id: string;
  };
  const fetchData2 = async (requestData: any) => {
    const { vehicleType, makeName } = requestData;
    setIsLoading(true);
    console.log(vehicleType);
    setSelectedMakeName(makeName);
    console.log(makeName);

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_model_list",
        {
          makeName,
          vehicleType,
        }
      );
      console.log(response);
      if (response.status === 200) {
        setIsFetchData2(true);
        setResponseData(response.data);
        setData(response.data.data);
        setSearchData(response.data.data);
        setMyObject((prevObject) => ({
          ...prevObject,
          makeName: makeName,
        }));
      } else if (response.status === 400) {
        setResponseData("Error while fetching");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    console.log(myObject);
  };

  const fetchData3 = async (requestData: any) => {
    const { vehicleType, makeName, modelName } = requestData;
    setIsLoading(true);
    console.log(vehicleType);
    console.log(makeName);

    const BikeData = [
      {
        name: "Petrol",
        id: "64b8cb117ee76a18e2b409c5",
        logo: "https://thumbs.dreamstime.com/b/closeup-motorcycle-fueling-human-flammable-production-storage-gas-motorbike-filling-fuel-hand-202399510.jpg",
        imagePath: "",
        garageName: "",
        type: "",
      },
      {
        name: "EV",
        id: "64b8cb117ee76a18e2b409b8",
        logo: "https://thumbs.dreamstime.com/b/electric-bike-electric-vehicle-ev-charging-dock-plug-hybrid-smart-motorcycle-battery-electric-bike-chage-station-electric-275593145.jpg",
        imagePath: "",
        garageName: "",
        type: "",
      },
    ];
    const CarData = [
      {
        name: "Petrol",
        id: "64b8cb117ee76a18e2b409c5",
        logo: "assets/images/petrol-car.png",
        imagePath: "",
        garageName: "",
        type: "",
      },
      {
        name: "Diesel",
        id: "64b8cb117ee76a18e2b409c7",
        logo: "assets/images/petrol-car.png",
        imagePath: "",
        garageName: "",
        type: "",
      },
      {
        name: "CNG",
        id: "64b8cb117ee76a18e2b409b8",
        logo: "assets/images/CNG-car.png",
        imagePath: "",
        garageName: "",
        type: "",
      },
      {
        name: "EV",
        id: "64b8cb117ee76a18e2b409c9",
        logo: "https://media.npr.org/assets/img/2020/11/24/gettyimages-1276026446-7a4e115d54068e288d1694b92b495de9c722f1f4-s1100-c50.jpg",
        imagePath: "",
        garageName: "",
        type: "",
      },
    ];

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_fuel_list",
        {
          makeName,
          vehicleType,
          modelName,
        }
      );
      console.log(response.data);
      if (response.status === 200) {
        setResponseData(response.data);
        setIsFetchData3(true);
        setIsFetchData2(false);
        {
          vehicleType === 1 ? setData(CarData) : setData(BikeData);
        }
        {
          vehicleType === 1 ? setSearchData(CarData) : setSearchData(BikeData);
        }
        // setSearchData(response.data.data);
        console.log("fuel_type");
        setMyObject((prevObject) => ({
          ...prevObject,
          modelName: modelName,
        }));
      } else if (response.status === 400) {
        setResponseData("Error while fetching");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
    console.log(myObject);
  };

  const handleBrandSelect = (
    vehicleType: number,
    selectedMakeName: string,
    modelName?: string
  ) => {
    if (modelName) {
      const requestData = {
        makeName: selectedMakeName,
        vehicleType: vehicleType,
        modelName: modelName,
      };
      fetchData3(requestData);
      setSelectedModel(modelName);

      // Extract vehicle details to parent component
      // onVehicleDetailsChange({
      //   makeName: selectedMakeName,
      //   vehicleType: vehicleType,
      //   modelName: modelName,
      //   fuelType: myObject.fuelType,
      // });
    } else {
      const requestData = {
        makeName: selectedMakeName,
        vehicleType: vehicleType,
      };
      fetchData2(requestData);
      setSelectedBrand(selectedMakeName);

      // Extract vehicle details to parent component
      // onVehicleDetailsChange({
      //   makeName: selectedMakeName,
      //   vehicleType: vehicleType,
      //   modelName: "",
      //   fuelType: myObject.fuelType,
      // });
    }
  };

  const handleObject = (fuel_type: string) => {
    localStorage.setItem("vehicleType", myObject?.vehicleType.toString());
    localStorage.setItem("brand", myObject?.makeName);
    localStorage.setItem("model", myObject?.modelName);
    localStorage.setItem("fuelType", fuel_type);
    setShowVehicleModel(false);
    // window.location.reload();
  };
  const handleFuelSelect = (fuelType: string) => {
    setMyObject((prevObject) => ({
      ...prevObject,
      fuelType: fuelType,
    }));
    handleObject(fuelType);

    // Extract vehicle details to parent component
    // onVehicleDetailsChange({
    //   makeName: myObject.makeName,
    //   vehicleType: myObject.vehicleType,
    //   modelName: myObject.modelName,
    //   fuelType: fuelType,
    // });
  };

  // Function to format selectedService and city
  const formatString = (str: string) => {
    if (!str) return "";
    return str.toLowerCase().split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const formatSelectedService = (selectedService: string) => {
    if (!selectedService) return "";

    // Split the string by hyphens, capitalize each word, then join with spaces
    return formatString(selectedService.replace(/-/g, ' '));
  };
  const returnVehicle = (vehicleType: string) => {
    return vehicleType == "1" ? "Car" : "Bike";
  };
  console.log(myObject);
  console.log('topHeader 390', data);
  const listOfdata = data?.list;
  const vehicleType = localStorage.getItem("vehicleType");
  const vehicleBrand = localStorage.getItem("brand");
  const vehicleModel = localStorage.getItem("model");
  const vehicleFuel = localStorage.getItem("fuelType");
  console.log(vehicleType, vehicleBrand, vehicleModel, vehicleFuel);
  console.log(typeof vehicleBrand);
  return (
    <div>
      <div className="top-head">
        <div>
          <h1>
            Best {returnVehicle(props.vehicleType)}{" "}
          {(props && props.selectedService && props.searchcity)
              ? formatSelectedService(props.selectedService) + " Service in " + formatString(props.searchcity)
              : !data?.keyWord
              ? "Water wash"
              : data?.keyWord + " Service in " + data?.city === undefined
              ? "Hyderabad "
              : data?.city}
          </h1>
        </div>
        <div>
          <h1 className="result">({props.garageListSize} Results)</h1>
        </div>
      </div>

      <div className="service-main">
        {/* <div
          onClick={() => setShowVehicleModel(true)}
          style={{ cursor: "pointer" }}
        >
          <p className="modal">
            {vehicleBrand === "undefined" ? "Maruti Suzuki" : vehicleBrand}/
            {vehicleModel === "undefined" ? "Swift" : vehicleModel}/
            {vehicleFuel === "undefined" ? "Petrol" : vehicleFuel}
          </p>
        </div> */}
        <div className="main">
          <div>
            <button className="sort" onClick={onClickSort}>
              Sort
              <ArrowUpOutlined
                style={{
                  width: "15.5px",
                  height: "15.46px",
                  paddingLeft: "6px",
                }}
              />{" "}
              <ArrowDownOutlined
                style={{ width: "11.5px", height: "15.46px" }}
              />
            </button>
          </div>
          <div>
            <button className="sort">
              {" "}
              Rated
              <StarFilled
                style={{
                  width: "15.5px",
                  height: "15.46px",
                  paddingLeft: "6px",
                }}
              />
            </button>
          </div>
          <div>
            <button className="sort" onClick={onClickNear}>
              Near Me
              <AimOutlined
                style={{
                  width: "15.5px",
                  height: "15.46px",
                  paddingLeft: "6px",
                }}
              />
            </button>
          </div>
          <div>
            <button className="sort sort-last">
              Category{" "}
              <DownOutlined
                style={{
                  width: "15.5px",
                  height: "15.46px",
                  paddingLeft: "6px",
                }}
              />
            </button>
          </div>
        </div>
      </div>
      <div>
        <Modal
          visible={showVehicleModel}
          onCancel={() => setShowVehicleModel(false)}
          centered
          width={350}
          footer={null}
        >
          <div
            style={{
              backgroundColor: "white",
            }}
            className="tabs-class invisible-scrollbar"
          >
            <Tabs
              defaultActiveKey="car"
              tabBarGutter={100}
              tabBarStyle={{ width: "100%" }}
              centered
              indicatorSize={60}
              onChange={handleTabChange}
            >
              <TabPane tab="Car" key="Car" style={{ margin: "0px 10px" }}>
                <Input
                  style={{ marginBottom: "10px" }}
                  placeholder="Search by brand, model, or fuel"
                  addonAfter={<FontAwesomeIcon icon={faSearch} />}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <div style={{ margin: "0px 10px", paddingBottom: "4px" }}>
                  {selectedTab}
                  {myObject.makeName && (
                    <span
                      onClick={() => {
                        setMyObject((prevObject) => ({
                          ...prevObject,
                          makeName: "",
                          modelName: "",
                        }));
                        setIsFetchData2(false);
                        setIsFetchData3(false);
                        setSelectedMakeName("");
                        setSelectedModel("");
                        fetchData(myObject.vehicleType);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginLeft: "5px",
                      }}
                    >
                      &gt; {myObject.makeName}
                    </span>
                  )}
                  {myObject.modelName && (
                    <span
                      onClick={() => {
                        setMyObject((prevObject) => ({
                          ...prevObject,
                          modelName: "",
                        }));
                        setIsFetchData3(false);
                        setSelectedModel("");
                        const reqData = {
                          makeName: myObject.makeName,
                          vehicleType: myObject.vehicleType,
                        };
                        fetchData2(reqData);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginLeft: "5px",
                      }}
                    >
                      &gt; {myObject.modelName}
                    </span>
                  )}
                </div>

                <List
                  style={{ height: "220px", overflow: "scroll" }}
                  className="invisible-scrollbar"
                  itemLayout="horizontal"
                  dataSource={Data}
                  renderItem={(item: ItemType) => (
                    <List.Item
                      style={{ padding: 0, cursor: "pointer" }}
                      onClick={() => {
                        if (isFetchData2) {
                          handleBrandSelect(1, selectedMakeName, item.name); // Pass three arguments
                        }
                        if (isFetchData3) {
                          handleFuelSelect(item.name);
                        } else {
                          handleBrandSelect(1, item.name); // Pass two arguments
                        }
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.logo ||
                              "https://st2.depositphotos.com/1003369/10994/i/950/depositphotos_109944072-stock-photo-car-refueling-on-a-petrol.jpg"
                            }
                          />
                        }
                        title={item.name}
                        style={{ paddingLeft: "10px" }}
                      />
                    </List.Item>
                  )}
                />
              </TabPane>
              <TabPane tab="Bike" key="Bike" style={{ margin: "0px 10px" }}>
                <Input
                  style={{ marginBottom: "10px" }}
                  placeholder="Search by brand, model, or fuel"
                  addonAfter={<FontAwesomeIcon icon={faSearch} />}
                  onChange={(e) => handleSearch(e.target.value)}
                />
                <div style={{ margin: "0px 10px", paddingBottom: "4px" }}>
                  {selectedTab}
                  {myObject.makeName && (
                    <span
                      onClick={() => {
                        setMyObject((prevObject) => ({
                          ...prevObject,
                          makeName: "",
                          modelName: "",
                        }));
                        setIsFetchData2(false);
                        setIsFetchData3(false);
                        setSelectedMakeName("");
                        fetchData(myObject.vehicleType);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginLeft: "5px",
                      }}
                    >
                      &gt; {myObject.makeName}
                    </span>
                  )}
                  {myObject.modelName && (
                    <span
                      onClick={() => {
                        setMyObject((prevObject) => ({
                          ...prevObject,
                          modelName: "",
                        }));
                        setIsFetchData3(false);
                        const reqData = {
                          makeName: myObject.makeName,
                          vehicleType: myObject.vehicleType,
                        };
                        fetchData2(reqData);
                      }}
                      style={{
                        cursor: "pointer",
                        color: "blue",
                        marginLeft: "5px",
                      }}
                    >
                      &gt; {myObject.modelName}
                    </span>
                  )}
                </div>
                <List
                  style={{ height: "220px", overflow: "scroll" }}
                  className="invisible-scrollbar"
                  itemLayout="horizontal"
                  dataSource={Data}
                  renderItem={(item: ItemType) => (
                    <List.Item
                      style={{ padding: 0, cursor: "pointer" }}
                      onClick={() => {
                        if (isFetchData2) {
                          console.log("if statement");
                          handleBrandSelect(2, selectedMakeName, item.name); // Pass three arguments
                        }
                        if (isFetchData3) {
                          handleFuelSelect(item.name);
                        } else {
                          console.log("else statement");
                          handleBrandSelect(2, item.name); // Pass two arguments
                        }
                      }}
                    >
                      <List.Item.Meta
                        avatar={
                          <Avatar
                            src={
                              item.logo ||
                              "https://www.livemint.com/lm-img/img/2023/06/07/600x338/Petrol_1686165007302_1686165007551.jpg"
                            }
                          />
                        }
                        title={item.name}
                        style={{ paddingLeft: "10px" }}
                      />
                    </List.Item>
                  )}
                />
              </TabPane>
            </Tabs>
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default TopHeader;
