import React, { useState } from "react";
import "./helpGrowSection.css";
import { Col, Tabs } from "antd";

const { TabPane } = Tabs;

const HelpGrowSection: React.FC = () => {
  const [activeTab, setActiveTab] = useState("menu1"); // State to manage active tab

  // Function to handle tab change
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };
  return (
    <>
      <Col lg={12}>
        <div className="help-grow-tab">
          <ul className="nav nav-tabs">
            <li className="nav-item">
              <a className={`nav-link ${activeTab === "menu1" ? "active" : ""}`} onClick={() => handleTabChange("menu1")} data-bs-toggle="tab" >
                <div className="grow-tab">
                  <div className="grow-tab-img">
                    <img
                      src="https://vecrep.in/wp-content/uploads/2022/08/sEARCH_BOOK.png"
                      alt="grow-tab image"
                      className="img-fluid"
                      style={{width: '100%'}}
                    />
                  </div>
                  <div className="grow-tab-cont">
                    <h5>Search, Compare &amp; Book </h5>
                    <p>
                      Browse through a broad choice of verified garages near
                      you, compare based on various parameters and book a garage
                      of your choice instantly.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </li>

            <li className="nav-item">
              <a className={`nav-link ${activeTab === "menu2" ? "active" : ""}`} onClick={() => handleTabChange("menu2")} data-bs-toggle="tab" >
                <div className="grow-tab">
                  <div className="grow-tab-img">
                    <img
                      src="https://vecrep.in/wp-content/uploads/2022/08/rELATIME-TRACK.png"
                      alt="grow-tab image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="grow-tab-cont">
                    <h5>Real time Updates &amp; Digital Records</h5>
                    <p>
                      You will be kept updated with the progress of the work
                      being done, also you can track your vehicle maintenance
                      record anytime for a better resale value.{" "}
                    </p>
                  </div>
                </div>
              </a>
            </li>

            <li className="nav-item">
              <a className={`nav-link ${activeTab === "menu3" ? "active" : ""}`} onClick={() => handleTabChange("menu3")} data-bs-toggle="tab">
                <div className="grow-tab">
                  <div className="grow-tab-img">
                    <img
                      src="https://vecrep.in/wp-content/uploads/2022/08/save.png"
                      alt="grow-tab image"
                      className="img-fluid"
                    />
                  </div>
                  <div className="grow-tab-cont">
                    <h5>Instant Savings &amp; Extra Discounts</h5>
                    <p>
                      With VecRep, you receive instant access to the best offers
                      and discounts, which will undoubtedly help you save more
                      and stress less.
                    </p>
                  </div>
                </div>
              </a>
            </li>
          </ul>
        </div>
      </Col>
      <Col lg={12}>
        <div className="help-grow-tabContent">
          <div className="tab-content">
            <div className={`tab-pane container ${activeTab === "menu1" ? "active" : "fade"}`} id="menu1">
              <img
                src="https://vecrep.in/wp-content/uploads/2022/08/search-Compare-Book.png"
                alt="Search, Compare & Book"
                className="img-fluid"
              />
            </div>
            <div className={`tab-pane container ${activeTab === "menu2" ? "active" : "fade"}`} id="menu2">
              <img
                src="https://vecrep.in/wp-content/uploads/2022/08/Real-time-update.png"
                alt="Real time Updates & Digital Records"
                className="img-fluid"
              />
            </div>
            <div className={`tab-pane container ${activeTab === "menu3" ? "active" : "fade"}`} id="menu3">
              <img
                src="https://vecrep.in/wp-content/uploads/2022/08/Instant-Saving500-×-700-px.png"
                alt="Instant Savings & Extra Discounts"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default HelpGrowSection;
