import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  AimOutlined,
  EnvironmentOutlined,
  PhoneFilled,
  PhoneOutlined,
  ShareAltOutlined,
  StarFilled,
} from "@ant-design/icons";
import { Modal } from "antd";
import GetForm from "./getForm";
import SignUpForm from "../Auth/signup";
import LoginForm from "../Auth/Login";
import BookForm from "./BookForm";
import Demo from "../GarageDetails/Share";

import "./listGarages.css";
import axios from "axios";
import CallGetForm from "./Callget";
import { Helmet, HelmetProvider } from "react-helmet-async";

interface DataType {
  key: string;
  name: string;
  address: string;
}

interface GarageData {
  _id: string;
  distance: number;
  garageName: string;
  phoneNumber: string;
  email: string;
  address: string;
  accountStatus: string;
  aboutGarage: string;
  urlLink: string;
  services: {
    name: string;
    type: string;
    id: string;
  }[];
}
interface Service {
  name: string;
  type: string;
  id: string;
}

interface ListGaragesProps {
  data: GarageData[];
  vehicleData: {
    vehicleType: number;
    makeName: string;
    modelName: string;
    fuelType: string;
  };
  ad2: string;
  vehicleType: string;
  selectedService: string;
  searchcity: string;
  selectedArea: string;
  vehicleName: string;
  onShowTable: (garages: any, dataPresent: any) => void;
  handleListSize: (size: number) => void;
}

const ListGarages: React.FC<ListGaragesProps> = ({
  data,
  vehicleData,
  ad2,
  vehicleType,
  selectedService,
  searchcity,
  selectedArea,
  vehicleName,
  onShowTable,
  handleListSize
}) => {
  console.log(
    "propspropsprops listgarages",
    data,
    vehicleType,
    selectedService,
    searchcity,
    vehicleName,
    selectedArea
  );
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const [garageId, setGarageId] = useState("");
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setisSignupModelOpen] = useState(false);
  const [shareModal, setShareModal] = useState(false);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [modalServices, setModalServices] = useState<Service[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [resp, setResp] = useState([]);
  const [singleGarageResp, setSingleGarageResp] = useState({});

  const handleViewMore = (services: Service[]) => {
    setModalServices(services);
    setShowModal(true);
  };

  const close = () => {
    setModal(false);
    setModal1(false);
    setModal2(false);
  };
  const token = localStorage.getItem("token");
  const onCloseSignupModal = () => {
    setisSignupModelOpen(false);
  };
  console.log(vehicleData?.vehicleType);
  const vehicledata = vehicleData?.vehicleType;
  const vehicleDetails = vehicleData;
  const openLoginModal = () => {
    setModal(false);
    setModal1(false);
    setModal2(false);

    setIsLoginModalOpen(true);
  };
  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  const openSignupModal = () => {
    setisSignupModelOpen(true);
  };

  console.log(vehicleDetails);

  const handleClick = (
    garageId: string,
    garageName: string,
    garageAddress: string
  ) => {
    console.log("garageId:", garageId);
    // navigate("/garagedetails", {
    //   state: { apiData: GarageId, vehicle: vehicleDetails },
    // });
    fetchDataFromUrlForSingleGarage(searchcity, "", "", garageName, garageId);
    console.log("125 list garages apiData:", singleGarageResp);
    // navigate(`/${searchcity}/${garageName}/${garageAddress}/${garageId}`, {
    //   state: {apiData: singleGarageResp, vehicle: vehicleDetails}
    // })
  };

  useEffect(() => {
    let userArea = "";
    // console.log("selectedArea ==>", selectedArea);
    const area = selectedArea.split("-");
    if (area.length > 1) {
      area.map((part: any, i: number) => {
        if(i == 0) {
          userArea = part;
        } else {          
          userArea = userArea + " " + part;
        }
      });
      selectedArea = userArea;
    }
    if(searchcity) {
      fetchDataFromUrl(10, 0, searchcity, selectedArea, vehicleName,selectedService);
    }    
  }, [searchcity, vehicleType, selectedService, selectedArea]);

  const fetchDataFromUrl = async (
    pageCount: number,
    pageNumber: number,
    city?: string,
    area?: string,
    vehicleName?: string,
    searchKey?: string
  ) => {
    console.log("topheader listgarages", selectedService);
    setIsLoading(true);
    const requestBody = {
      city: city || "", // If city is undefined, an empty string will be used
      area: area || "",
      vechileType: vehicleType || "",
      searchKey: vehicleName+' '+searchKey || "",
      pageCount: pageCount,
      pageNumber: pageNumber,
    };
    console.log("fetchGaragesByService requestBody", requestBody);
    try {
      const response = await axios.post(
        "https://api.vecrep.com/fetchGaragesByService",
        requestBody
      );
      console.log("fetchGaragesByService", response.data);
      if (response) {
        if (response.data) {
          setResp(response.data.data);  
          let test;
          console.log("resp list garages", resp);
          console.log("resp list garages resp.data", response.data.data);
          if (response.data.data.length == 0) {
            test = "empty";
          } else {
            test = "not empty";
            console.log('garageListSizeSet 209', response.data.data.length);
            changeListGarageSize(response.data.data.length);
          }
          onShowTable(response.data.data, test);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const changeListGarageSize = (size: number) => {
    handleListSize(size);
  }

  const fetchDataFromUrlForSingleGarage = async (
    city?: string,
    area?: string,
    service?: string,
    garageName?: string,
    garageId?: string
  ) => {
    const requestedData = {
      city: city || "",
      area: area || "",
      // vechileType: vehicleType || "",
      service: service || "",
      garageName: garageName || "",
      garageId: garageId || "",
    };
    
    try {
      const response = await fetch("https://api.vecrep.com/fetchGarages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data) {
        console.log("url response", data.data);
        // return;
        const garageData = data.data;
        
        console.log("garage data url", garageData);
        
        // setSingleGarageResp(garageData);
        let garageName = garageData?.garageName
          .split(" ")
          .map((word: any) => word.toLowerCase())
          .join("-");
        let area = garageData?.area
          .split(" ")
          .map((word: any) => word.toLowerCase())
          .join("-");
        navigate(
          `/${garageData.city}/${garageName}/${area}/${garageData.garageId}`,
          {
            state: { apiData: garageData, vehicle: vehicleDetails },
          }
        );
        console.log("set data url ", singleGarageResp);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  console.log(resp);
  const handleModal = async () => {
    setModal(true);
    setModal1(true);
    setModal2(true);
  };

  // const [listData, setListData] = useState([]);
  const subTaskComponents = []
    //{(resp && resp.length ? resp : data)?.map((garage: any, index: number) => (
      if(resp) {
        // console.log(resp);
        for (var i = 0; i < resp.length; i++) {
          subTaskComponents.push({
            "@type": "ListItem",
            "position": i+1,
            "name": resp[i]['name'],
            "description": resp[i]['area'],
            "url": resp[i]['urlLink']
          })  
        }
      }  
  
  return (
    <>
      <HelmetProvider>
        <Helmet>
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "ItemList",
              itemListElement: subTaskComponents,
            })}
          </script>
        </Helmet>
      </HelmetProvider>

      <div className="invisible-scrollbar services-scroll">
        {(resp.length == 0) ? <h3 className="section-title text-center"> No garage found in selected city</h3> : ''}
        {(resp && resp.length ? resp : data)?.map(
          (garage: any, index: number) => (
            <>
              <div className="garage-box">
                {index === 3 && (
                  <div className="ad-container">
                    <img
                      src={ad2}
                      alt={`Advertisement Image`}
                      style={{}}
                      className=""
                    />
                  </div>
                )}
                <div className="garage-box_container">
                  <div className="garage_data">
                    <div className="garage_data_image">
                      <img
                        className="list-image"
                        src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMHB-nuo7vhyEidjjMxoVP4TnrDkJb6_g9bw&usqp=CAU"
                        alt=""
                      />
                    </div>
                    <div className="content_container">
                      <div className="garage-col">
                        <div className="garage-name-col">
                          <h2
                            className="list-garage-name"
                            onClick={() => {
                              resp?.length
                                ? setGarageId(garage.uniqueId)
                                : setGarageId(garage._id);
                              const garageId = resp?.length ? garage.uniqueId : garage._id;
                              handleClick(
                                garage.garageId,
                                garage.name,
                                garage.address
                              );
                            }}
                            style={{
                              cursor: "pointer",
                              textTransform: "capitalize",
                            }}
                          >
                            {resp && resp.length
                              ? garage.name
                              : garage.garageName}
                          </h2>
                        </div>
                        <div
                          className=""
                          onClick={() => setShareModal(true)}
                          style={{ cursor: "pointer" }}
                        >
                          {/* <img
                          src='assets/images/symbol_share.png'
                          alt='share-symbol'
                        /> */}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="20"
                            viewBox="0 0 24 16"
                            fill="none"
                            className="share-symbol-class"
                          >
                            <path
                              d="M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.1167 8.99167 12.2377 8.975 12.363C8.95833 12.4883 8.93333 12.6007 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z"
                              fill="#565656"
                            />
                          </svg>
                        </div>
                      </div>
                      <h3>
                        <span>
                          <AimOutlined />
                        </span>{" "}
                        {garage.distance && Math.floor(garage.distance / 1000)}{" "}
                        Km away
                      </h3>
                      <div className="services_container">
                        <p className="spec-list">Specialized in</p>
                        <div className="services">
                          <>
                            {garage.services.length > 0 ? (
                              <>
                                {garage.services
                                  .slice(0, window.innerWidth >= 575 ? 2 : 1)
                                  .map((service: any, index: number) => (
                                    <div className="service" key={index}>
                                      <button className="services-list">
                                        {service.name}
                                      </button>
                                    </div>
                                  ))}
                                {garage.services.length > 2 && (
                                  <div
                                    className="service-view-more"
                                    onClick={() =>
                                      handleViewMore(garage.services)
                                    }
                                  >
                                    View More
                                  </div>
                                )}
                              </>
                            ) : (
                              <div className="error-message">
                                No services found
                              </div>
                            )}
                          </>
                        </div>
                      </div>
                      {/* <div className='rating-review-container'>
                      <div className='rating_container'>
                        <div>
                          <StarFilled className='rating ' />
                          <StarFilled className='rating rating1' />
                          <StarFilled className='rating rating1' />
                          <StarFilled className='rating rating1' />
                        </div>
                        <div className='rating-num'>
                          <p>4.0</p>
                        </div>
                      </div>
                      <div className='review_container'>
                        <a href=''>12 Reviews</a>
                      </div>
                    </div> */}
                    </div>
                  </div>
                  <div className="garage_action">
                    <ul>
                      <li>
                        <button
                          className="get_quote list-get"
                          onClick={() => {
                            setGarageId(garage.id);
                            setModal(true);
                          }}
                        >
                          Get Quote{" "}
                        </button>
                      </li>
                      <li>
                        <button
                          className="call_now"
                          onClick={() => {
                            setGarageId(garage.id);
                            setModal2(true);
                          }}
                        >
                          <a
                            href={`tel:${garage.phoneNumber}`}
                            className="call_now list-get"
                            style={{ color: "black" }}
                          >
                            <span style={{ paddingRight: 10 }}>
                              <PhoneFilled
                                className="i"
                                style={{
                                  transform: "rotate(110deg)",
                                  fontSize: 14,
                                  color: "black",
                                }}
                              />
                            </span>
                            Call Now{" "}
                          </a>
                        </button>
                      </li>
                      <li>
                        <button
                          className="book list-get"
                          onClick={() => {
                            setGarageId(garage.id);
                            setModal1(true);
                          }}
                        >
                          Book for free
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </>
          )
        )}
      </div>
      <Modal
        visible={modal}
        onCancel={close}
        centered
        footer={null}
        width={520}
        className=""
      >
        {token != undefined ? (
          <GetForm
            vehicleData={vehicleData}
            selectService={selectedService}
            garageId={garageId}
            onClose={close}
          />
        ) : (
          <SignUpForm
            onClose={onCloseSignupModal}
            onOpenLoginModal={openLoginModal}
          />
        )}
      </Modal>
      <Modal
        visible={modal2}
        onCancel={close}
        centered
        footer={null}
        width={520}
        className=""
      >
        {token != undefined ? (
          <CallGetForm
            vehicleData={vehicleData}
            selectServices={selectedService}
            garageId={garageId}
            onClose={close}
          />
        ) : (
          <SignUpForm
            onClose={onCloseSignupModal}
            onOpenLoginModal={openLoginModal}
          />
        )}
      </Modal>
      <Modal
        visible={modal1}
        onCancel={close}
        centered
        footer={null}
        width={520}
        className=""
      >
        {token != undefined ? (
          <BookForm vehicleData={vehicleData} garageId={garageId} />
        ) : (
          <SignUpForm
            onClose={onCloseSignupModal}
            onOpenLoginModal={openLoginModal}
          />
        )}
      </Modal>
      <Modal
        visible={isSignupModalOpen}
        onCancel={onCloseSignupModal}
        centered
        footer={null}
        className="custom-modal"
      >
        <SignUpForm
          onClose={onCloseSignupModal}
          onOpenLoginModal={openLoginModal}
        />
      </Modal>
      <Modal
        visible={isLoginModalOpen}
        onCancel={closeLoginModal}
        centered
        footer={null}
        className="custom-modal"
      >
        <LoginForm
          onClose={closeLoginModal}
          onOpenSignupModal={openSignupModal}
        />
      </Modal>
      <div>
        <Modal
          visible={shareModal}
          onCancel={() => setShareModal(false)}
          centered
          footer={null}
        >
          <Demo />
        </Modal>
      </div>
      <div>
        <Modal
          visible={showModal}
          onCancel={() => setShowModal(false)}
          centered
          footer={null}
        >
          {showModal && (
            <div className="services-modal">
              <div className="services-modal-header">
                <div className="modal-header-left">Services</div>
                <div className="modal-header-right">
                  Total :{" "}
                  <span className="service-count">{modalServices.length}</span>
                </div>
              </div>
              <div className="services-column">
                {modalServices.map((service, index) => (
                  <div key={index}>
                    <div
                      className=""
                      style={{
                        border: "1px solid grey",
                        padding: 8,
                        borderRadius: 5,
                        fontSize: 12,
                      }}
                    >
                      {service.name}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </Modal>
      </div>
    </>
  );
};

export default ListGarages;

// import React, { useState } from "react";
// import {
//   AimOutlined,
//   EnvironmentOutlined,
//   PhoneOutlined,
//   ShareAltOutlined,
//   StarFilled,
// } from "@ant-design/icons";
// import { Modal } from "antd";
// import GetForm from "./getForm";
// import SignUpForm from "../Auth/signup";
// import LoginForm from "../Auth/Login";
// import BookForm from "./BookForm";

// import "./listGarages.css";

// interface GarageData {
//   _id: string;
//   distance: number;
//   garageName: string;
//   phoneNumber: string;
//   email: string;
//   address: string;
//   accountStatus: string;
//   aboutGarage: string;
//   urlLink: string;
//   services: {
//     name: string;
//     type: string;
//     id: string;
//   }[];
// }

// interface ListGaragesProps {
//   data: GarageData[];
//   vehicleData: {
//     vehicleType: number;
//     makeName: string;
//     modelName: string;
//     fuelType: string;
//   };
//   ad2: string;
// }

// const ListGarages: React.FC<ListGaragesProps> = ({
//   data,
//   vehicleData,
//   ad2,
// }) => {
//   const [modal, setModal] = useState(false);
//   const [modal1, setModal1] = useState(false);
//   const [garageId, setGarageId] = useState("");
//   const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
//   const [isSignupModalOpen, setisSignupModelOpen] = useState(false);

//   const close = () => {
//     setModal(false);
//     setModal1(false);
//   };
//   const token = localStorage.getItem("token");
//   const onCloseSignupModal = () => {
//     setisSignupModelOpen(false);
//   };
//   const openLoginModal = () => {
//     setModal(false);
//     setModal1(false);
//     setIsLoginModalOpen(true);
//   };
//   const closeLoginModal = () => {
//     setIsLoginModalOpen(false);
//   };
//   const openSignupModal = () => {
//     setisSignupModelOpen(true);
//   };

//   return (
//     <div
//       className='invisible-scrollbar services-scroll'
//       style={{ marginTop: "2rem" }}>
//       {data.slice(1, data.length).map((garage: GarageData, index: number) => (
//         <>
//           {index === 3 && (
//             <div className='ad-container'>
//               <img
//                 src={ad2}
//                 alt={`Inserted Image ${index}`}
//                 style={{}}
//                 className=''
//               />
//             </div>
//           )}
//           <div
//             className='li-container1'
//             key={index}
//             style={{ paddingTop: "0.5rem" }}>
//             <div className='li-container'>
//               <div className='garage_image'>
//                 <img
//                   src={"assets/images/Rectangle 1.png"}
//                   className='li-image'
//                   alt={`Garage ${index}`}
//                 />
//               </div>

//               <div className='garage'>
//                 <h3 className='garage-h3'>{garage.garageName}</h3>
//                 <h6 className='garage-h6'>
//                   <AimOutlined /> {garage.distance?.toFixed(2)} km Away
//                 </h6>
//                 <p className='garage-p'>Specialized in</p>
//                 <div className='ser'>
//                   {garage.services
//                     .slice(0, window.innerWidth >= 575 ? 4 : 2)
//                     .map((service, index) => (
//                       <div className='service-name' key={index}>
//                         <button className='service-btn'>{service.name}</button>
//                       </div>
//                     ))}
//     <>
//       <div className="invisible-scrollbar services-scroll">
//         {data.slice(1, data.length).map((garage: GarageData, index: number) => (
//           <div className="garage-box">
//             <div className="garage-box_container">
//               <div className="garage_data">
//                 <div className="image_container">
//                   <img
//                     src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTMHB-nuo7vhyEidjjMxoVP4TnrDkJb6_g9bw&usqp=CAU"
//                     alt=""
//                   />
//                 </div>
//                 <div className="content_container">
//                   <h2>
//                     {garage.garageName}
//                     <span>
//                       <ShareAltOutlined />
//                     </span>
//                   </h2>
//                   <h3>
//                     <span>
//                       <AimOutlined />
//                     </span>{" "}
//                     {garage.distance && Math.floor(garage.distance)} Km away
//                   </h3>

//                   <div className="services_container">
//                     <p>Specialized in</p>
//                     <div className="services">
//                       {garage.services
//                         .slice(0, window.innerWidth >= 575 ? 4 : 2)
//                         .map((service, index) => (
//                           <div className="service" key={index}>
//                             <button>{service.name}</button>
//                           </div>
//                         ))}

//                       <a href="#">View More</a>
//                     </div>
//                   </div>
//                   <div className="rating_container">
//                     <div>
//                       <StarFilled className="rating " />
//                       <StarFilled className="rating rating1" />
//                       <StarFilled className="rating rating1" />
//                       <StarFilled className="rating rating1" />
//                     </div>
//                     <div className="rating-num">
//                       <p>4.0</p>
//                     </div>
//                   </div>
//                   <div className="review_container">
//                     <a href="">12 Reviews</a>
//                   </div>
//                 </div>
//               </div>
//               <div className="garage_action">
//                 <ul>
//                   <li>
//                     <button
//                       className="get_quote"
//                       onClick={() => setModal(true)}
//                     >
//                       Get Quote{" "}
//                     </button>
//                   </li>
//                   <li>
//                     <button className="call_now">
//                       <a
//                         href={`tel:${garage.phoneNumber}`}
//                         className="call_now"
//                       >
//                         Call Now{" "}
//                         <span>
//                           <PhoneOutlined />
//                         </span>
//                       </a>
//                     </button>
//                   </li>
//                   <li>
//                     <button
//                       className="book"
//                       onClick={() => {
//                         setGarageId(garage._id);
//                         setModal1(true);
//                       }}
//                     >
//                       Book for free
//                     </button>
//                   </li>
//                 </ul>
//               </div>
//             </div>

//             <Modal
//               visible={modal}
//               onCancel={close}
//               centered
//               footer={null}
//               width={600}
//               className=''>
//               {token != undefined ? (
//                 <GetForm />
//               ) : (
//                 <SignUpForm
//                   onClose={onCloseSignupModal}
//                   onOpenLoginModal={openLoginModal}
//                 />
//               )}
//             </Modal>
//             <Modal
//               visible={modal1}
//               onCancel={close}
//               centered
//               footer={null}
//               width={520}
//               className=''>
//               {token != undefined ? (
//                 <BookForm vehicleData={vehicleData} garageId={garageId} />
//               ) : (
//                 <SignUpForm
//                   onClose={onCloseSignupModal}
//                   onOpenLoginModal={openLoginModal}
//                 />
//               )}
//             </Modal>
//             <Modal
//               visible={isSignupModalOpen}
//               onCancel={onCloseSignupModal}
//               centered
//               footer={null}
//               className='custom-modal'>
//               <SignUpForm
//                 onClose={onCloseSignupModal}
//                 onOpenLoginModal={openLoginModal}
//               />
//             </Modal>
//             <Modal
//               visible={isLoginModalOpen}
//               onCancel={closeLoginModal}
//               centered
//               footer={null}
//               className='custom-modal'>
//               <LoginForm
//                 onClose={closeLoginModal}
//                 onOpenSignupModal={openSignupModal}
//               />
//             </Modal>
//             {/* <Modal
//         visible={modal}
//         onCancel={close}
//         centered
//         footer={null}
//         width={600}
//         className=""
//       >
//         <GetForm/>
//       </Modal> */}
//             <div className='call'>
//               <div>
//                 <button className='get-quote' onClick={() => setModal(true)}>
//                   Get Quote
//                 </button>
//               </div>
//               <div>
//                 <button className='call-now'>Call Now</button>
//               </div>
//               <div>
//                 <button
//                   className='book'
//                   onClick={() => {
//                     setGarageId(garage._id);
//                     setModal1(true);
//                   }}>
//                   Book For Free
//                 </button>
//               </div>
//             </div>
//           </div>
//         </>
//       ))}
//     </div>
//           </div>
//         ))}
//       </div>
//       <Modal
//         visible={modal}
//         onCancel={close}
//         centered
//         footer={null}
//         width={600}
//         className=""
//       >
//         {token != undefined ? (
//           <GetForm />
//         ) : (
//           <SignUpForm
//             onClose={onCloseSignupModal}
//             onOpenLoginModal={openLoginModal}
//           />
//         )}
//       </Modal>
//       <Modal
//         visible={modal1}
//         onCancel={close}
//         centered
//         footer={null}
//         width={520}
//         className=""
//       >
//         {token === undefined ? (
//           <SignUpForm
//             onClose={onCloseSignupModal}
//             onOpenLoginModal={openLoginModal}
//           />
//         ) : (
//           <BookForm vehicleData={vehicleData} garageId={garageId} />
//         )}
//       </Modal>
//       <Modal
//         visible={isSignupModalOpen}
//         onCancel={onCloseSignupModal}
//         centered
//         footer={null}
//         className="custom-modal"
//       >
//         <SignUpForm
//           onClose={onCloseSignupModal}
//           onOpenLoginModal={openLoginModal}
//         />
//       </Modal>
//       <Modal
//         visible={isLoginModalOpen}
//         onCancel={closeLoginModal}
//         centered
//         footer={null}
//         className="custom-modal"
//       >
//         <LoginForm
//           onClose={closeLoginModal}
//           onOpenSignupModal={openSignupModal}
//         />
//       </Modal>
//     </>
//   );
// };

// export default ListGarages;
