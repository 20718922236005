// SignUpForm.tsx
import React, { useState } from "react";
import { Form, Input, Button, Checkbox, message } from "antd";
import { Link } from "react-router-dom";
import Success from "./Success";
import axios from "axios";
import PinField from "react-pin-field";
import cn from "classnames";
import "./otp.css";
import LoginForm from "./Login";
// import "antd/dist/antd.css";

interface SignupFormProps {
  onClose: () => void;
  onOpenLoginModal: () => void; // Add this prop
}

const SignUpForm: React.FC<SignupFormProps> = ({
  onClose,
  onOpenLoginModal,
}) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);
  const [demoCompleted, setDemoCompleted] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  console.log(otp);

  const onFinish = async (values: any) => {
    // setShowSuccessPopup(true)
    console.log(values);
    console.log(values.phoneNumber);

    try {
      // Make a POST request to the API endpoint
      const response = await axios.post(
        "https://api.vecrep.com/createCustomer",
        values
      );
      const data = response.data;
      console.log(data);
      if (data.responseCode === 200) {
        setPhoneNumber(values.phoneNumber);
        setShowSuccessPopup(true);
      } else if (data.responceCode === 400) {
        setErrorMessage("Phone number or email already exist please login");
        console.log(data.message);
        console.log("400 error");
      }
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);
      console.log("api error");
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  const otpVerify = async () => {
    console.log(phoneNumber);
    try {
      const response = await axios.post(
        "https://api.vecrep.com/otpVerification",
        {
          otp,
          phoneNumber,
        },
        { headers: { token: true } }
      );

      // Handle the response as needed
      console.log("OTP Verification Response:", response.data);
      const data = response.data;
      if (data.responseCode === 200) {
        localStorage.setItem("token", data.data.token);
        setSuccess(true);
      } else if (data.responseCode === 400) {
        setError(data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("OTP Verification Error:", error);
    }
  };

  const token = localStorage.getItem("token");
  console.log(token);

  const resendOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.vecrep.com/reSendOtp",
        {
          phoneNumber,
        },
        { headers: { token: true } }
      );

      const data = response.data;
      if (data.responseCode === 200) {
        message.success(data.message);
      }

      // Handle the response as needed
      console.log("Resend OTP Response:", response.data);
      // You may want to display a success message or handle it in some way
    } catch (error) {
      // Handle errors
      console.error("Resend OTP Error:", error);
    }
  };

  const onOtpFormFinish = async (values: any) => {
    console.log(values);
    setOtp(values);
    setPhoneNumber(values.phoneNumber);
    console.log(values.phoneNumber);
    otpVerify();
    console.log(otp);
  };
  const lastFourDigits = phoneNumber ? phoneNumber.toString().slice(-4) : "";

  // Create a masked phone number with asterisks
  const maskedPhoneNumber = "*******" + lastFourDigits;

  const closeLoginModal = () => {
    setShowLoginForm(true);
  };

  const handleSignupModal = () => {
    onClose();
    onOpenLoginModal();
  };

  return (
    <>
      {success ? (
        <Success />
      ) : (
        <>
          <div className='form-head'>
            <h1>VecRep.com</h1>
          </div>
          <div className='error-msg'>
            <p>{error}</p>
          </div>

          {showSuccessPopup ? (
            <div>
              <Form
                name='otp'
                onFinish={() => onOtpFormFinish(otp)}
                className='signUp-form'>
                <h1 className='otpHead'>Verify OTP</h1>
                <p className='otpPara'>
                  OTP has sent to the phone number ends with {maskedPhoneNumber}
                </p>
                <div className='pin-field-container'>
                  <PinField
                    length={6}
                    className={cn("pin-field", {})}
                    onComplete={(result: string) => {
                      setOtp(result);
                    }}
                    autoFocus
                    // autoComplete='one-time-password'
                    validate={RegExp(/^[0-9]$/)}
                  />
                </div>
                <h3 className='otpdesc'>
                  OTP not recevied ?{" "}
                  <Link to=''>
                    <span className='tearms' onClick={resendOtp}>
                      Resend
                    </span>
                  </Link>
                </h3>

                <div className='signup-verify'>
                  <div>
                    <Form.Item>
                      <button type='submit' className='signup-verify-button'>
                        Verify
                      </button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <Form
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              className='signUp-form'>
              {errorMessage ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "1rem",
                  }}>
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
              <h3>Sign Up</h3>
              <p>Enter required details</p>
              <p></p>
              <Form.Item
                name='customerName'
                rules={[{ required: true, message: "Please enter your name" }]}>
                {/* <Input placeholder='Name' /> */}
                <div className='input-login'>
                  <input type='text' required />
                  <span>Name * </span>
                </div>
              </Form.Item>
              <Form.Item
                name='phoneNumber'
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                ]}>
                {/* <Input
                  placeholder='phoneNumber'
                  prefix='+91'
                  onChange={(e) => setPhoneNumber(e.target.value)}
                /> */}
                <div className='input-login'>
                  <input type='text' required />
                  <span>Phone Number * </span>
                </div>
              </Form.Item>
              <Form.Item
                name='email'
                rules={[
                  { required: false, message: "Please enter your email!" },
                  {
                    type: "email",
                    message: "Please enter a valid email address",
                  },
                ]}>
                {/* <Input placeholder='Email' /> */}
                <div className='input-login'>
                  <input type='email' />
                  <span>Email </span>
                </div>
              </Form.Item>
              <Form.Item
                name='latitude'
                rules={[
                  { required: false, message: "Please input your email!" },
                ]}
                style={{ display: "none" }}>
                <Input placeholder='Latitude' defaultValue={0} />
              </Form.Item>
              <Form.Item
                name='longitude'
                rules={[
                  { required: false, message: "Please input your email!" },
                ]}
                style={{ display: "none" }}>
                <Input placeholder='Longitude' defaultValue={0} />
              </Form.Item>
              <Form.Item name='remember' valuePropName='checked'>
                <Checkbox>
                  I agree to VecRep.com{" "}
                  <span className='tearms'>Terms&Conditions</span> and{" "}
                  <span className='tearms'>Privacy Policy</span>
                </Checkbox>
              </Form.Item>

              <Form.Item>
                <div className='signup-submit'>
                  <div>
                    <button type='submit' className='signup-submit-button'>
                      Create Account
                    </button>
                  </div>
                </div>
              </Form.Item>
              {/* {error && <p className='error-message'>{error}</p>} */}
              <h3>OR</h3>
              <h3>
                Have an Account ?{" "}
                <span
                  onClick={handleSignupModal}
                  style={{ color: "#B11226", cursor: "pointer" }}>
                  Login
                </span>
              </h3>
            </Form>
          )}
          {showLoginForm && (
            <div>
              {/* Render the LoginForm component */}
              {/* <LoginForm onClose={showLoginModal} /> */}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default SignUpForm;
