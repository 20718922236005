import React, { useState } from "react";
import "./popularSearch.css";
import { Col, Row } from "antd";
import Collapsedview from "../Collapse/Collapse";
import { Link } from "react-router-dom";

const ExtraPerks: React.FC = () => {
  const texts = [
    {
      title: "Begin With A Visit",
      text: (
        <>
          Click on our website, enter the location where you want to find the
          car or bike services and submit your query. Take your time to browse
          through the website to find your requirements.
        </>
      ),
    },
    {
      title: "Find By Browsing",
      text: (
        <>
          We have a highly interactive and user-friendly website. Visit us to
          discover a complete range of car and bike services. To zero down on
          your requirement, you can use filters like ‘location,’ ‘price range,’
          ‘type of service,’ ‘vehicle issue,’ ‘available slots,’ ‘rating and
          reviews,’ and others. For the best experience, you can ask a query or
          dial-up our contact number to speak to our executive and get
          personalized guidance.
        </>
      ),
    },
    {
      title: "Book The Service You Need",
      text: (
        <>
          You can always visit us, check our services, and book, vehicle service
          online at your chosen garage based on availability. Relax because,
          through VecRep.com, you can book car service online anytime and from
          anywhere. Choose the garage nearest to your location, choose a
          convenient time, book the slot, and wait for garage confirmation.
          Then, all you need to do is drive your vehicle to the chosen garage or
          choose a pick and drop service
        </>
      ),
    },
    {
      title: "Real-Time Updates",
      text: (
        <>
          Once you book a VecRep verified garage for your bike service, you will
          get timely updates on every step completed. We understand your concern
          about your vehicle, and that’s why we keep you updated about every
          step. This will help you to track the progress and plan accordingly,
          especially if you want to pick the vehicle yourself from our garage.
        </>
      ),
    },
    {
      title: "Inspection Report & Job Card",
      text: (
        <>
          Inspections reports as well as job cards will be shared by the garage
          at your fingertips to give you the clarity if your vehicle is facing
          any additional problem. This will ensure that your work is completed
          professionally which will in return deliver high customer satisfaction
        </>
      ),
    },
    {
      title: "Convenient Payment",
      text: (
        <>
          Booking a garage for your vehicle service at VecRep.com is FREE. You
          can pay the final bill amount after the work is completed, during your
          vehicle delivery. There are no hidden charges, surprising costs, or
          other prices involved. We provide a detailed bill mentioning all the
          cost-overheads. You can pay us through online payment modes like UPI,
          Debit, or Credit cards, or with cash.
        </>
      ),
    },
    {
      title: "Huge Savings",
      text: (
        <>
          VecRep.com is the most affordable choice when it comes to booking a
          garage for your car and bike services. With VecRep.com you get instant
          access to the greatest deals & discounts in the market that will
          surely help you to save huge on your next service.
        </>
      ),
    },
    {
      title: "Feedback",
      text: (
        <>
          We love to hear about your experience. Once your vehicle is returned
          to you after servicing, you can share your ratings & reviews based on
          your experience with the garage you booked, you can rate VecRep.com as
          well according to your experience on all the social medias. Feedback
          is a perennial source of encouragement; find areas to improve and
          discover more specialized services aligned with your requirements, and
          customize them to serve you better.
        </>
      ),
    },
  ];

  const extraPerks = [
    {
      title: "Transparent Process",
      text: (
        <>
          VecRep.com believes in transparency and hard work. Our teams are
          skilled and experts in their roles; that’s our strength and gives us
          the confidence to serve you authentically. We have a transparent
          process that helps us to serve you better, and we have been winning
          customer trust.
        </>
      ),
    },
    {
      title: "Open Price Comparison",
      text: (
        <>
          You can view the multiple services offered by different garages and
          their charges. Thus, you can compare the prices and utilities against
          the convenience factors and decide which garage to choose. You get the
          liberty to compare the prices and choose the best deal.
        </>
      ),
    },
    {
      title: "Flexible Booking",
      text: (
        <>
          You don’t need to assign a separate time for booking garage service;
          instead, browse and book anytime and from anywhere at your
          convenience. What’s more? If you cannot be available at the scheduled
          time, then you can always reschedule your garage service!
        </>
      ),
    },
    {
      title: "Discount That Allows You To Save",
      text: (
        <>
          VecRep.com often comes up with offers and discounts and cashbacks from
          time to time. Our customers can avail cashbacks and can access
          interesting discounts on car and bike services, which help them save
          significantly on their next servicing. When it comes to saving money,
          having access to fair deals, winning offers, heavy cashbacks and
          superior services, VecRep.com ranks above all.
        </>
      ),
    },
    {
      title: "No Hidden Charges",
      text: (
        <>
          Hidden charges feel like a betrayal and are a huge turn-off for
          customers. It’s a trust-breaker, too. There isn’t any at VecRep.com
          .You only pay for the service that you approve of in the job card.
          From booking a garage for a vehicle service till the bill is
          generated, the complete journey is transparent with no hidden charges
        </>
      ),
    },
    {
      title: "Authentic Ratings",
      text: (
        <>
          Customers rate the garage based on the experience they had after
          availing the services. We ask for our feedback from the customers;
          it’s always nice to know what our customers feel about the service.
          It’s encouraging and also helps us to identify the areas for
          improvement.
        </>
      ),
    },
  ];

  return (
    <>
    <div className="desktop-gyanContainer" data-reactid="354">
        <h1>
          <strong>ONLINE GARAGE BOOKING MADE EASY AT VecRep.com</strong>
        </h1>
        <ul>
          {
            texts.map((item: any, index: number) => (
              <li key={index}>
                <strong>{item.title}</strong> - {item.text}
              </li>
            ))
          }
        </ul>
      </div>

      <div className="desktop-gyanContainer" data-reactid="354">
        <h1>
          <strong>Extra Perks @ VecRep.com</strong>
        </h1>
        <ul>
          {
            extraPerks.map((item: any, index: number) => (
              <li key={index}>
                <strong>{item.title}</strong> - {item.text}
              </li>
            ))
          }
        </ul>
      </div>
    </>
  )
};

export default ExtraPerks;
