import React from "react";
import "./Terms.css";
import { Row, Col } from "antd";

const Terms = () => {
  return (
    <>
      <div className="container">
        <Row style={{ padding: "10px" }}>
          <div className="dashboard_services">
            <h1 className="section-title text-center">Terms & Conditions</h1>
            <Col span={24}>
              <p className="agree-terms-heading">
                <b>Agreement to terms</b>
              </p>
              <p>
                This document is an electronic record in terms of the Indian
                Contract Act 1872; the Information Technology Act 2000, the
                rules made thereunder; and the amended provisions pertaining to
                the electronic records in various other statutes as amended by
                the Information Technology Act, 2000. This electronic record is
                generated by a computer system and does not require any physical
                or digital signatures.{" "}
              </p>
              <p>
                We, VecRep Technologies Pvt. Ltd., are registered at, Plot No.
                57, Industrial Phase 1, CHANDIGARH 160002 INDIA hereinafter
                referred to as VecRep. These Terms and Conditions constitute a
                legally binding agreement made between you, whether personally
                or on behalf of an entity (“you”) and VecRep (“we,” “us” or
                “our”), concerning your access to and use of the vecrep.com
                website, vecrep.in website, progressive web application as well
                as any other media form, media channel, emails, SMS, mobile
                website or mobile application related, linked, or otherwise
                connected thereto (collectively, the “Site”).
              </p>
              <p>
                Supplemental terms and conditions or documents that may be
                posted on the Site from time to time are hereby expressly
                incorporated herein by reference. We reserve the right, in our
                sole discretion, to make changes or modifications to these Terms
                and Conditions at any time and for any reason. We will alert you
                about any changes by updating the “Last Updated” date of these
                Terms and Conditions, and you waive any right to receive
                specific notice of each such change.
              </p>
              <p>
                It is your responsibility to periodically review these Terms and
                Conditions to stay informed of updates. You will be subject to
                and will be deemed to have been made aware of and to have
                accepted, the changes in any revised Terms and Conditions by
                your continued use of the Site after the date such revised Terms
                and Conditions are posted.
              </p>
              <p>
                The information provided on the Site is not intended for
                distribution to or use by any person or entity in any
                jurisdiction or country where such distribution or use would be
                contrary to law or regulation or which would subject us to any
                registration requirement within such jurisdiction or country.
              </p>
              <p>
                Accordingly, those persons who choose to access the Site from
                other locations do so on their initiative and are solely
                responsible for compliance with local laws, if and to the extent
                local laws are applicable.
              </p>
            </Col>

            <Col span={24}>
              <p className="agree-terms-heading">
                <b>Terms and Conditions of Use</b>
              </p>
              <p>
                By using the website, you agree to these Terms of Use together
                with any additional terms, conditions, notices, and disclaimers
                included on the Website, therefore please read these Terms of
                Use carefully. If you object to any of these Terms of Use
                (including any subsequent amends or modifications) or become
                dissatisfied in any way with your use of the Website, your only
                recourse is to discontinue usage of the Website.{" "}
              </p>
            </Col>

            <Col span={24}>
              <p className="agree-terms-heading">
                <b>Overview</b>
              </p>
              <p>
                VecRep (VR) is an aggregator platform that connects vehicle
                owners (drivers) (specifically 2 wheeler & 4 wheeler) with their
                nearby garage (mechanics) in their locality, based on the
                specific need, customer (driver) can view the profile of the
                garage; compare the prices and then book the available slot with
                any garage listed on VecRep Technologies Private Limited (VRT)
                (“We”). We are a limited company registered in India and
                Chandigarh and have our registered office at, Chandigarh, India.
                Our main trading address and principal place of business is also
                located at this same address.{" "}
              </p>
              <p>
                To use the Website you must agree and allow to receive
                communications via, email, text, and phone from VR to allow
                Bookings to be completed. Also, you should allow or give access
                to your location so that VR can show you the list of all the
                nearby located garages based on your specific needs.
              </p>
              <p>
                When you make and confirm a Booking over the Website and call,
                the chosen Garage will be sent your booking details and you will
                be sent their details via the text or email you have provided.
                Please make sure we are on your safe senders’ list and not
                filtered to junk mail. The VecRep Auto Advisor (customer
                representative) will contact you to resolve all your queries and
                concerns. Should the Garage not be able to undertake the Booking
                or rejects the booking we will endeavour to find you an
                alternative Garages based on your requirement and time slot or
                you can do the same even from your side as well.
              </p>
              <p>
                You will have multiple payment options, you can pay the final
                amount to the garage owner directly (COD) or even you can pay to
                VecRep through online modes (cards, wallets, bank transfer, UPI,
                etc.) based on the final invoice generated for all works
                undertaken in the normal way under the terms and conditions of
                business and you have the option to pay once you have received
                the invoice i.e. after the work is done. At VecRep you have an
                option of “Book Now Pay Later” i.e. you can pay an online
                booking fee (advance fee) of Rs. 99 to VecRep while placing your
                order to confirm your slot with the chosen garage and the rest
                of the amount can be paid (either online to VecRep or directly
                to the garage) based on the final invoice once the work is
                completed.
              </p>
              <p>
                We understand that an emergency can arise all of a sudden & in
                such a situation if you need to revise/modify your Booking then
                VecRep always gives you the option to reschedule your booking
                with the chosen garage on the next available date & time slot by
                justifying the reason on the website or contact our customer
                support directly so that we can take immediate steps to help you
                with the same. It is really important as the Garage will be
                expecting you and have allocated a person to work on your
                vehicle.
              </p>
              <p>VecRep is not responsible or liable for:</p>
              <ul style={{ listStyle: "none" }}>
                <li>
                  The nature or suitability of any agreed or offered Service by
                  the Garage or over the Website, nor the suitability of a
                  Garage in providing Services offered.
                </li>
                <li>
                  If you check the garage from the VecRep website and directly
                  visit the garage to get your work done, then VecRep won’t be
                  responsible for those cases.
                </li>
                <li>
                  If you take any additional service or repair from the garage
                  (other than what you have ordered from the website) then
                  VecRep won’t be responsible for that additional work.
                </li>
                <li>
                  You acknowledge and accept that you release VecRep from any
                  liability, claim, or damages of any kind that may arise from
                  you using either the Website or Garage Services.
                </li>
                <li>
                  The nature or suitability of any agreed or offered Service by
                  the Garage or over the Website, nor the suitability of a
                  Garage in providing Services offered.
                </li>
              </ul>
              <p>
                By using Platform, you affirm that you are at least 18 years of
                age and are fully able and competent to enter into the terms,
                conditions, obligations, affirmations, representations,
                warranties set forth in the Terms and Conditions, and to abide
                by and comply with the terms stated therein. Registration of
                User in the Platform is available only to persons who can form
                legally binding contracts under Indian Contract Act, 1872.
                Persons who are “incompetent to contract” within the meaning of
                the Indian Contract Act, 1872 including minors, un-discharged
                insolvents, or person with unsound mind etc. are not eligible to
                use the Platform. By accessing the Platform or by accessing any
                of the Services or Content on any other Authorized Device, it is
                deemed that the User has read and understood and accepted these
                Terms.
              </p>
              <p>
                If you will be using the Services on behalf of an organization,
                you agree to these Terms on behalf of that organization and you
                represent that you have the authority to do so.
              </p>
            </Col>

            <Col span={24}>
              <p className="agree-terms-heading">
                <b>Terms & Conditions</b>
              </p>
              <p>
              These Terms & Conditions (together with the documents referred to in them) govern your use of our website and all related sites, services, applications, and tools, which we operate from time to time.
              </p>
              <p>
              These Terms & Conditions apply to any person registering as a “user” on the VecRep, or using the VecRep Services or placing orders/booking slots with the garages as a user (a “Driver/Rider”). If you are registering to use the VecRep Service and website as a mechanic/garage, or you are using the VecRep Service as a mechanic/garage (a “Mechanic/Garage”) 
              </p>
              <p>
              You will not access the Platform, and/or its Services, or the personal information of other Users, available on the Platform in order to build a similar or competitive website, product, or service.
              </p>
              <p>
              By using the VecRep Services you confirm that you accept these Terms & Conditions and agrees to abide by them.
              </p>
            </Col>

            <Col span={24}>
              <p className="agree-terms-heading">
                <b>REGISTERING AS A DRIVER / RIDER</b>
              </p>
              <p>
              These Terms & Conditions (together with the documents referred to in them) govern your use of our website and all related sites, services, applications, and tools, which we operate from time to time.
              </p>
              <p>
              These Terms & Conditions apply to any person registering as a “user” on the VecRep, or using the VecRep Services or placing orders/booking slots with the garages as a user (a “Driver/Rider”). If you are registering to use the VecRep Service and website as a mechanic/garage, or you are using the VecRep Service as a mechanic/garage (a “Mechanic/Garage”) 
              </p>
              <p>
              You will not access the Platform, and/or its Services, or the personal information of other Users, available on the Platform in order to build a similar or competitive website, product, or service.
              </p>
              <p>
              By using the VecRep Services you confirm that you accept these Terms & Conditions and agrees to abide by them.
              </p>
            </Col>

            <Col span={24}>
              <p className="agree-terms-heading">
                <b>PROVISION OF SERVICES TO DRIVERS/RIDERS (Customers)</b>
              </p>
              <p>
              Following registration, we will make certain services available to you. 
              </p>
              <p>
              The services will include the ability to search for your vehicle needs from the list of automotive repair or servicing work, view, check & compare the verified & available Mechanic/Garage in your locality specialized to do the work for you (a “Job”), the ability to receive quotes from Mechanics/Garages to Jobs or requirements you have posted/entered (“Quotes”), option to book a slot with the garage based on your preferred date & time, check all the real-time updates (“Live Notification”), check and approve the inspection report & job card for any additional work, check the invoices & make the payments online and the ability to post feedback/ratings/reviews about any Mechanic/Garage you have used as a result of your use of the VecRep Service (“Feedback”).
              </p>
              <p>
              You must only book a service if you have a genuine requirement for the work detailed to be performed. 
              </p>
              <p>
              You must only post Feedback/Ratings about a Mechanic/Garage if you have used them as a result of your use of the VecRep Service. In addition, you must ensure that the content of any Job or Feedback you post complies with the provisions of our Acceptable Use Policy.
              </p>
              <p>
              After inspection of the vehicle by the Mechanic, Digital vehicle fitness reports, videos/photos, Digital Job-Card will be shared by the chosen garage for your approval on any additional work required. You will have to notify us with the same by giving your response, in the whole process from the time of placing the order till the order is complete VecRep should be involved in every step and you agree that you will not reach out to the mechanic/garage directly listed on our platform for any service or for any additional work mentioned in the Job Card without involving VecRep, as in that case we won’t be taking any responsibility of your vehicle and the quality of service/repair nor we will be liable to provide any after support in those cases, and if such scenario is found, VecRep holds the rights to block the account of the user without any prior notice.
              </p>

              <p>
              Full details of the services available to the user will be published on www.vecrep.com from time to time. We are continually seeking to develop and improve the services we offer and so we reserve the right, at our absolute discretion, to make changes to any part of the Service without notice.
              </p>

              <p>
              Although we have asked all users to use VecRep responsibly, we cannot guarantee the accuracy, integrity, or quality of any information you view or receive of our Partner garage. This includes any information contained about details of Garage who are listed in our directory or quality of service you may receive from Garage you avail using VecRep or discounts & offers mentioned in our website or price of services offered by Garages.
              </p>

              <p>
              User is solely responsible as driver or customer to ensure that he/she has the legal title or right to take the vehicle to the garage, i.e. he should not be bringing stolen vehicles or vehicles used for unlawful acts to the garage and any such acts, he will be solely liable. VecRep is solely an aggregator and connector platform and is in no way liable in such cases
              </p>
            </Col>
          </div>
        </Row>
      </div>
    </>
  );
};

export default Terms;
