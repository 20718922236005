import React from "react";
import "./myvehicle.css";
import Dropdown from "antd/es/dropdown/dropdown";
import { Button, Modal, Form, Radio, Upload, message } from "antd";
import { useState, useEffect } from "react";
import axios from "axios";
import {
  CloudUploadOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import EditVechile from "./EditVechicle";
import VechileEdit from "./VehicleEdit";

interface Car {
  name: string;
  id: string;
  logo: string;
  imagePath: string;
  garageName: string;
  type: string;
}

const Myvehicle = () => {
  const [vehiclesData, setVehiclesData] = useState([]);
  const [vechilePopup, setVehiclePopup] = useState(false);
  const [vechileEditPopup, setVehicleEditPopup] = useState(false);
  const [vehicleId, setVehicleId] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleFuel, setVehicleFuel] = useState("");
  const [vehicleYear, setVehicleYear] = useState("");
  const [vehicleType, setVehicleType] = useState(0);
  const [vehicleImage, setVehicleImage] = useState("");
  const [insuranceImage, setInsuranceImage] = useState("");
  const [companyName, setCompanyname] = useState("");
  const [insuranceNumber, setinsuranceNumber] = useState("");
  const [RCNumber, setRCNumber] = useState("");
  useEffect(() => {
    fetchVehicles();
  }, []);

  const fetchVehicles = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/listOfVehicles", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      if (data.responseCode === 200) {
        console.log(data.data, "owndata");
        setVehiclesData(data.data.vehicles);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const vehiclepopup = () => {
    setVehiclePopup(false);
    setVehicleEditPopup(false);
  };

  const fetchDelete = async () => {
    console.log(vehicleId);
    const requestedData = {
      vehicleId: vehicleId,
    };
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/deleteVehicle", {
        method: "DELETE",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        fetchVehicles();
        message.success("Vehicle Deleted Successfully ");
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const items = [
    {
      key: "1",
      label: (
        <p onClick={() => setVehicleEditPopup(true)}>
          <EditOutlined />
          <span style={{ paddingLeft: "8px" }}>Edit</span>
        </p>
      ),
    },
    {
      key: "2",
      label: (
        <p onClick={fetchDelete}>
          <DeleteOutlined />
          <span style={{ paddingLeft: "6px" }}>Delete</span>
        </p>
      ),
    },
  ];

  return (
    <>
      <div className="myvehicle-container">
        <div className="myvehicle-title-row">
          <div className="myvehicle-title">My Vehicles</div>
          <div className="Add-vehicle-image">
            <img
              src="assets/images/myvehicle/addvehicle-image.png"
              alt="Add-vehicle"
            />
          </div>
        </div>
        <div className="myvehicle-contents">
          {vehiclesData?.map(
            (
              {
                brand,
                model,
                fuelType,
                vehicleType,
                _id,
                vehicleImage,
                registrationNumber,
                vehicleNumber,
                year,
                vehicleInsurance: {
                  companyName,
                  insuranceNumber,
                  insuranceImage,
                },
              },
              index
            ) => (
              <>
                <div className="vehicle-card" key={index}>
                  <div className="vehicle-card-image">
                    {vehicleType && vehicleType === 1 ? (
                      <img
                        src={
                          vehicleImage
                            ? vehicleImage
                            : "assets/images/myvehicle/myvehicle-image.png"
                        }
                        alt="vehicle-image"
                      />
                    ) : (
                      <img
                        src={
                          vehicleImage
                            ? vehicleImage
                            : "assets/images/myvehicle/myvehicle-image3.png"
                        }
                        alt="vehicle-image"
                      />
                    )}
                  </div>
                  <div className="vehicle-card-contents">
                    <div className="card-content">
                      <div className="vehicle-name">
                        <h4>
                          {vehicleType && vehicleType === 1 ? (
                            <>
                              {brand ? brand : "-"} {model ? model : "-"}
                            </>
                          ) : (
                            <>{model ? model : "-"}</>
                          )}
                        </h4>
                      </div>
                      <p className="vehicle-fuel">
                        {fuelType ? fuelType : "-"}
                      </p>
                      {companyName ? (
                        <div className="vehicle-insurence">
                          <p className="insurence-text">{companyName}</p>
                          <div className="insurence-image">
                            <img
                              src="assets/images/myvehicle/insurence-check.png"
                              alt="check"
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="vehicle-insurence">
                          <p className="insurence-text">Insurance</p>
                          <p className="insurence-text">(pending)</p>
                        </div>
                      )}
                    </div>
                    <Dropdown
                      menu={{
                        items,
                      }}
                      trigger={["click"]}
                      placement="bottomRight"
                    >
                      <p
                        style={{ border: "none", height: "10px" }}
                        onClick={() => {
                          setVehicleId(_id);
                          setVehicleBrand(brand);
                          setVehicleFuel(fuelType);
                          setVehicleNumber(vehicleNumber);
                          setVehicleModel(model);
                          setVehicleYear(year);
                          setVehicleType(vehicleType);
                          setRCNumber(registrationNumber);
                          setCompanyname(companyName);
                          setinsuranceNumber(insuranceNumber);
                          setVehicleImage(vehicleImage);
                          setInsuranceImage(insuranceImage);
                        }}
                      >
                        <div className="vehicle-card-options">
                          <p className="dot"></p>
                          <p className="dot"></p>
                          <p className="dot"></p>
                        </div>
                      </p>
                    </Dropdown>
                  </div>
                </div>
              </>
            )
          )}
          <div
            className="Add-vehicle-image"
            onClick={() => setVehiclePopup(true)}
          >
            <img
              src="assets/images/myvehicle/addvehicle-image.png"
              alt="Add-vehicle"
            />
          </div>
        </div>
      </div>
      <Modal
        footer={null}
        centered
        width={600}
        open={vechilePopup}
        onCancel={() => {
          setVehiclePopup(false);
        }}
      >
        {" "}
        <EditVechile fetchVehicles={fetchVehicles} closePopup={vehiclepopup} />
      </Modal>
      <Modal
        footer={null}
        centered
        width={600}
        open={vechileEditPopup}
        onCancel={() => {
          setVehicleEditPopup(false);
        }}
      >
        {" "}
        <VechileEdit
          fetchVehicles={fetchVehicles}
          closePopup={vehiclepopup}
          vehicleModel={vehicleModel}
          vehicleBrand={vehicleBrand}
          vehicleFuel={vehicleFuel}
          vehicleNum={vehicleNumber}
          vehicleYear={vehicleYear}
          vehicleType={vehicleType}
          insuranceImage={insuranceImage}
          vehicleIamge={vehicleImage}
          insuranceNumber={insuranceNumber}
          companyName={companyName}
          RCNumber={RCNumber}
        />
      </Modal>
    </>
  );
};

export default Myvehicle;
