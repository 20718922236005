import React, { useState, useEffect } from "react";
import { Table } from "antd";
import type { TableProps } from "antd";
import axios from "axios";

interface DataType {
  key: number;
  packageName: string;
  packagePrice: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Car Service List",
    dataIndex: "packageName",
    key: "packageName",
  },
  {
    title: "Price Starts From (₹)",
    dataIndex: "packagePrice",
    key: "packagePrice",
  },
];

const data = [
  {
    key: 1,
    packageName: "COMPREHENSIVE CAR REPAIR",
    packagePrice: "Rs. 5699",
  },
  {
    key: 2,
    packageName: "BASIC CAR REPAIR",
    packagePrice: "Rs. 2899",
  },
  {
    key: 3,
    packageName: "STANDARD CAR REPAIR",
    packagePrice: "Rs. 3599",
  },
];

const PriceListing: React.FC = () => {
  const year = new Date().getFullYear();
  return (
    <>
      <h2 className="faq-side-content-heading">
        Car Repair Price List in India {year}
      </h2>
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        className="tbl-price-list"
      />
    </>
  );
};

export default PriceListing;
