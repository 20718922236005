import React from "react";
import "./searchServices.css";

const OperateCities: React.FC = () => {
    const cityArr = ['Mohali', 'Panchkula', 'Zirakpur', 'Derabassi', 'Kalka', 'Baddi'];
    return (
        <>
        <div className="operate-city-section">
            {
                cityArr && cityArr.map((city, i) => {
                    return (
                        <>                        
                            <div className='city-name' key={i}>
                                <a href="https://vecrep.com/#">
                                    {city}
                                </a>
                            </div>
                        </>
                    )
                })
            }
        </div>
        </>
    )
}

export default OperateCities;