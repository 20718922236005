import { Avatar, Form, Input, Row, Col, Button, Radio, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import React, { useRef, useState } from "react";
import "./myprofile.css";

interface EditProfileProps {
  email: string;
  address: string;
  day: string;
  month: string;
  year: string;
  gender: string;
  phoneNumber: string;
  customerName: string;
  imageUrl: string;
  callcustomer: () => void;
  close: () => void;
}

const EditProfile: React.FC<EditProfileProps> = ({
  email,
  address,
  day,
  month,
  year,
  gender,
  phoneNumber,
  customerName,
  imageUrl,
  callcustomer,
  close,
}) => {
  console.log(
    email,
    address,
    day,
    month,
    year,
    gender,
    phoneNumber,
    customerName,
    imageUrl
  );

  const [previewUrl, setPreviewUrl] = useState<string | null>(null);
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [profileUrl, setProfileUrl] = useState("");
  const [selectedDay, setSelectedDay] = useState<string>(day);
  const [selectedMonth, setSelectedMonth] = useState<string>(month);
  const [selectedYear, setSelectedYear] = useState<string>(year);

  console.log(previewUrl);

  const onFinish = async (values: any) => {
    console.log("Selected Dob", selectedDay, selectedMonth, selectedYear);
    console.log(selectedImage);
    console.log(values);
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage); // 'file' should match the name of the file field in the form
      try {
        const response = await fetch("https://api.vecrep.com/uploadImage", {
          method: "POST",
          body: formData,
        });
        if (!response.ok) {
          console.error("Error:", response.statusText);
          fetchForm(imageUrl, values);
          return;
        }
        const data = await response.json();
        console.log(data.imagePath1);
        setProfileUrl(data?.imagePath1);
        fetchForm(data.imagePath1, values);
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      fetchForm(imageUrl, values);
    }
  };

  const fetchForm = async (url: any, values: any) => {
    const token = localStorage.getItem("token");
    const formattedDate = `${selectedDay}-${selectedMonth}-${selectedYear}`;
    const requestData = {
      customerName: values.customerName,
      phoneNumber: values.phoneNumber,
      email: values.email,
      gender: values.gender,
      dateOfBirth: formattedDate,
      address: values.address,
      customerImage: url,
    };
    console.log(requestData);
    try {
      const response = await fetch("https://api.vecrep.com/updateCustomer", {
        method: "PUT",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responceCode === 200) {
        console.log(data.data);
        close();
        callcustomer();
        message.success("Details updated successfully");
      } else {
        console.error("Error fetching data from the API");
        console.log("400", data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileSelection = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    const file = event.target.files?.[0] || null;
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const imageDataUrl = e.target?.result as string;
        setPreviewUrl(imageDataUrl);
        setSelectedImage(file);
        console.log(imageDataUrl);
      };
      reader.readAsDataURL(file);
    }
  };
  const days = Array.from({ length: 31 }, (_, i) => i + 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const years = Array.from({ length: 100 }, (_, i) => 2023 - i);

  const handleDayChange = (value: string) => {
    setSelectedDay(value);
  };

  const handleMonthChange = (value: string) => {
    setSelectedMonth(value);
  };

  const handleYearChange = (value: string) => {
    setSelectedYear(value);
  };
  return (
    <div style={{ padding: 35 }} className='myprofile-container'>
      <Form
        name='basic'
        initialValues={{
          customerName,
          phoneNumber,
          email,
          gender,
          address,
        }}
        onFinish={onFinish}
        className=''>
        <Form.Item
          name='customerImage'
          rules={[
            {
              required: false,
              message: "Please enter your phone Number!",
            },
          ]}>
          <div style={{ display: "flex", gap: 20 }}>
            <div className='profile-picture'>
              <Avatar
                src={previewUrl ? previewUrl : imageUrl ? imageUrl : ""}
                shape='circle'
                size={120}
                style={{fontWeight:"bold",fontSize:30,backgroundColor:"#b11226"}}
              >  {customerName.charAt(0)}
              </Avatar>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}>
              {/* <input type='file' name='file' onChange={handleFileChange} /> */}
              <label
                style={{
                  backgroundColor: "#b11226",
                  padding: 10,
                  borderRadius: 5,
                  border: "none",
                  color: "white",
                  fontWeight: "400",
                  cursor: "pointer",
                }}
                htmlFor='fileInput'>
                Upload Profile
              </label>
              <input
                type='file'
                id='fileInput'
                name='file'
                ref={fileInputRef}
                onChange={handleFileChange}
                style={{
                  opacity: 0,
                  position: "absolute",
                  left: "-9999px",
                }}
              />
            </div>
          </div>
        </Form.Item>

        <Row className='d-list-garage-row'>
          <Col xl={11} xs={11} sm={11}>
            <label className='d-list-garage-label'>Name</label>
            <Form.Item
              name='customerName'
              rules={[{ required: true, message: "Please enter your name" }]}>
              <Input
                type='text'
                placeholder='First Name'
                className='profile-page-input'
                style={{ borderColor: "#b11226" }}
              />
            </Form.Item>
          </Col>
          <Col xl={11} sm={11} xs={11}>
            <label className='d-list-garage-label'>Mobile</label>
            <Form.Item
              name='phoneNumber'
              rules={[
                { required: false, message: "Please enter your mobile number" },
              ]}>
              <Input
                type='text'
                placeholder='Phone Number'
                className='profile-page-input'
                style={{ borderColor: "#b11226" }}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ justifyContent: "space-around" }}>
          <Col xl={11} xs={11} sm={11}>
            <label className='d-list-garage-label'>Email</label>
            <Form.Item
              name='email'
              rules={[
                {
                  required: false,
                  message: "Please enter your email",
                },
              ]}>
              <Input
                type='text'
                placeholder='Email'
                className='profile-page-input'
                style={{ borderColor: "#b11226" }}
              />
            </Form.Item>
          </Col>
          <Col xl={11} sm={11} xs={11}>
            <label className='d-list-garage-label'>D.O.B</label>
            <Form.Item
              name='dateOfBirth'
              rules={[
                {
                  required: false,
                  message: "Please select your date of birth!",
                },
              ]}>
              <div className='dob-column'>
                <select
                  name='day'
                  className='day-select'
                  onChange={(e) => handleDayChange(e.target.value)}
                  value={selectedDay}>
                  <option value=''>Day</option>
                  {days.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <select
                  name='month'
                  className='month-select'
                  onChange={(e) => handleMonthChange(e.target.value)}
                  value={selectedMonth}>
                  <option value=''>Month</option>
                  {months.map((month) => (
                    <option key={month} value={month}>
                      {month}
                    </option>
                  ))}
                </select>
                <select
                  name='year'
                  className='year-select'
                  onChange={(e) => handleYearChange(e.target.value)}
                  value={selectedYear}>
                  <option value=''>Year</option>
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                {/* <Input
                type="date"
                placeholder="Date of Birth"
                className="profile-page-input"
                style={{ borderColor: "#b11226" }}
              /> */}
              </div>
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ justifyContent: "space-around" }}>
          <Col xl={17} xs={22} sm={20}>
            {/* <label className='d-list-garage-label'>Gender</label> */}
            <Form.Item
              name='gender'
              label='Gender'
              rules={[
                { required: false, message: "Please select your gender" },
              ]}
              style={{ display: "flex" }}>
              <Radio.Group
              //   onChange={handleRadioChange}
              //    value={selectedValue}
              >
                <Radio className='radio-button-style' value='male'>
                  Male
                </Radio>
                <Radio className='radio-button-style' value='female'>
                  Female
                </Radio>
                <Radio className='radio-button-style' value='others'>
                  Others
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col xl={5} sm={2} xs={4}>
            {/* <label className='d-list-garage-label'>Dob</label> */}
          </Col>
        </Row>
        <Row className='d-list-garage-row'>
          <Col xl={24} sm={24} xs={24}>
            <label className='d-list-garage-label'>Address</label>
            <Form.Item
              name='address'
              rules={[
                { required: false, message: "Please enter your address" },
              ]}>
              <TextArea
                // type='text'
                rows={3}
                placeholder='Address'
                className='profile-page-input'
                style={{ borderColor: "#b11226" }}
              />
            </Form.Item>
          </Col>
          <Col xl={10} xs={24} sm={24}></Col>
        </Row>

        <Form.Item className='d-list-garage-button-container'>
          <button type='submit' className='d-list-garage-button'>
            Submit
          </button>
        </Form.Item>
      </Form>
    </div>
  );
};
export default EditProfile;
