import React from "react";
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  FacebookShareCount,
  GabIcon,
  GabShareButton,
  HatenaIcon,
  HatenaShareButton,
  HatenaShareCount,
  InstapaperIcon,
  InstapaperShareButton,
  LineIcon,
  LineShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  LivejournalIcon,
  LivejournalShareButton,
  MailruIcon,
  MailruShareButton,
  OKIcon,
  OKShareButton,
  OKShareCount,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  PocketIcon,
  PocketShareButton,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TelegramIcon,
  TelegramShareButton,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterShareButton,
  ViberIcon,
  ViberShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WeiboIcon,
  WeiboShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  WorkplaceIcon,
  WorkplaceShareButton,
  XIcon,
} from "react-share";
import "./GarageDetails.css";
import { message } from "antd"

export function Demo() {
  const shareUrl = "https://www.youtube.com";
  const title = "Youtube";
  const handleCopyClick = () => {
    const tempInput = document.createElement("input");
    tempInput.value = shareUrl;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    message.success("Copied Successfully");
  };

  return (
    <>
      <div className='share-popup'>
        <div className='Demo__title'>Share</div>
        <div className='Demo__container'>
          <div className='Demo__some-network'>
            <div className='Demo__icon'>
              <FacebookShareButton
                url={shareUrl}
                className='Demo__some-network__share-button'>
                <FacebookIcon size={50} round />
              </FacebookShareButton>

              {/* <div>
          <FacebookShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'>
            {(count) => count}
          </FacebookShareCount>
        </div> */}
            </div>
            <div className='Demo__icon_text'>Facebook</div>
          </div>

          <div className='Demo__some-network'>
            <div className='Demo__icon'>
              <FacebookMessengerShareButton
                url={shareUrl}
                appId='521270401588372'
                className='Demo__some-network__share-button'>
                <FacebookMessengerIcon size={50} round />
              </FacebookMessengerShareButton>
            </div>
            <div className='Demo__icon_text'>Messenger</div>
          </div>

          <div className='Demo__some-network'>
            <div>
              <TwitterShareButton
                url={shareUrl}
                title={title}
                className='Demo__some-network__share-button'>
                <XIcon size={50} round />
              </TwitterShareButton>
            </div>
            <div className='Demo__icon_text'>Twitter</div>
          </div>

          <div className='Demo__some-network'>
            <div>
              <TelegramShareButton
                url={shareUrl}
                title={title}
                className='Demo__some-network__share-button'>
                <TelegramIcon size={50} round />
              </TelegramShareButton>
            </div>
            <div className='Demo__icon_text'>Telegram</div>
          </div>

          <div className='Demo__some-network'>
            <div>
              <WhatsappShareButton
                url={shareUrl}
                title={title}
                separator=':: '
                className='Demo__some-network__share-button'>
                <WhatsappIcon size={50} round />
              </WhatsappShareButton>
            </div>
            <div className='Demo__icon_text'>Whatsapp</div>
          </div>

          <div className='Demo__some-network'>
            <div>
              <LinkedinShareButton
                url={shareUrl}
                className='Demo__some-network__share-button'>
                <LinkedinIcon size={50} round />
              </LinkedinShareButton>
            </div>
            <div className='Demo__icon_text'>Linkedin</div>
          </div>

          {/* <div className='Demo__some-network'>
        <div>
          <PinterestShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div> */}

          {/* <div className='Demo__some-network'>
        <VKShareButton
          url={shareUrl}
          // image={`${String(window.location)}/${exampleImage}`}
          className='Demo__some-network__share-button'>
          <VKIcon size={32} round />
        </VKShareButton>

        <div>
          <VKShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div>

      <div className='Demo__some-network'>
        <OKShareButton
          url={shareUrl}
          // image={`${String(window.location)}/${exampleImage}`}
          className='Demo__some-network__share-button'>
          <OKIcon size={32} round />
        </OKShareButton>

        <div>
          <OKShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div>

      <div className='Demo__some-network'>
        <RedditShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className='Demo__some-network__share-button'>
          <RedditIcon size={32} round />
        </RedditShareButton>

        <div>
          <RedditShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div>

      <div className='Demo__some-network'>
        <GabShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={640}
          className='Demo__some-network__share-button'>
          <GabIcon size={32} round />
        </GabShareButton>
      </div>

      <div className='Demo__some-network'>
        <TumblrShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <TumblrIcon size={32} round />
        </TumblrShareButton>

        <div>
          <TumblrShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div>

      <div className='Demo__some-network'>
        <LivejournalShareButton
          url={shareUrl}
          title={title}
          description={shareUrl}
          className='Demo__some-network__share-button'>
          <LivejournalIcon size={32} round />
        </LivejournalShareButton>
      </div>

      <div className='Demo__some-network'>
        <MailruShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <MailruIcon size={32} round />
        </MailruShareButton>
      </div>

      <div className='Demo__some-network'>
        <EmailShareButton
          url={shareUrl}
          subject={title}
          body='body'
          className='Demo__some-network__share-button'>
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>

      <div className='Demo__some-network'>
        <ViberShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <ViberIcon size={32} round />
        </ViberShareButton>
      </div>

      <div className='Demo__some-network'>
        <WorkplaceShareButton
          url={shareUrl}
          quote={title}
          className='Demo__some-network__share-button'>
          <WorkplaceIcon size={32} round />
        </WorkplaceShareButton>
      </div>

      <div className='Demo__some-network'>
        <LineShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <LineIcon size={32} round />
        </LineShareButton>
      </div>

      <div className='Demo__some-network'>
        <WeiboShareButton
          url={shareUrl}
          title={title}
          // image={`${String(window.location)}/${exampleImage}`}
          className='Demo__some-network__share-button'>
          <WeiboIcon size={32} round />
        </WeiboShareButton>
      </div>

      <div className='Demo__some-network'>
        <PocketShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <PocketIcon size={32} round />
        </PocketShareButton>
      </div>

      <div className='Demo__some-network'>
        <InstapaperShareButton
          url={shareUrl}
          title={title}
          className='Demo__some-network__share-button'>
          <InstapaperIcon size={32} round />
        </InstapaperShareButton>
      </div>

      <div className='Demo__some-network'>
        <HatenaShareButton
          url={shareUrl}
          title={title}
          windowWidth={660}
          windowHeight={460}
          className='Demo__some-network__share-button'>
          <HatenaIcon size={32} round />
        </HatenaShareButton>

        <div>
          <HatenaShareCount
            url={shareUrl}
            className='Demo__some-network__share-count'
          />
        </div>
      </div> */}
        </div>
        <div className='Demo__bar'>
          <div className='Demo__bar__link'>
            <input readOnly size={35} value={shareUrl} />
          </div>
          <div className='Demo__bar__button'>
            <button className='Demo__bar__copy' onClick={handleCopyClick}>
              Copy
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Demo;
