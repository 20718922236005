import React, { useState, useEffect } from "react";
import "./NearMeGarageBanner.css";
import { Row, Col } from "antd";
import LocationSearch from "../Dashboard/Banner/LocationSearch";
import { useLocation, useNavigate } from "react-router-dom";

interface Coordinates {
  latitude: number;
  longitude: number;
}
interface Address {
  city: string;
  state: string;
  zip: string;
  country: string;
  plain(): string;
}
const NearMeGarageBanner: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { pathname } = location;
    const serviceArr = pathname.replace('/', '').split('-');
    const serviceName = serviceArr
    .filter(part => part !== "near" && part !== "me")
    .join(" ");
    console.log('near-me page', serviceName);
    
  const [locationData, setLocationData] = useState<{
    coords: Coordinates;
    address: Address;
  } | null>(null);
  const [value, setValue] = useState<string>("");
  const [showMobileBar, setShowMobileBar] = useState<boolean>(false);

  const handleLocationChange = (coords: Coordinates, address: Address) => {
    // Do something with the coordinates and address in the parent component
    console.log("Received coordinates in parent:", coords);
    console.log("Received address in parent:", address);

    setLocationData({ coords, address });
  };
  const handleNextButtonClick = () => {
    setShowMobileBar(true); // Show the mobile bar when next button is clicked
  };

  const handleSubmitButtonClick = () => {
      if(locationData && locationData.address.city) {
        const url = window.location.pathname;
        const splitArray = url.substring(1).split("-");
        const filteredParts = splitArray.filter(part => part !== 'near' && part !== 'me').join('-');
        const newUrl = `${locationData.address.city.toLowerCase()}/${filteredParts}`;
        navigate(`/${newUrl}`);
    }
  }

  useEffect(() => {
    console.log('nearme garage banner useeffect', locationData);
  }, [locationData]);
  return (
    <>
      <Row className="bugConatiner" style={{ display: 'block' }}>
        <Col
          md={24}
          className="brand-select-spacing-main-div near-me-search-container"
          id="near-me-first-panel"
        >
          <div
            className="near-me-search-background"
            style={{
              backgroundColor: "rgba(77,77,77,.5)",
              top: "0",
              left: "0",
              width: "100%",
              height: "100%",
              position: "absolute",
              zIndex: "1",
            }}
          ></div>
          <div className="near-meMobConatiner">
            <h2 className="near-me-search-title">
              Discover the Best {serviceName} Services Near You
            </h2>
            {/* style={{ display: showMobileBar ? "none" : "inline-block" }} */}
            <div className="near-me-search-bar">
              <div className="b_eecb near-me-input-container">
                <div className="near-me-input-div">
                  {/* <input
                        name="near-me-location"
                        value=""
                        type="text"
                        id="nearMe"
                        className="ellipsis near-me-search-input reverse-geo-add pac-target-input"
                        placeholder="Enter Your Location"
                        style={{ outline: "none" }}
                        autoComplete="off"
                      /> */}
                    <LocationSearch
                        onLocationChange={handleLocationChange}
                        updateValue={setValue}
                    />
                </div>
              </div>
              <div className="geolocataddress">
                <img
                  id="locImg"
                  className="detectLocationImg"
                  src="https://gomechprod.blob.core.windows.net/websiteasset/New%20Website/components/Homepage/B77BEBBB-3D47-469C-B25C-8B1D53DEF4EC.svg"
                  alt="detect"
                />
                <div
                  className="loaderNew"
                  id="loader-display"
                  style={{
                    visibility: "hidden",
                    color: "red",
                    display: "none",
                  }}
                ></div>
              </div>
              {/* onClick={handleNextButtonClick} // Handle next button click */}
              <button
                className="near-me-search-btn"
                id="locationBtn"
                style={{ outline: "none" }}
                onClick={handleSubmitButtonClick}
              >
                Next
              </button>
            </div>
            {/* style={{ display: showMobileBar ? "inline-block" : "none" }} */}
            <div className="near-me-mobile-bar" style={{ display: "none" }}>
              <div className="b_eecb near-me-input-container">
                <div className="near-me-mobile-div">
                  <input
                    type="text"
                    maxLength={10}
                    pattern="[789][0-9]{9}"
                    inputMode="numeric"
                    id="mobile_no"
                    className="ellipsis near-me-search-input"
                    placeholder="Enter Your Mobile Number"
                    style={{ outline: "none" }}
                  />
                </div>
              </div>
              <button
                className="near-me-submit-btn"
                id="mobileBtn"
                style={{ outline: "none" }}
              >
                <span
                  id="hide-text"
                  className="hide-mobile-btn-text"
                  style={{ visibility: "visible", color: "white" }}
                >
                  SUBMIT
                </span>
                {/* <i className="fa fa-spinner fa-pulse fa-fw" id="display-loader" style={{visibility:"visible", color: "white"}}></i> */}
                <div
                  className="spinner"
                  id="display-loader"
                  style={{ visibility: "visible", color: "white" }}
                >
                  <div className="bounce1"></div>
                  <div className="bounce2"></div>
                  <div className="bounce3"></div>
                </div>
              </button>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default NearMeGarageBanner;
