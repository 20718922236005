import React, { useEffect, useRef, useState } from "react";
import { EnvironmentFilled, EnvironmentOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { setCoordinates } from "../../../Redux/store";
import LocationSearchRes from "./LocationRes";

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Address {
  area: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  plain(): string;
}

const initialCoordinates: Coordinates = {
  latitude: 0, // Replace with your default latitude
  longitude: 0, // Replace with your default longitude
};

const initialAddress: Address = {
  area: "manimajra",
  city: "Chandigarh",
  state: "Punjab",
  zip: "160047",
  country: "INDIA",
  plain() {
    return `${this.area},${this.city}, ${this.zip}, ${this.state}, ${this.country}`;
  },
};

interface LocationSearchProps {
  onLocationChange: (coords: Coordinates, address: Address) => void;
  updateValue: React.Dispatch<React.SetStateAction<any>>;
}

const LocationSearch: React.FC<LocationSearchProps> = ({
  onLocationChange,
  updateValue,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [address, setAddress] = useState<Address>(initialAddress);
  const [modalVisible, setModalVisible] = useState(false);
  const [Address2, setAddress2] = useState("");
  const [suggestions, setSuggestions] = useState<string[]>([]); // Added state for suggestions

  const apiKey = "AIzaSyD0pb4Bwj8KYUWRimZfmNuZQ9BOotkezF4";
  const mapApiJs = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

  const dispatch = useDispatch();

  const loadAsyncScript = (src: string): Promise<HTMLScriptElement> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  };

  const initAutocomplete = () => {
    if (!searchInputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(
      searchInputRef.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    if(localStorage.getItem('currentCity') === null) {
      loadAsyncScript(mapApiJs).then(() => {
        initAutocomplete();
        findMyLocation(); // Fetch the user's location by default when entering the page
      });
    } else {
      if (searchInputRef.current) {
        var cityName = JSON.parse(localStorage.getItem('currentPlace') || '{}');
        // var cityName = localStorage.getItem('currentPlace') || null;
        searchInputRef.current.value = ""+cityName.area+", "+cityName.city+", "+cityName.zip+", "+cityName.state+", "+cityName.country;
        const defaultCoords = initialCoordinates;
        const defaultAddress = cityName;
        dispatch(setCoordinates(defaultCoords.latitude, defaultCoords.longitude));
        onLocationChange(defaultCoords, defaultAddress);
      }
    }
  }, []);

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();
    if (!place || !place.geometry || !place.geometry.location) {
      console.error(
        "Invalid place object or no geometry found for place:",
        place
      );

      // Use default values when no valid place is found
      const defaultCoords = initialCoordinates;
      const defaultAddress = initialAddress;
      dispatch(setCoordinates(defaultCoords.latitude, defaultCoords.longitude));
      onLocationChange(defaultCoords, defaultAddress);

      return;
    }

    const newAddress = extractAddress(place);
    console.log(place);
    localStorage.setItem('currentPlace',JSON.stringify(newAddress));
    setAddress(newAddress);

    const { lat, lng } = place.geometry.location;
    const latitude = lat?.() ?? 0;
    const longitude = lng?.() ?? 0;

    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);
    dispatch(setCoordinates(latitude, longitude));

    // Pass both coordinates and address to the parent component
    onLocationChange({ latitude, longitude }, newAddress);
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Latitude:", latitude + ", Longitude:", longitude);

          const newCoords = { latitude, longitude };
          reverseGeocode(newCoords);
        },
        (error) => {
          console.error("Error getting geolocation:", error);

          // Use default values when geolocation error occurs
          const defaultCoords = initialCoordinates;
          const defaultAddress = initialAddress;

          dispatch(
            setCoordinates(defaultCoords.latitude, defaultCoords.longitude)
          );
          onLocationChange(defaultCoords, defaultAddress);
        }
      );
    }
  };

  const reverseGeocode = (coords: Coordinates) => {
    const { latitude, longitude } = coords;
    const url = `${geocodeJson}?key=${apiKey}&latlng=${latitude},${longitude}`;

    if (searchInputRef.current) {
      searchInputRef.current.value = "Getting your location..."; // Set loading message
    }
    console.log("Fetching address from:", url);

    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        // console.log(_address);
        localStorage.setItem('currentPlace',JSON.stringify(_address));
        setAddress(_address);
        localStorage.setItem('currentCity', _address.city);
        if (searchInputRef.current) {
          searchInputRef.current.value = _address.plain();
          updateValue(searchInputRef.current.value);
          // Update input value with the fetched address
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const extractAddress = (place: google.maps.places.PlaceResult): Address => {
    const address: Address = {
      area: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        const area = this.area ? this.area + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return area + city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }
    console.log(place.address_components);
    place.address_components.forEach((component) => {
      const types = component.types;
      const value = component.long_name;
      if (types.includes("sublocality")) {
        address.area = value;
      }

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });

    return address;
  };

  const handleInputChange = () => {
    if (searchInputRef.current) {
      const inputValue = searchInputRef.current.value;

      if (inputValue.trim() !== "") {
        // Perform autocomplete suggestions based on the input value
        const autoCompleteService =
          new window.google.maps.places.AutocompleteService();
        autoCompleteService.getPlacePredictions(
          { input: inputValue },
          (predictions) => {
            setSuggestions(
              predictions?.map((prediction) => prediction.description)
            );
          }
        );
      } else {
        setSuggestions([]); // Clear suggestions when input is empty
      }
    }
  };

  useEffect(() => {
    loadAsyncScript(mapApiJs).then(() => initAutocomplete());
    // findMyLocation(); // Fetch the user's location by default when entering the page
  }, []);

  const mobile_locat = () => {
    setModalVisible(true);
  };

  useEffect(() => {
    const Loc = localStorage.getItem("location");
    setAddress2(Loc ?? "");
  });

  return (
    <div className='input_box locationInput'>
      <div
        className='web_locat'
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}>
        <button onClick={findMyLocation}>
          <EnvironmentOutlined />
        </button>
        {Address2 && Address2 != "undefined" ? (
          <input
            ref={searchInputRef}
            // value={Address2}
            type='text'
            placeholder='Search location....'
            onChange={handleInputChange}
          />
        ) : (
          <input
            ref={searchInputRef}
            type='text'
            placeholder='Search location....'
            onChange={handleInputChange}
          />
        )}

        {/* Display suggestions */}
      </div>

      <div className='mobile_locat'>
        <button onClick={mobile_locat}>
          <img src='assets/icons/mdi_my-location.png' alt='' />
        </button>
      </div>
      <Modal
        title={null}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}>
        <LocationSearchRes onLocationChange={onLocationChange} />
      </Modal>
    </div>
  );
};

export default LocationSearch;
