import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "./ConfirmBooking.css";
import { Card, Col, Row } from "antd";
// import user from "./User.png";
import loction from "./mdi_my-location.png";
import check from "./Check.png";
import cal from "./schedule.png";
// import Garagedetails from "./Garagedetails";
// import Getlocation from "./Getlocation";
// import Schedule from "./Schedule";
// import CustomerDetails from "./CustomerDetails";
// import GetHelp from "./GetHelp";
import mechanic from "./Mechanic.png";
import group from "./OBJECTS.png";
import { Modal, Avatar } from "antd";
// import mark from "./Group (1).png";
import { help } from "yargs";
import RescheduleBooking from "./RescheduleBooking";
interface LocationDetails {
  garageName: string;
  location: {
    // Define the properties of the location object
  };
  address: string;
}

interface GetHelpData {
  text: string;
  phoneNumber: string;
  email: string;
}

interface CustomerData {
  customerData: {
    customerName: string;
    email: string;
    phoneNumber: string;
    vehicle: {
      brand: string;
      fuelType: string;
      model: string;
    };
  };
}

interface BookingFormprops {
  bookingId: string;
  chooseDate: string;
  chooseTime: string;
  createdAt: string;
  created_At: string;
  created_at: string;
  customer: CustomerData;
  customerId: string;
  date: string;
  finalPrice: string;
  garageDetails: LocationDetails;
  garageId: string;
  getHelpData: GetHelpData;
  internetType: string;
  invoiceId: string;
  jobCardId: string;
  labourCharge: number;
  mechanics: any[];
  note: string;
  remark: string;
  serviceStatus: string;
  updated_at: string;
  vehicleId: string;
  __v: number;
  _id: string;
}
interface customerDetailsprops {
  Data: BookingFormprops | Partial<BookingFormprops>;
}

interface vehicledetails {
  customerVehicleBrand: string;
  customerVehicleModal: string;
  customerVehicleFuel: string;
  garageName: string;
}
const ConfirmBooking: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [serviceBookingId, setServiceBookingId] = useState("");
  const [garageData, setGarageData] = useState<CustomerData | null>(null);
  const [scheduleData, setScheduleData] = useState("");
  const [responseData, setResponseData] = useState<customerDetailsprops | null>(
    null
  );
  const [bookingId, setBookingId] = useState("");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [modal1, setModal1] = useState<boolean>(false);
  const [garageName, setGarageName] = useState<string>("");
  const [garageAddress, setGarageAddress] = useState("");
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerVehicleBrand, setCustomerVehicleBrand] = useState<string>("");
  const [customerVehicleModel, setCustomerVehicleModal] = useState<string>("");
  const [customerVehicleFuel, setCustomerVehicleFuel] = useState<string>("");
  const [helpEmail, setHelpEmail] = useState("");
  const [helpPhone, setHelpPhone] = useState("");
  const [message, setMessage] = useState(
    "Congrats! Booking Confirmed Successfully"
  );
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);

  const { apiData } = location.state || {};

  useEffect(() => {
    setServiceBookingId(apiData);
    fetchData();
  }, [apiData]);
  console.log(apiData);

  const fetchData = async () => {
    const token = localStorage.getItem("token");
    console.log(token);
    const requestData = { bookingId: apiData };
    console.log(requestData);
    try {
      const response = await fetch(
        "https://api.vecrep.com/getBookappointmentData",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      console.log(response);
      if (data.responseCode === 200) {
        console.log("HI");
        console.log(data.data);
        // setCustomerData(data.data.customer);
        setResponseData(data.data);
        // setMessage(data.message);
        const Data = data.data;
        setGarageData(data.data);
        setBookingId(Data.bookingId);
        setGarageName(Data.garageDetails.garageName);
        setGarageAddress(Data.garageDetails.address);
        setTime(Data.chooseTime);
        setDate(Data.chooseDate);
        setCustomerName(Data.customer.customerName);
        setCustomerEmail(Data.customer.email);
        setCustomerPhone(Data.customer.phoneNumber);
        setCustomerVehicleBrand(Data.customer.vehicle.brand);
        setCustomerVehicleModal(Data.customer.vehicle.model);
        setCustomerVehicleFuel(Data.customer.vehicle.fuelType);
        setHelpEmail(Data.getHelpData.email);
        setHelpPhone(Data.getHelpData.phoneNumber);
        console.log(Data.garageDetails.location.coordinates);
        setLatitude(Data.garageDetails.location?.coordinates[1]);
        setLongitude(Data.garageDetails.location?.coordinates[0]);

        // setGarageData(data.data.garageDetails);
        // setScheduleData(data.data);
      } else {
        console.log("400");
        console.error("Error fetching data from the API");
      }
    } catch (error) {
      console.log("catch");
      console.error("An error occurred:", error);
    }
  };
  const CancelBooking = async () => {
    const token = localStorage.getItem("token");
    console.log(token);
    const requestedData = { serviceBookingId: apiData };
    console.log(requestedData);
    try {
      const response = await fetch(
        "https://api.vecrep.com/cancleServiceBooking",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestedData),
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      console.log(response);
      if (data.responseCode === 200) {
        setSuccessPopup(false);
        navigate(-1);
        console.log(data.message);
      } else {
        console.log("400");
        console.error("Error fetching data from the API");
      }
    } catch (error) {
      console.log("catch");
      console.error("An error occurred:", error);
    }
  };
  const closeModal = () => {
    setModal1(false);
  };
  const handleMessage = (messageData: string) => {
    console.log("handleMessage", messageData);
    setMessage(messageData);
  };

  const handleGetDirections = () => {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(mapsUrl, "_blank");
  };

  return (
    <>
      <div className='booking-container'>
        <div className='back-option' onClick={() => navigate(-1)}>
          <svg
            className='arrow'
            xmlns='http://www.w3.org/2000/svg'
            width='14'
            height='14'
            viewBox='0 0 20 21'
            fill='none'>
            <path
              d='M4 10.5L13 19.5L14.4 18L7 10.5L14.4 3L13 1.5L4 10.5Z'
              fill='black'
            />
          </svg>{" "}
          Back
        </div>

        <div className='book-message'>
          <div className='image-class'>
            <img src='assets/images/ConfirmBooking/Check.png' alt='Hello' />
          </div>
          <div className='text-message'>{message ? message : "Booking "}</div>
        </div>
        <div className='content-box'>
          <div className='content'>
            <div className='garage-details'>
              <div className='profile'>
                <div>
                  <Avatar
                    size={50}
                    gap={2}
                    style={{ fontSize: 25, color: "black" }}>
                    {garageName ? garageName.charAt(0).toUpperCase() : "G"}
                  </Avatar>
                </div>
                <div className='details'>
                  <p style={{ fontSize: "1.1rem" }}>
                    <span>{garageName}</span>
                  </p>
                  <p style={{ fontSize: "14px", color: "#475569" }}>
                    {garageAddress}
                  </p>
                </div>
              </div>
              <div className='book-id'>
                Booking id: <span>{bookingId}</span>
              </div>
            </div>
            <div className='location'>
              <div className='location-image'>
                <img
                  src='assets/images/ConfirmBooking/mdi_my-location.png'
                  alt=''
                />
              </div>
              <div className='location-text' onClick={handleGetDirections} style={{cursor:"pointer"}}>
                Get Directions
              </div>
            </div>
            <div className='schedule'>
              <div className='schedule-image'>
                <img src='assets/images/ConfirmBooking/schedule.png' alt='' />
              </div>
              <div className='schedule-time'>
                <p>
                  Date & time :{" "}
                  <span>
                    {" "}
                    {date} & {time}{" "}
                  </span>
                </p>
                <p>
                  Service name : <span>Regular Service</span>
                </p>
              </div>
            </div>
            <div className='customer'>
              <div className='customer-image'>
                <img src='assets/images/ConfirmBooking/User.png' alt='' />
              </div>
              <div className='customer-details'>
                <div>
                  <h4>Customer Details</h4>
                </div>
                <div>
                  Customer Name: <span>{customerName}</span>
                </div>
                <div>
                  Phone Number: <span>{customerPhone}</span>
                </div>
                <div>
                  Email: <span>{customerEmail}</span>
                </div>
                <div>
                  Vehicle:{" "}
                  <span>
                    {customerVehicleBrand ? `${customerVehicleBrand} / ` : " "}
                    {customerVehicleModel ? `${customerVehicleModel} / ` : ""}
                    {customerVehicleFuel ? customerVehicleFuel : ""}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='side-content'>
            <div className='help-content'>
              <div className='help'>
                <div className='contents'>
                  <div>
                    <h4>Get Help</h4>
                  </div>
                  <div>
                    <p>
                      Are you facing any issues?
                      <br />
                      we are here to help you.
                    </p>
                  </div>
                  <div>
                    Call us : <span> {helpPhone}</span>
                  </div>
                  <div>
                    Email : <span>{helpEmail}</span>
                  </div>
                </div>
                <div className='objects'>
                  <img src='assets/images/ConfirmBooking/OBJECTS.png' alt='' />
                </div>
              </div>
              <div className='options'>
                <div className='cancel-button'>
                  <button
                    className='cancel'
                    onClick={() => setSuccessPopup(true)}>
                    {" "}
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 20 20'
                      fill='none'>
                      <path
                        d='M6.99984 14.1667L9.99984 11.1667L12.9998 14.1667L14.1665 13.0001L11.1665 10.0001L14.1665 7.00008L12.9998 5.83341L9.99984 8.83342L6.99984 5.83341L5.83317 7.00008L8.83317 10.0001L5.83317 13.0001L6.99984 14.1667ZM9.99984 18.3334C8.84706 18.3334 7.76373 18.1145 6.74984 17.6767C5.73595 17.239 4.854 16.6454 4.104 15.8959C3.354 15.1459 2.76039 14.264 2.32317 13.2501C1.88595 12.2362 1.66706 11.1529 1.6665 10.0001C1.6665 8.8473 1.88539 7.76397 2.32317 6.75008C2.76095 5.73619 3.35456 4.85425 4.104 4.10425C4.854 3.35425 5.73595 2.76064 6.74984 2.32341C7.76373 1.88619 8.84706 1.6673 9.99984 1.66675C11.1526 1.66675 12.2359 1.88564 13.2498 2.32341C14.2637 2.76119 15.1457 3.3548 15.8957 4.10425C16.6457 4.85425 17.2396 5.73619 17.6773 6.75008C18.1151 7.76397 18.3337 8.8473 18.3332 10.0001C18.3332 11.1529 18.1143 12.2362 17.6765 13.2501C17.2387 14.264 16.6451 15.1459 15.8957 15.8959C15.1457 16.6459 14.2637 17.2398 13.2498 17.6776C12.2359 18.1154 11.1526 18.334 9.99984 18.3334ZM9.99984 16.6667C11.8609 16.6667 13.4373 16.0209 14.729 14.7292C16.0207 13.4376 16.6665 11.8612 16.6665 10.0001C16.6665 8.13897 16.0207 6.56258 14.729 5.27091C13.4373 3.97925 11.8609 3.33341 9.99984 3.33341C8.13873 3.33341 6.56234 3.97925 5.27067 5.27091C3.979 6.56258 3.33317 8.13897 3.33317 10.0001C3.33317 11.8612 3.979 13.4376 5.27067 14.7292C6.56234 16.0209 8.13873 16.6667 9.99984 16.6667Z'
                        fill='#565656'
                      />
                    </svg>
                    Cancel
                  </button>
                </div>
                <div className='reschedule-button'>
                  <button
                    className='reschedule'
                    onClick={() => setModal1(true)}>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      viewBox='0 0 20 20'
                      fill='none'>
                      <g clip-path='url(#clip0_11737_121150)'>
                        <path
                          d='M7.49984 16.6667H4.99984C4.11578 16.6667 3.26794 16.3156 2.64281 15.6904C2.01769 15.0653 1.6665 14.2175 1.6665 13.3334V5.83341C1.6665 4.94936 2.01769 4.10151 2.64281 3.47639C3.26794 2.85127 4.11578 2.50008 4.99984 2.50008H14.1665C15.0506 2.50008 15.8984 2.85127 16.5235 3.47639C17.1486 4.10151 17.4998 4.94936 17.4998 5.83341V8.33341M6.6665 1.66675V3.33341M12.4998 1.66675V3.33341M1.6665 6.66675H17.4998M15.4165 13.0359L14.1665 14.2859'
                          stroke='#565656'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                        <path
                          d='M14.1667 18.3333C16.4679 18.3333 18.3333 16.4679 18.3333 14.1667C18.3333 11.8655 16.4679 10 14.1667 10C11.8655 10 10 11.8655 10 14.1667C10 16.4679 11.8655 18.3333 14.1667 18.3333Z'
                          stroke='#565656'
                          stroke-width='2'
                          stroke-linecap='round'
                          stroke-linejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_11737_121150'>
                          <rect width='20' height='20' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>{" "}
                    Reschedule
                  </button>
                </div>
              </div>
              <Modal
                visible={successPopup}
                onCancel={() => setSuccessPopup(false)}
                centered
                footer={null}
                style={{ width: 200 }}>
                <div className='popup'>
                  <div className='popup-image'>
                    <img
                      src='assets/images/ConfirmBooking/Cancel_mark.png'
                      alt=' '
                    />
                  </div>
                  <div className='popup-title'>
                    <h3>Cancel Booking ?</h3>
                  </div>
                  <div className='poup-content'>
                    Are you sure you want to cancel the <br />
                    booking for your vehicle
                  </div>
                  <div className='popup-buttons'>
                    <div>
                      <button className='popup-yes' onClick={CancelBooking}>
                        Yes
                      </button>
                    </div>
                    <div>
                      <button
                        className='popup-no'
                        onClick={() => setSuccessPopup(false)}>
                        No
                      </button>
                    </div>
                  </div>
                </div>
              </Modal>
              <Modal
                visible={modal1}
                onCancel={() => setModal1(false)}
                centered
                footer={null}
                width={520}>
                <RescheduleBooking
                  customerVehicleBrand={customerVehicleBrand}
                  customerVehicleModel={customerVehicleModel}
                  customerVehicleFuel={customerVehicleFuel}
                  garageName={garageName}
                  apiData={apiData}
                  closeModal={closeModal}
                  fetchData={fetchData}
                  handleMessage={handleMessage}
                />
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ConfirmBooking;
