import React from "react";
import "./Services.css";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router";

interface Category {
  categoryImage: string;
  categoryName: string;
  // Add other properties as needed
}

interface ApiResponse {
  responseCode: number;
  data: {
    findCategories: Category[];
  };
}

interface SliderSettings {
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  nextArrow: React.ReactElement;
  prevArrow: React.ReactElement;
  dots: boolean;
  arrows: boolean;
  responsive?: {
    breakpoint: number;
    settings: {
      slidesToShow: number;
      slidesToScroll: number;
      infinite: boolean;
      arrows: boolean;
      dots: boolean;
    };
  }[];
}

function Survices() {
  const [categoriesData, setCategoriesData] = React.useState<Category[]>([]);
  const navigate = useNavigate();
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.vecrep.com/dashboardServices"
        );
        const data: ApiResponse = await response.json();
        console.log(data);
        if (data.responseCode === 200) {
          setCategoriesData(data.data.findCategories);
        } else if (data.responseCode === 400) {
          console.log("error in list of categories");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const SamplePrevArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}>
        <LeftOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const SampleNextArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}>
        <RightOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const settings: SliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 7,
    slidesToScroll: 2,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    dots: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          arrows: true,
          dots: false,
        },
      },
    ],
  };

  const navigateToListingPage = (service: string) => {
    const currentCity = localStorage.getItem('currentCity')?.toLowerCase();
    const selectedService = service.split(" ").map((word) => word.toLowerCase())
    .join("-");

    navigate(`${currentCity}/${selectedService}`);
  }
  const currentCity = localStorage.getItem('currentCity')?.toLowerCase();
  return (
    <div className='dashboard_services'>
      <h2>Looking for Bike Service?</h2>
      <div className='services_container'>
        <Slider {...settings}>
          {categoriesData.map((service, index) => (
            <div key={index} className='service'>
            <a href={`/${currentCity}/${service.categoryName.split(" ").map((word) => word.toLowerCase())
            .join("-")}`}>
              <div className='image_service'>
                <img src={service.categoryImage ? service.categoryImage : "/assets/images/bike-wrapping.png"} alt='' />
                <div className='image_overlay'></div>
              </div>
              </a>
              <div className='content'>
                <h4>{service.categoryName}</h4>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Survices;
