import React, { useState, useEffect } from "react";
import "./searchServices.css";
import { useSelector } from "react-redux";
import TopHeader from "./TopHeader";
import { Col, Row, Table } from "antd";
import type { TableProps } from "antd";
import Collapsedview from "../Collapse/Collapse";
import RelatedSearch from "./relatedSearch";
import { Link, useLocation, useParams } from "react-router-dom";
import ListGarages from "./listGarages";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";
import ServicesOffered from "./ServicesOffered";
import WhyChooseUs from "./WhyChooseUs";
import HelpGrowSection from "./HelpGrowSection";
import axios from "axios";
import { error } from "console";
import parser from "html-react-parser";
import NotFoundPage from "../Auth/404/404";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";

interface DataType {
  key: number;
  name: string;
  address: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "Name",
    dataIndex: "name",
    key: "name",
    width: "25%",
    // render: (text) => <a>{text}</a>,
  },
  {
    title: "Address",
    dataIndex: "address",
    key: "address",
    width: "75%",
  },
];

interface Content {
  title: string;
  description: string;
}

interface SeoData {
  title: string;
  paragraphData: string;
  FAQContent: Content[] | undefined;
  WhyVecrepContent: Content[] | undefined;
}

// let data: DataType[] = [];

const SearchServices: React.FC = () => {
  const location1 = useLocation();
  const pathname = location1.pathname.toLowerCase();

  const { city, service, area, garage, id } = useParams();
  console.log("city", city);
  console.log("service", service);
  console.log("area", area);
  // console.log('garage', garage);
  // console.log('id', id);

  const responseData = location1.state?.apiData;
  const vechicleData = responseData?.vechileData;
  console.log("responseData", responseData);
  const { latitude1, longitude1 } = useSelector(
    (state: { latitude1: number | null; longitude1: number | null }) => state
  );

  const [sortOrder, setSortOrder] = useState<"asc" | "desc">("asc");
  const [sortBy, setSortBy] = useState<"name" | "distance">("name");
  const [adData, setAdData] = useState("");
  const [ad1, setAd1] = useState("");
  const [ad2, setAd2] = useState("");
  const [ad3, setAd3] = useState("");
  const [initialList, setInitialList] = useState(responseData || []);
  const [sortedList, setSortedList] = useState(initialList);
  const [showTable, setShowTable] = useState(false);
  const [resp, setResp] = useState<SeoData | null>(null);
  const [isApiCalled, setIsApiCalled] = useState(false);
  const [garageListSize, setGarageListSize] = useState(0);

  const handleListSize = (size: number) => {
    setGarageListSize(size);
  };

  // split service by - to get vehicle type and service name
  // console.log('serviceSplitArr', serviceSplitArr);

  const vehicleInterface = {
    car: "1",
    bike: "2",
  };
  const [vehicleType, setVehicleType] = useState("");
  const [searchcity, setSearchCity] = useState("");
  const [selectedService, setSelectedService] = useState("");
  const [vehicleName, setvehicleName] = useState("");
  const [selectedArea, setSelectedArea] = useState("");
  const [selectedGarage, setSelectedGarage] = useState("");

  const [garagesData, setGaragesData] = useState([]); // State to store garages data for the table
  const [isGarageDataEmpty, setIsGarageDataEmpty] = useState(""); // State to rerender parent from child

  useEffect(() => {
    if (service) {
      let serviceSplitArr = service?.split("-");
      
      if (serviceSplitArr && serviceSplitArr.length > 1) {
        // Extract vehicle type from the first element of serviceSplitArr
        const vehicleTypeKey =
          serviceSplitArr[0] as keyof typeof vehicleInterface;
        // alert(vehicleTypeKey);
        const selectedVehicleType = vehicleInterface[vehicleTypeKey];

        // Join the remaining elements of serviceSplitArr to get the selectedService
        const selectedServiceJoin = serviceSplitArr.slice(1).join("-");
        let serviceName = vehicleTypeKey + "-" + selectedServiceJoin;

        // Set the vehicleType and selectedService states accordingly
        setVehicleType(selectedVehicleType);
        setSelectedService(selectedServiceJoin);
        setvehicleName(vehicleTypeKey);
        fetchSeoData(city, selectedVehicleType, serviceName);
      }
      if (selectedService) {
        // alert(selectedService);
        console.log("selectedService", selectedService);
      }
      if (vehicleType) {
        // alert(vehicleType);
        // 
        console.log(vehicleType);
      }
    }
    if (city) {
      // 
      setSearchCity(city);
    }
    if (area) {
      // 
      setSelectedArea(area);
    }
    console.log("useeffect garages data", garagesData);
  }, [service, selectedService]);

  let data: DataType[] = garagesData
    ?.slice(0, 5)
    .map((garage: any, index: number) => ({
      key: index + 1,
      name: garage.name,
      address: garage.address,
    }));

  // Callback function to receive data from ListGarages component
  const receiveGaragesData = (data: any, dataPresent: any) => {
    console.log("148 garagesData", data);
    setGaragesData(data);
    setIsGarageDataEmpty(dataPresent);
    console.log("receiveGaragesData index.tsx", data, dataPresent);
  };

  const handleSort = () => {
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
    setSortBy("name");
    applySorting(initialList, "name"); // Apply sorting to the initial list
  };

  const handleNear = () => {
    setSortBy("distance");
    applySorting(initialList, "distance");
    setSortOrder((prevSortOrder) => (prevSortOrder === "asc" ? "desc" : "asc"));
  };

  const applySorting = (listToSort: any[], sortBy: string) => {
    const sortedListCopy = [...listToSort].sort((a: any, b: any) => {
      if (sortBy === "name") {
        const comparisonValueA = String(a.garageName || "").toLowerCase();
        const comparisonValueB = String(b.garageName || "").toLowerCase();
        return sortOrder === "asc"
          ? comparisonValueA.localeCompare(comparisonValueB)
          : comparisonValueB.localeCompare(comparisonValueA);
      } else if (sortBy === "distance") {
        const distanceA = parseFloat(a.distance ?? 0); // Use 0 if a.distance is undefined
        const distanceB = parseFloat(b.distance ?? 0); // Use 0 if b.distance is undefined
        return sortOrder === "asc"
          ? distanceA - distanceB
          : distanceB - distanceA;
      }
      return 0; // Default return value to handle other cases
    });
    setSortedList(sortedListCopy);
    console.log("sortedList 191", sortedList);
  };

  const toggleTable = () => {
    setShowTable(!showTable);
  };

  useEffect(() => {
    fetchAdData();
  }, []);

  const fetchAdData = async () => {
    const requestAdData = {
      location: {
        coordinates: latitude1
          ? [latitude1, longitude1]
          : ["17.123184", "79.208824"],
      },
    };
    try {
      const response = await fetch("https://api.vecrep.com/advertisementList", {
        method: "POST",
        body: JSON.stringify(requestAdData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        setAdData(data.data);
        setAd1(data.data[0].image);
        setAd2(data.data[1].image);
        setAd3(data.data[2].image);
        console.log(data.data[0].image);
        console.log(data.message);
        console.log("Advertisement Data");
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const fetchSeoData = (
    location?: string,
    vehicleType?: string,
    service?: string
  ) => {
    console.log("topheader listgarages", selectedService);
    const requestBody = {
      serviceName: service || "",
      vehicleType: vehicleType || "",
      location: location || "",
    };
    console.log("requestBodyrequestBody seodefault", requestBody);
    if (!isApiCalled) {
      try {
        
        axios
          .post("https://api.vecrep.com/seoDefaultData", requestBody)
          .then((response) => {
            console.log("@@@@@");
            console.log("responseresponseresponse", response.data);
            if (response) {
              if (response.data.data.length > 0) {
                setIsApiCalled(true);
                console.log("setting value to resp");
                // if(response.data.data.length >= 1) {
                  setResp(response.data.data);
                // }
                // let test;
                console.log("resp list garages 236", resp);
                console.log("resp list garages resp.data", response.data.data);
              } else {
                console.log("ashish code ",resp);
              }
            } else {
              console.log("ashish code ",resp);
            }
          })
          .catch((error) => {
            console.error("then catch Error fetching data:", error);
          });
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        // setIsLoading(false);
      }
    }
  };

  // useEffect(() => {
  //   console.log('line 256', resp);
  //   console.log('searchcity', searchcity, vehicleType, selectedService)
  //   fetchSeoData(searchcity, vehicleType, selectedService);
  //   console.log('line 258', resp);

  // }, []);

  const capitalizeFirstLetter = (str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const returnVehicle = (vehicleType: string) => {
    return vehicleType == "1" ? "Car" : "Bike";
  };

  // Function to format selectedService and city
  const formatString = (str: string) => {
    if (!str) return "";
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatSelectedService = (selectedService: string) => {
    if (!selectedService) return "";

    // Split the string by hyphens, capitalize each word, then join with spaces
    return formatString(selectedService.replace(/-/g, " "));
  };

  const containsMultiple = (text: string, keyword: string, count: number) => {
    const regex = new RegExp(keyword, "g");
    const matches = text.match(regex);
    return matches && matches.length === count;
  };

  const containsMultipleCar = containsMultiple(pathname, "car", 2);
  const containsMultipleBike = containsMultiple(pathname, "bike", 2);
  const containsCarAndBike =
    pathname.includes("car") && pathname.includes("bike");

  return (
    <>
      {
       
        <>
        <Navbar />
          <div>
            <div className="services_banner">
              <img src={ad1 && ad1} alt="" />
            </div>
            <div className="vechile_nav">
              {/* <p>{vechicleData?.vehicleType === 2 ? "Bike" : "Car"}</p> */}
              <Link to="/" style={{ color: 'blue', textDecoration: 'none' }}>
                Home
              </Link>
              <Link to={`/${capitalizeFirstLetter(searchcity)}`} style={{ color: "blue", textDecoration: 'none' }}>
                {`> ${capitalizeFirstLetter(searchcity)}`}
              </Link>
              <p>
                {responseData?.keyWord == "" ||
                responseData?.keyWord == undefined
                  ? `> ${returnVehicle(vehicleType)} ${formatSelectedService(
                      selectedService
                    )} Service in ${capitalizeFirstLetter(searchcity)}`
                  : `> ${responseData?.keyWord}`}
              </p>
            </div>

            <div className="car-service">
              <h1 style={{ marginBottom: '0.5rem' }}> {responseData?.keyWord == "" ||
                responseData?.keyWord == undefined
                  ? `${returnVehicle(vehicleType)} ${formatSelectedService(
                      selectedService
                    )}`
                  : `${responseData?.keyWord}`} Service in {capitalizeFirstLetter(searchcity)}</h1>
              {(resp && resp?.paragraphData) ? parser(resp.paragraphData) : 
              <>
              VecRep.com is an online garage discovery, comparison & booking platform where motorists based on their specific vehicle needs can search for verified nearby garages, compare the trusted rating & offerings, and book the slot based on availability, get digital evidence & records of work done and avail of the offers extended by the garages.
              </>
              }
              <div className="clr"></div>
              <div className="ucwidth" id="dealerTableMore">
                <span
                  className="v-lnk mt-10 uc-readmoreless"
                  onClick={toggleTable}
                >
                  <span>
                    {showTable ? "Read Less" : "Read More"}{" "}
                    <FontAwesomeIcon
                      icon={showTable ? faAngleUp : faAngleDown}
                    />
                  </span>
                </span>
              </div>
              {showTable && (
                <>
                  <h2
                    className="hidden mt-0 ml-15 pt-15 dealer-table-heading"
                    id="dealerTableHeading"
                  >
                    {" "}
                    List of Top{" "} {garagesData.length <= 2 ? "" : garagesData.length}
                    {responseData?.keyWord == "" ||
                    responseData?.keyWord == undefined
                      ? ` ${returnVehicle(
                          vehicleType
                        )} ${formatSelectedService(selectedService)}`
                      : ` ${responseData?.keyWord}`}{" "} Service
                    in {capitalizeFirstLetter(searchcity)}
                  </h2>
                  <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                    className="custom-table"
                  />
                </>
              )}
            </div>

            <div className="middle">
              <Row>
                <Col xl={24} lg={24} sm={24} xs={24}>
                  <div className="">
                    <TopHeader
                      data={responseData}
                      onClickSort={handleSort}
                      onClickNear={handleNear}
                      vehicleType={vehicleType}
                      selectedService={selectedService}
                      searchcity={searchcity}
                      selectedArea={selectedArea}
                      garageListSize={garageListSize}
                    />
                    <div>
                      <ListGarages
                        data={sortedList}
                        vehicleData={vechicleData}
                        ad2={ad2}
                        vehicleType={vehicleType}
                        selectedService={selectedService}
                        searchcity={searchcity}
                        selectedArea={selectedArea}
                        vehicleName={vehicleName}
                        onShowTable={receiveGaragesData}
                        handleListSize={handleListSize}
                      />
                    </div>
                  </div>
                </Col>
                {/* <Col xl={4} lg={4} sm={24} xs={24}>
                  <RelatedSearch />
                </Col> */}
              </Row>
            </div>

            {
              resp && !resp.WhyVecrepContent && (
              <div className="middle resp-view">
              <h3 className="section-title text-center" style={{ marginTop: '3rem' }}>
                Why book a garage from VecRep?
              </h3>
              <Row style={{ justifyContent: "center" }}>
                <ServicesOffered WhyVecrepContent={resp?.WhyVecrepContent} />
              </Row>
            </div>
               )
            } 
            

            <div className="middle">
              <h3 className="section-title text-center">
                Why Choose VecRep.com?
              </h3>
              <Row>
                <WhyChooseUs />
              </Row>
            </div>

            <div className="container">
              <h3 className="text-center section-title">
                The all-in-one place to book a nearby verified garage
              </h3>
              <Row style={{ alignItems: "center" }}>
                <HelpGrowSection />
              </Row>
            </div>

            {
              resp && !resp.FAQContent && (

            <div className="container">
              <h3 className="text-center section-title">
                Frequently Asked Questions
              </h3>
              <Row>
                <Collapsedview FAQContent={resp?.FAQContent} />
              </Row>
            </div>
              )
            }
          </div>
          <Footer />
        </>
      }
    </>
  );
};

export default SearchServices;
