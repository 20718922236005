import React, { useState, useEffect, useRef } from "react";
import "./GarageDetails.css";
import { Radio, Modal, Row, Col } from "antd";
import Topgarages from "../Top_Garages/Topgarages";
import Survices from "../Services/Survices";
import Collapsedview from "../Collapse/Collapse";
import { useNavigate, useLocation, useParams, Link } from "react-router-dom";
import { Dropdown, Space, Typography, Menu } from "antd";
import LoginForm from "../Auth/Login";
import SignUpForm from "../Auth/signup";
import GetForm from "../searchServices/getForm";
import BookForm from "../searchServices/BookForm";
import Demo from "./Share";
import { message } from "antd";
import { DownOutlined, HeartFilled, HeartOutlined } from "@ant-design/icons";
import OperateCities from "../searchServices/OperateCities";
import axios from "axios";
import NotFoundPage from "../Auth/404/404";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
// import { Helmet } from "react-helmet-async";
// import { HelmetProvider } from "react-helmet-async";
// const accordionData = [
//   {
//     header:
//       "Q. What is Lorem Ipsum simply dummy text of the printing and typesetting industry?",
//     body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
//   },
//   {
//     header:
//       "2. What is Lorem Ipsum simply dummy text of the printing and typesetting industry?",
//     body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
//   },
//   {
//     header:
//       "3. What is Lorem Ipsum simply dummy text of the printing and typesetting industry?",
//     body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
//   },
//   {
//     header:
//       "4. What is Lorem Ipsum simply dummy text of the printing and typesetting industry?",
//     body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
//   },
//   {
//     header:
//       "5. What is Lorem Ipsum simply dummy text of the printing and typesetting industry?",
//     body: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.",
//   },
// ];
const items: { key: string; label: string }[] = [
  {
    key: "Mon",
    label: "Monday",
  },
  {
    key: "Tue",
    label: "Tuesday",
  },
  {
    key: "Wed",
    label: "Wednesday",
  },
  {
    key: "Thur",
    label: "Thursday",
  },
  {
    key: "Fri",
    label: "Friday",
  },
  {
    key: "Sat",
    label: "Saturday",
  },
  {
    key: "Sun",
    label: "Sunday",
  },
];
const faq = [
  {
    title:
      "What types of car and bike repair services does VecRep offer online?",
    description:
      "VecRep.com has partnered with more than 40 verified garages across different cities so that you can choose anyone closest to your location. You can avail of repair and maintenance services for all car and bike models of every brand. Visit our services page for all the details, or call us for assistance.",
  },
  {
    title:
      "Can I schedule a vehicle service for a specific date and time that suits my convenience?",
    description:
      "We are available round the clock. You can choose any of our verified garages, closer to your home or office, and even the date and time according to your convenience. The idea is to serve your vehicle requirements and any emergency situation. VecRep.com is open 24x7. Please feel free to call us anytime to assist you.",
  },
  {
    title: "How can I contact customer support at VecRep?",
    description:
      "You can call us on 9056262909 anytime you need assistance. Our customer service representatives are available round the clock to hear you and provide the needed assistance.",
  },
  {
    title:
      "What payment options are available for booking car and bike services online?",
    description:
      "We accept, debit and credit cards, payments through cash, and UPI transactions as well. You can pay us anyhow you are comfortable with.",
  },
  {
    title: "How can I book a car or bike service online on your website?",
    description:
      "To book car or bike services online, you need to visit our website, choose your location and select the type of service you want. You can choose your schedule as well. It’s a simple process and hardly takes a minute to book VecRep.com’s car or bike services online.",
  },
  {
    title: "Is it possible to track the status of my vehicle service online?",
    description:
      "You can track the progress of your vehicle’s servicing process in real-time and plan your schedule accordingly. Updates of every step get automatically updated on our system enabling you to track it in real time and suit your convenience.",
  },
];
interface Service {
  item: string;
  type: string;
  id: string;
}

const vehicleInterface = {
  car: "1",
  bike: "2",
};

const GarageDetails = () => {
  const [selectedValue, setSelectedValue] = useState("car");
  const [garageName, setGarageName] = useState("");
  const [garageType, setGarageType] = useState();
  const [ownerNumber, setOwnerNumber] = useState("");
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [activeSection, setActiveSection] = useState<string | null>(null);
  const servicesRef = useRef<HTMLDivElement | null>(null);
  const offersRef = useRef<HTMLDivElement | null>(null);
  const galleryRef = useRef<HTMLDivElement | null>(null);
  const reviewsRef = useRef<HTMLDivElement | null>(null);
  const qnaRef = useRef<HTMLDivElement | null>(null);
  const [carServicesData, setCarServicesData] = useState([]);
  const [bikeServicesData, setBikeServicesData] = useState([]);
  const [galleryData, setGalleryData] = useState([]);
  const [serviceNames, setServiceNames] = useState([]);
  const [selectedDay, setSelectedDay] = useState("Fri");
  const [offersData, setOffersData] = useState([]);
  const [couponsCount, setCouponsCount] = useState("");
  const [isCopied, setIsCopied] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setisSignupModelOpen] = useState(false);
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const [garageId, setGarageId] = useState("");
  const [garageImage1, setGarageImage1] = useState("");
  const [garageImage2, setGarageImage2] = useState("");
  const [garageImage3, setGarageImage3] = useState("");
  const [garageImage4, setGarageImage4] = useState("");
  const [shareModal, setShareModal] = useState(false);
  const [isFavourite, setIsFavourite] = useState("");
  const [favouriteId, setFavouriteId] = useState("");
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(false);
  const [jobs, setJobs] = useState(0);
  const [imageCount, setImageCount] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [modalServices, setModalServices] = useState<Service[]>([]);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [searchcity, setSearchCity] = useState("");
  const [selectedGarage, setSelectedGarage] = useState("");
  const [selectedGarageAddress, setSelectedGarageAddress] = useState("");
  const [selectedGarageId, setSelectedGarageId] = useState("");
  const [resp, setResp] = useState("");

  const handleViewMore = (services: Service[]) => {
    setModalServices(services);
    setShowModal(true);
  };
  const responseData1 = location.state?.apiData;

  const garageId1 = responseData1?.ObjectId; // Replace 'your_garage_id' with the actual garageId
  console.log(garageId);

  useEffect(() => {
    async function fetchData() {
      const token = localStorage.getItem("token"); // Assuming getToken is a function to get the token
      const source = "Service Search"; // Re
      if (token) {
        try {
          // Make the API request with the token
          const response = await axios.post(
            "https://api.vecrep.com/searchService",
            {
              garageId: id,
              source,
              // Your request data here
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          console.log("API Response:", response.data);
        } catch (error) {
          console.error("Error making API request:", error);
        }
      } else {
        console.log("Token is null. API not hit.");
      }
    }

    fetchData();
  }, []);
  const handleToggleFavorite = async () => {
    const token = localStorage.getItem("token");

    if (token != undefined) {
      try {
        if (isFavorite) {
          console.log("Removing from favourites");
          removeFavourites();
        } else {
          addFavourites();
          console.log("Adding to favourites");
        }

        // Toggle the favorite status locally
        setIsFavorite(!isFavorite);
      } catch (error) {
        console.error("Error updating favorites:", error);
      } finally {
        setLoading(false);
      }
    } else {
      return setIsLoginModalOpen(true);
    }
  };

  const handleCopyClick = (dataToCopy: any) => {
    const textarea = document.createElement("textarea");
    textarea.value = dataToCopy;

    document.body.appendChild(textarea);
    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    message.success("Copied Successfully");
    setIsCopied(true);
  };

  const onCloseSignupModal = () => {
    setisSignupModelOpen(false);
  };
  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  const openSignupModal = () => {
    setisSignupModelOpen(true);
  };
  const openLoginModal = () => {
    setModal(false);
    setModal1(false);
    setIsLoginModalOpen(true);
  };
  const close = () => {
    setModal(false);
    setModal1(false);
  };
  const token = localStorage.getItem("token");

  const scrollToSection = (id: string) => {
    const targetElement = document.getElementById(id);

    if (targetElement) {
      const offset = 80;
      const targetPosition =
        targetElement.getBoundingClientRect().top +
        window.scrollY + // Add current scroll position
        targetElement.offsetHeight / 2 -
        window.innerHeight / 2 +
        20;

      window.scrollTo({
        top: targetPosition,
        behavior: "smooth",
      });
      // Get the current URL
      const currentUrl = window.location.pathname;

      // Split the URL by '/'
      const segments = currentUrl.split("/");
      segments[5] = id;

      // Append the new segment to the base URL
      const newUrl = `${segments.join("/")}`;
      navigate(newUrl);
    }
  };
  /* useEffect(() => {
     const handleScroll = () => {
       const sections = [
         "services",
         "offers",
         "gallery",
         "reviews",
         "qna",
       ];
       const windowHeight = window.innerHeight;

       for (const section of sections) {
         const element = document.getElementById(section);
         if (element) {
           const { top, bottom } = element.getBoundingClientRect();
           const sectionCenter = (top + bottom) / 2;

           if (sectionCenter >= 0 && sectionCenter <= windowHeight) {
             setActiveSection(section);
             break;
           }
         }
       }
     };

     window.addEventListener("scroll", handleScroll);

      // Cleanup the event listener on component unmount
     return () => {
       window.removeEventListener("scroll", handleScroll);
     };
   }, []); */

  // SCROLL FUNCTION - ANUBHAV
  const { city, garage, area, id, section, ObjectId } = useParams(); // Extract section from URL parameters

  useEffect(() => {
    if (section) {
      // Scroll to the section when the component mounts
      const scrollToSection = () => {
        const element = document.getElementById(section);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      };

      scrollToSection(); // Scroll to the section
      setActiveSection(section);
    }
  }, [section]);

  useEffect(() => {
    if (city) {
      setSearchCity(city);
    }
    if (garage) {
      setSelectedGarage(garage);
    }
    if (area) {
      let userArea = "";
      const selectedArea = area.split("-");
    if (selectedArea.length > 1) {
      selectedArea.map((part: any, i: number) => {
        if(i == 0) {
          userArea = part;
        } else {          
          userArea = userArea + " " + part;
        }
      });
    }
      setSelectedGarageAddress(userArea);
    }
    if (id) {
      setSelectedGarageId(id);
    }

    console.log(
      "garagedetails useeffect",
      searchcity,
      selectedGarage,
      selectedGarageAddress,
      selectedGarageId
    );
    if(searchcity && selectedGarage && selectedGarageAddress && selectedGarageId) {
      fetchDataFromUrl(
        searchcity,
        selectedGarageAddress,
        "",
        "",
        selectedGarage,
        selectedGarageId
      );
    }
    // else {
    //   fetchData();
    // }
  }, [searchcity, selectedGarage, selectedGarageAddress, selectedGarageId]);

  const responseData = location.state?.apiData;
  console.log("responseData garageDetails 301", responseData?.ObjectId);

  const vehicleData = location.state?.vehicle;

  const vehicleType = location.state?.vehicle?.vehicleType;
  console.log(vehicleType);

  const favouriteStatus = location.state?.favouriteStatus;
  console.log(favouriteStatus);

  const myFavoriteId = location.state?.favouriteId;
  console.log(myFavoriteId);

  useEffect(() => {
    fetchGallery();
    fetchOffers();
    if (vehicleType === 1) {
      fetchCarsData();
      fetchBikesData();
    } else if (vehicleType === 2) {
      fetchBikesData();
      fetchCarsData();
    }
  }, [responseData, favouriteId]);

  useEffect(() => {
    if (favouriteStatus !== undefined) {
      setIsFavorite(favouriteStatus);
    }
  }, [favouriteStatus]);

  let inverseVehicle = vehicleType === 1 ? 2 : 1;
  console.log("Inverse Vehicle Type:", inverseVehicle);

  const fetchData = async () => {
    const requestedData = {
      garageId: responseData.garageId,
    };
    try {
      const response = await fetch("https://api.vecrep.com/getSingleGarage", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        const garageData = data.data.garageDetails;
        setGarageName(garageData.garageName);
        setGarageType(garageData.garageType);
        setOwnerNumber(garageData.phoneNumber);
        setIsFavorite(garageData.favoriteStatus);
        setFavouriteId(garageData.myFavoriteId);
        setAddress(garageData.address);
        setServiceNames(garageData.serviceNames);
        setGarageId(garageData.gearageId);
        setJobs(garageData.jobsDone);
        setLatitude(garageData.location?.coordinates[1]);
        setLongitude(garageData.location?.coordinates[0]);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchCarsData = async () => {
    const requestedData = {
      garageId: responseData,
      vehicleType: vehicleType,
      type: "services",
    };
    try {
      const response = await fetch("https://api.vecrep.com/listImages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setCarServicesData(data.data);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchBikesData = async () => {
    const requestedData = {
      garageId: responseData,
      vehicleType: inverseVehicle,
      type: "services",
    };
    try {
      const response = await fetch("https://api.vecrep.com/listImages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setBikeServicesData(data.data);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchOffers = async () => {
    const requestedData = {
      garageId: responseData,
      vehicleType: vehicleType,
      type: "offers",
    };
    try {
      const response = await fetch("https://api.vecrep.com/listImages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setOffersData(data?.data);
        setCouponsCount(data?.data?.length);
        console.log("Number of coupons", data.data.length);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchGallery = async () => {
    const requestedData = {
      garageId: responseData,
      vehicleType: vehicleType,
      type: "gallery",
    };
    try {
      const response = await fetch("https://api.vecrep.com/listImages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setGalleryData(data.data);
        console.log(data.data[0].image);
        console.log(data.data?.length);
        setImageCount(data.data?.length);
        setGarageImage1(data.data[0]?.image);
        setGarageImage2(data.data[1]?.image);
        setGarageImage3(data.data[2]?.image);
        setGarageImage4(data.data[3]?.image);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const addFavourites = async () => {
    const token = localStorage.getItem("token");
    const neededData = {
      garageId: responseData,
    };
    try {
      const response = await fetch("https://api.vecrep.com/myFavoite", {
        method: "POST",
        body: JSON.stringify(neededData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setFavouriteId(data.data?.myFavoriteId);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleRadioChange = (e: any) => {
    setSelectedValue(e.target.value);
  };

  const [activeIndex, setActiveIndex] = useState<number | null>(null);

  const toggleAccordion = (index: number) => {
    setActiveIndex((prevIndex) => (prevIndex === index ? null : index));
  };
  const handleCallNow = () => {
    window.location.href = `tel:${ownerNumber}`;
  };
  const handleMenuSelect = ({ key }: { key: string }) => {
    setSelectedDay(key);
  };

  const removeFavourites = async () => {
    const token = localStorage.getItem("token");
    const requestData = {
      myFavoriteId: myFavoriteId || favouriteId,
    };
    try {
      const response = await fetch(
        "https://api.vecrep.com/cancelleMyFavorite",
        {
          method: "PUT",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleGetDirections = () => {
    const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${latitude},${longitude}`;
    window.open(mapsUrl, "_blank");
  };

  const fetchDataFromUrl = async (
    city?: string,
    area?: string,
    vehicleType?: string,
    service?: string,
    garageName?: string,
    garageId?: string
  ) => {
    const requestedData = {
      city: city || "",
      area: (area != '-')?area:"" || "",
      vechileType: vehicleType || "",
      service: service || "",
      garageName: garageName || "",
      garageId: garageId || "",
    };

    try {
      const response = await fetch("https://api.vecrep.com/fetchGarages", {
        method: "POST",
        body: JSON.stringify(requestedData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log("url response", data.data);
        // if (data.message == "No Garage Found ") {
        setResp(data.message);
        // }
        // return;
        const garageData = data.data;
        
        console.log("garage data url", garageData);
        setGarageName(garageData.garageName);
        setGarageType(garageData.garageType);
        setOwnerNumber(garageData.phoneNumber);
        setIsFavorite(garageData.favoriteStatus);
        setFavouriteId(garageData.myFavoriteId);
        setAddress(garageData.address);
        setServiceNames(garageData.serviceNames);
        setGarageId(garageData.gearageId);
        setJobs(garageData.jobsDone);
        setLatitude(garageData.location?.coordinates[1]);
        setLongitude(garageData.location?.coordinates[0]);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const capitalizeFirstLetter = (str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const returnVehicle = (vehicleType: string) => {
    return vehicleType == "1" ? "Car" : "Bike";
  };

  return (
    <>
      {resp && resp == "No Garage Found " ? (
        <>
          <NotFoundPage />
        </>
      ) : (
        <>
          <Navbar />
          <div>
            <div className='garageDetails-container'>
              <div className='inner-container1'>
                <div className='container1-topcontent'>
                  <Row>
                    <Col xl={12} sm={24} xs={24}>
                      <div className='title'>
                        <h1 className='caption'>
                          {garageName ? garageName : "-"}
                        </h1>
                        <div className='title-image'>
                          <img
                            src='/assets/images/GarageDetails/badge.png'
                            alt=''
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} sm={24} xs={24}>
                      <div className='rating-call-book'>
                        <Row>
                          {/* <Col xl={12} sm={24} xs={24}> */}
                          {/* <div className='rating-col'>
                        <div className='rating-text'>
                          <div className='text'>4.0</div>
                          <div className='icon'>
                            <img src='/assets/images/white_star.png' alt='' />
                          </div>
                        </div>
                        <div className='services-count'>
                          <p>
                            {jobs && jobs > 0 ? `${jobs} jobs completed` : ""}{" "} */}
                          {/* {jobs && jobs > 0 ? `${jobs} jobs` : "No jobs "}{" "} */}
                          {/* </p> */}
                          {/* <p>completed</p> */}
                          {/* </div>
                      </div> */}
                          {/* </Col> */}
                          <Col xl={24}>
                            <Row>
                              <Col xl={12}>
                                <div
                                  className='callnow-col'
                                  onClick={handleCallNow}>
                                  <button className='callnow'>
                                    <div>
                                      <svg
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='18'
                                        height='18'
                                        viewBox='0 0 33 32'
                                        fill='none'>
                                        <path
                                          d='M10.6437 3.25201L12.0797 2.79334C13.4237 2.36401 14.861 3.05868 15.437 4.41601L16.5837 7.12001C17.0837 8.29734 16.8063 9.68268 15.8983 10.544L13.3743 12.9413C13.5303 14.376 14.0117 15.788 14.8197 17.1773C15.5875 18.5216 16.6171 19.6981 17.8477 20.6373L20.8823 19.624C22.0317 19.2413 23.2837 19.6827 23.989 20.7187L25.6317 23.132C26.453 24.3373 26.305 25.9987 25.2877 27.02L24.197 28.1147C23.1117 29.204 21.5623 29.6 20.1277 29.152C16.7423 28.096 13.6303 24.9613 10.7903 19.748C7.94635 14.5267 6.94368 10.0947 7.77968 6.45734C8.13168 4.92668 9.22235 3.70668 10.6463 3.25201H10.6437Z'
                                          fill='#231F20'
                                        />
                                      </svg>
                                    </div>
                                    <div>Call now</div>
                                  </button>
                                </div>
                              </Col>
                              <Col xl={12}>
                                <div className='bookfree-col'>
                                  <button
                                    className='bookfree'
                                    onClick={() => setModal1(true)}>
                                    Book For Free
                                  </button>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className='container1-bottomcontent'>
                  {garageImage1 && garageImage2 ? (
                    <>
                      <div className='image1'>
                        <img src={garageImage1} alt='image1' />
                      </div>
                      <div className='image3'>
                        <img src={garageImage3} alt='image2' />
                      </div>
                      <div className='two-images'>
                        <div className='image4'>
                          <img src={garageImage4} alt='image4' />
                        </div>
                        <div className='image2'>
                          <div className='image-overlay'>
                            <img src={garageImage2} alt='image3' />
                            <div className='content'>
                              <p
                                onClick={() => scrollToSection("gallery")}
                                style={{ cursor: "pointer" }}>
                                View all{imageCount && ` (${imageCount - 4}+)`}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className='image1-text'>
                      <div> No garage images are available.</div>{" "}
                    </div>
                  )}
                </div>
                <div
                  className='vechile_nav'
                  style={{ width: "100%", margin: "0" }}>
                  {/* <p>{vechicleData?.vehicleType === 2 ? "Bike" : "Car"}</p> */}
                  <Link
                    to="/"
                    style={{ color: "blue", textDecoration: "none" }}
                  >
                    Home
                  </Link>
                  <Link
                    to={`/${capitalizeFirstLetter(searchcity)}`}
                    style={{ color: "blue", textDecoration: "none" }}
                  >
                    {`> ${capitalizeFirstLetter(searchcity)}`}
                  </Link>
                  <p>{garageName ? "> " + garageName : "-"}</p>
                </div>
              </div>
              <div className='hidden-container'>
                <div className='hidden-content'>
                  <div className='hidden-left'>
                    <div className='hours'>Open Hours</div>
                    <div className='week-timeline'>
                      <div className='week'>
                        <p className='day'>
                          <span className='friday'>
                            {selectedDay ? selectedDay : " - "}&nbsp;
                          </span>
                          <span>
                            <Dropdown
                              overlay={
                                <Menu
                                  onClick={handleMenuSelect}
                                  selectedKeys={[selectedDay]}>
                                  {items.map((item) => (
                                    <Menu.Item key={item.key}>
                                      {item.label}
                                    </Menu.Item>
                                  ))}
                                </Menu>
                              }
                              trigger={["click"]}>
                              <Typography.Link>
                                <Space>
                                  <DownOutlined
                                    style={{ color: "black", fontSize: "12px" }}
                                  />
                                </Space>
                              </Typography.Link>
                            </Dropdown>
                          </span>
                        </p>
                      </div>
                      <div className='time'>
                        10:00 am{" "}
                        <span style={{ fontWeight: "normal" }}>to</span> 06:00
                        pm
                      </div>
                    </div>
                  </div>
                  <div className='hidden-right'>
                    <p
                      className='border-class'
                      onClick={() => setShareModal(true)}>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='20'
                        height='18'
                        viewBox='0 0 22 22'
                        fill='none'>
                        <path
                          d='M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.1167 8.99167 12.2377 8.975 12.363C8.95833 12.4883 8.93333 12.6007 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z'
                          fill='black'
                        />
                      </svg>
                    </p>
                    <div className='favourites-col time-garage-list'>
                      <p
                        className='border-class'
                        onClick={handleToggleFavorite}
                        style={{ cursor: "pointer" }}>
                        {isFavorite ? (
                          <>
                            <div>
                              <HeartFilled
                                style={{ color: "#b11226", fontSize: "20px" }}
                              />
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <HeartOutlined style={{ fontSize: "20px" }} />
                            </div>
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className='inner-container2'>
                <div className='container2-content'>
                  <div className='content1'>
                    <h2 className='title'>
                      {garageName ? garageName : "Garage name not available"}
                    </h2>
                    <div className='options time-garage-name'>
                      <div className='share-col time-garage-list'>
                        <button
                          className='share'
                          onClick={() => setShareModal(true)}>
                          <div className='share-icon'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='20'
                              viewBox='0 0 24 24'
                              fill='none'>
                              <path
                                d='M18 22C17.1667 22 16.4583 21.7083 15.875 21.125C15.2917 20.5417 15 19.8333 15 19C15 18.8833 15.0083 18.7623 15.025 18.637C15.0417 18.5117 15.0667 18.3993 15.1 18.3L8.05 14.2C7.76667 14.45 7.45 14.646 7.1 14.788C6.75 14.93 6.38333 15.0007 6 15C5.16667 15 4.45833 14.7083 3.875 14.125C3.29167 13.5417 3 12.8333 3 12C3 11.1667 3.29167 10.4583 3.875 9.875C4.45833 9.29167 5.16667 9 6 9C6.38333 9 6.75 9.071 7.1 9.213C7.45 9.355 7.76667 9.55067 8.05 9.8L15.1 5.7C15.0667 5.6 15.0417 5.48767 15.025 5.363C15.0083 5.23833 15 5.11733 15 5C15 4.16667 15.2917 3.45833 15.875 2.875C16.4583 2.29167 17.1667 2 18 2C18.8333 2 19.5417 2.29167 20.125 2.875C20.7083 3.45833 21 4.16667 21 5C21 5.83333 20.7083 6.54167 20.125 7.125C19.5417 7.70833 18.8333 8 18 8C17.6167 8 17.25 7.92933 16.9 7.788C16.55 7.64667 16.2333 7.45067 15.95 7.2L8.9 11.3C8.93333 11.4 8.95833 11.5127 8.975 11.638C8.99167 11.7633 9 11.884 9 12C9 12.1167 8.99167 12.2377 8.975 12.363C8.95833 12.4883 8.93333 12.6007 8.9 12.7L15.95 16.8C16.2333 16.55 16.55 16.3543 16.9 16.213C17.25 16.0717 17.6167 16.0007 18 16C18.8333 16 19.5417 16.2917 20.125 16.875C20.7083 17.4583 21 18.1667 21 19C21 19.8333 20.7083 20.5417 20.125 21.125C19.5417 21.7083 18.8333 22 18 22Z'
                                fill='black'
                              />
                            </svg>
                          </div>
                          <div className='share-text time-garage-name'>
                            Share
                          </div>
                        </button>
                      </div>
                      <div className='favourites-col time-garage-list'>
                        <button
                          className='favourites'
                          onClick={handleToggleFavorite}
                          style={{ cursor: "pointer" }}>
                          {isFavorite ? (
                            <>
                              <div>
                                <HeartFilled style={{ color: "#b11226" }} />
                              </div>
                              <div className='fav-text time-garage-name'>
                                Remove from favourites
                              </div>
                            </>
                          ) : (
                            <>
                              <div>
                                <HeartOutlined />
                              </div>
                              <div className='fav-text time-garage-name'>
                                Add to favourites
                              </div>
                            </>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className='content2'>
                    <div
                      className='garagetype
'>
                      <small>{garageType === 1 ? "Car" : "Bike"} Garage</small>{" "}
                    </div>
                    <div className='timeliness time-garage-name'>
                      <div className='hours'>Open Hours</div>
                      <div className='week'>
                        <div className='day'>
                          {" "}
                          {selectedDay ? selectedDay : " - "}{" "}
                        </div>
                        <div>
                          <Dropdown
                            overlay={
                              <Menu
                                onClick={handleMenuSelect}
                                selectedKeys={[selectedDay]}>
                                {items.map((item) => (
                                  <Menu.Item key={item.key}>
                                    {item.label}
                                  </Menu.Item>
                                ))}
                              </Menu>
                            }
                            trigger={["click"]}>
                            <Typography.Link>
                              <Space>
                                <DownOutlined
                                  style={{ color: "black", fontSize: "12px" }}
                                />
                              </Space>
                            </Typography.Link>
                          </Dropdown>
                        </div>
                      </div>
                      <div className='time'>
                        10:00 am{" "}
                        <span style={{ fontWeight: "normal" }}>to</span> 06:00
                        pm
                      </div>
                    </div>
                  </div>
                  <div className='content3'>
                    <div className='heading'>Specialised in</div>
                    <div className='services'>
                      {serviceNames && serviceNames.length > 0 ? (
                        <>
                          {serviceNames.slice(0, 4).map((item, index) => (
                            <div key={index} className='service single-ser'>
                              {" "}
                              <small className=''>{item}</small>
                            </div>
                          ))}
                          {serviceNames.length > 4 && (
                            <div
                              className='single-garage-service-viewmore'
                              onClick={() => handleViewMore(serviceNames)}>
                              View More
                            </div>
                          )}
                        </>
                      ) : (
                        <p className='single-service-notfount'>
                          No services found
                        </p>
                      )}
                    </div>
                  </div>
                  <div className='content4'>
                    <div className='location-add'>
                      <div className='location-icon'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'>
                          <path
                            d='M12 11.5C11.337 11.5 10.7011 11.2366 10.2322 10.7678C9.76339 10.2989 9.5 9.66304 9.5 9C9.5 8.33696 9.76339 7.70107 10.2322 7.23223C10.7011 6.76339 11.337 6.5 12 6.5C12.663 6.5 13.2989 6.76339 13.7678 7.23223C14.2366 7.70107 14.5 8.33696 14.5 9C14.5 9.3283 14.4353 9.65339 14.3097 9.95671C14.1841 10.26 13.9999 10.5356 13.7678 10.7678C13.5356 10.9999 13.26 11.1841 12.9567 11.3097C12.6534 11.4353 12.3283 11.5 12 11.5ZM12 2C10.1435 2 8.36301 2.7375 7.05025 4.05025C5.7375 5.36301 5 7.14348 5 9C5 14.25 12 22 12 22C12 22 19 14.25 19 9C19 7.14348 18.2625 5.36301 16.9497 4.05025C15.637 2.7375 13.8565 2 12 2Z'
                            fill='black'
                          />
                        </svg>
                      </div>
                      <div className='location-text'>Address</div>
                    </div>
                    <div>Door no:- {address ? address : "-"}</div>
                    <div className='directions'>
                      <div className='directions-logo'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='18'
                          height='18'
                          viewBox='0 0 24 24'
                          fill='none'>
                          <path
                            d='M12 8C13.0609 8 14.0783 8.42143 14.8284 9.17157C15.5786 9.92172 16 10.9391 16 12C16 13.0609 15.5786 14.0783 14.8284 14.8284C14.0783 15.5786 13.0609 16 12 16C10.9391 16 9.92172 15.5786 9.17157 14.8284C8.42143 14.0783 8 13.0609 8 12C8 10.9391 8.42143 9.92172 9.17157 9.17157C9.92172 8.42143 10.9391 8 12 8ZM3.05 13H1V11H3.05C3.5 6.83 6.83 3.5 11 3.05V1H13V3.05C17.17 3.5 20.5 6.83 20.95 11H23V13H20.95C20.5 17.17 17.17 20.5 13 20.95V23H11V20.95C6.83 20.5 3.5 17.17 3.05 13ZM12 5C10.1435 5 8.36301 5.7375 7.05025 7.05025C5.7375 8.36301 5 10.1435 5 12C5 13.8565 5.7375 15.637 7.05025 16.9497C8.36301 18.2625 10.1435 19 12 19C13.8565 19 15.637 18.2625 16.9497 16.9497C18.2625 15.637 19 13.8565 19 12C19 10.1435 18.2625 8.36301 16.9497 7.05025C15.637 5.7375 13.8565 5 12 5Z'
                            fill='#565656'
                          />
                        </svg>
                      </div>
                      <div
                        className='directions-text'
                        onClick={handleGetDirections}
                        style={{ cursor: "pointer" }}>
                        Get Directions
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className='getQuote get-quote-disaply getQuote-list'
                style={{ cursor: "pointer" }}
                onClick={() => setModal(true)}>
                Get Quote
              </div>
              <div className='inner-container3'>
                {/* <h2
                  className={`services-nav ${
                    activeSection === "services" ? "active" : ""
                  }`}
                  onClick={() => scrollToSection("services")}
                  style={{ cursor: "pointer",fontSize: "1.4rem",
    fontWeight: "500",
    textTransform: "capitalize" }}
                >
                  Services
                </h2> */}
                {offersData && offersData.length > 0 ? (
                  <h2
                    className={`offers-nav ${
                      activeSection === "offers" ? "active" : ""
                    }`}
                    onClick={() => scrollToSection("offers")}
                    style={{
                      cursor: "pointer",
                      fontSize: "1.4rem",
                      fontWeight: "500",
                      textTransform: "capitalize",
                    }}>
                    Offers
                  </h2>
                ) : null}
                <h2
                  className={`gallery-nav ${
                    activeSection === "gallery" ? "active" : ""
                  }`}
                  onClick={() => scrollToSection("gallery")}
                  style={{
                    cursor: "pointer",
                    fontSize: "1.4rem",
                    fontWeight: "500",
                    textTransform: "capitalize",
                  }}>
                  Gallery
                </h2>

                {/* <h2
                  className={`reviews-nav ${
                    activeSection === "reviews" ? "active" : ""
                  }`}
                  onClick={() => scrollToSection("reviews")}
                  style={{ cursor: "pointer",fontSize: "1.4rem",
    fontWeight: "500",
    textTransform: "capitalize" }}
                >
                  Reviews
                </h2> */}
                {/* <h2
                  className={`answer-nav ${
                    activeSection === "qna" ? "active" : ""
                  }`}
                  onClick={() => scrollToSection("qna")}
                  style={{ cursor: "pointer",fontSize: "1.4rem",
    fontWeight: "500",
    textTransform: "capitalize" }}
                >
                  Q&A
                </h2> */}
                <div
                  className='getQuote time-garage-name'
                  style={{ cursor: "pointer" }}
                  onClick={() => setModal(true)}>
                  Get Quote
                </div>
              </div>
              {(selectedValue === "car" &&
                carServicesData &&
                carServicesData.length > 0) ||
              (selectedValue === "bike" &&
                bikeServicesData &&
                bikeServicesData.length > 0) ? (
                <div className='inner-container4' id='services'>
                  <div className='container4-content'>
                    <div className='services-heading'>
                      <h2>Services</h2>
                    </div>
                    <div className='vehicle-change'>
                      Vehicle Type &nbsp;&nbsp;
                      <Radio.Group
                        onChange={handleRadioChange}
                        value={selectedValue}>
                        <Radio value='car'>Car</Radio>
                        <Radio value='bike'>Bike</Radio>
                      </Radio.Group>
                    </div>
                  </div>
                  {selectedValue === "car" && (
                    <>
                      {carServicesData && carServicesData.length > 0 ? (
                        <>
                          <div className='container4-content2'>
                            {carServicesData.map(
                              (
                                { serviceName, note, category, image },
                                index
                              ) => (
                                <div className='card-container' key={index}>
                                  <div className='card-image'>
                                    <img
                                      src={
                                        image
                                          ? image
                                          : "//assets/images/GarageDetails/service-image.png"
                                      }
                                      alt='Service'
                                    />
                                  </div>
                                  <div className='card-text'>
                                    <div className='serviceName'>
                                      {serviceName ? serviceName : "-"}
                                    </div>
                                    <div className='categoryName'>
                                      {category ? category : "-"}
                                    </div>
                                    <div className='description'>
                                      {note ? note : "empty"}
                                    </div>
                                    <div className='discount'>
                                      <span>10%</span> Discount available
                                    </div>
                                    <div className='quote-button'>
                                      <button
                                        className='quote'
                                        onClick={() => setModal(true)}>
                                        Get Quote
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <div className='services-text-container'>
                          <div className='services-text'>
                            Services data not available
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  {selectedValue === "bike" && (
                    <>
                      {bikeServicesData && bikeServicesData.length > 0 ? (
                        <>
                          <div className='container4-content2'>
                            {bikeServicesData.map(
                              (
                                { serviceName, note, category, image },
                                index
                              ) => (
                                <div className='card-container' key={index}>
                                  <div className='card-image'>
                                    <img
                                      src={
                                        image
                                          ? image
                                          : "//assets/images/GarageDetails/service-image.png"
                                      }
                                      alt='Service'
                                    />
                                  </div>
                                  <div className='card-text'>
                                    <div className='serviceName'>
                                      {serviceName ? serviceName : "-"}
                                    </div>
                                    <div className='categoryName'>
                                      {category ? category : "-"}
                                    </div>
                                    <div className='description'>
                                      {note ? note : "empty"}
                                    </div>
                                    <div className='discount'>
                                      <span>10%</span> Discount available
                                    </div>
                                    <div className='quote-button'>
                                      <button
                                        className='quote'
                                        onClick={() => setModal(true)}>
                                        Get Quote
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              )
                            )}
                          </div>
                        </>
                      ) : (
                        <div className='services-text-container'>
                          <div className='services-text'>
                            Services data not available
                          </div>
                        </div>
                      )}
                    </>
                  )}

                  <div className='service-footer'>
                    <div className='entries'>Showing 1 to 8 of 56 Services</div>
                    <div className='view-more'>View more</div>
                  </div>
                </div>
              ) : null}

              {offersData && offersData.length > 0 ? (
                <div className='inner-container5' id='offers'>
                  <div className='content5'>
                    <div>
                      <h2>Offers</h2>
                    </div>
                    <div className='offer-details'>
                      <div className='coupons'>
                        <div className='coupon-image'>
                          <img
                            src='/assets/images/GarageDetails/voucher.png '
                            alt=''
                          />
                        </div>
                        <div className='coupon-text'>
                          <span className='number'>
                            {" "}
                            {couponsCount ? couponsCount : "0"}{" "}
                          </span>
                          Coupons
                        </div>
                      </div>
                      <div className='deals'>
                        <div className='deals-image'>
                          <img
                            src='/assets/images/GarageDetails/deal.png '
                            alt=''
                          />
                        </div>
                        <div className='deals-text'>
                          <span className='number'> 3 </span>Deals
                        </div>
                      </div>
                      <div className='expire'>
                        <div className='expire-image'>
                          <img
                            src='/assets/images/GarageDetails/chronometer.png'
                            alt=''
                          />
                        </div>
                        <div className='expire-text'>
                          <span className='number'> 10 </span>Will Expire Soon
                        </div>
                      </div>
                    </div>
                    {offersData && offersData.length > 0 ? (
                      offersData.map(
                        (
                          {
                            couponName,
                            discount,
                            couponCode,
                            status,
                            describeCoupon,
                          },
                          index
                        ) => (
                          <div className='offers-content'>
                            <div className='discount-percent'>
                              {discount}
                              <br />
                              off
                            </div>
                            <div className='offers-data'>
                              <div className='data1'>
                                {/* 20% Off Coupon | Garage Rewards Program */}
                                {couponName}
                              </div>
                              <div className='data2'>
                                {/* Garage is offering 20% off your next visit when you join their
                  Rewards Program! You will get this coupon within 24-48 hours
                  after signing up...<span className='more'>More</span> */}
                                {describeCoupon}
                              </div>
                            </div>
                            <div className='offer-coupon'>
                              <div className='verify'>
                                <div className='verify-image'>
                                  <img
                                    src='/assets/images/GarageDetails/check1.png'
                                    alt='image'
                                  />
                                </div>
                                <div className='verify-text'>verified</div>
                              </div>
                              <div className='coupon-code'>
                                <div className='coupon-code-text'>
                                  {couponCode}
                                </div>

                                <div
                                  className='coupon-copy-image'
                                  onClick={() => handleCopyClick(couponCode)}>
                                  <img
                                    src='/assets/images/GarageDetails/copy-outline.png'
                                    alt='image'
                                  />
                                </div>
                              </div>
                              <div className='days'>
                                <div className='days-image'>
                                  <img
                                    src='/assets/images/GarageDetails/chronometer1.png'
                                    alt=''
                                  />
                                </div>
                                <div className='days-left'>{status}</div>
                              </div>
                            </div>
                          </div>
                        )
                      )
                    ) : (
                      <div className='offers-text-container'>
                        <div className='offers-text'>
                          Currently no offers are available.
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : null}
              <div className='ad-image'>
                <img
                  className='image-banner-list'
                  src='/assets/images/GarageDetails/ad-image.png'
                  alt=' '
                  style={{ maxWidth: "-webkit-fill-available" }}
                />
              </div>
              <div className='inner-container6' id='gallery'>
                <div className='content6'>
                  <div className='content6-title'>
                    <h2>Gallery</h2>
                  </div>
                  <div className='gallery-images'>
                    {galleryData.map(({ _id, image }) => (
                      <div className='gallery-image' key={_id}>
                        <img
                          src={
                            image
                              ? image
                              : "/assets/images/GarageDetails/gallery-image.png"
                          }
                          alt='image'
                        />
                      </div>
                    ))}
                  </div>
                  <div className='content6-view'>
                    <div className='view-all'>View all</div>
                  </div>
                </div>
              </div>
              {/* <div className="inner-container7" id="reviews">
                <div className="content7">
                  <div className="content7-title">
                    <h2>Reviews</h2>
                  </div>
                  <div className="review-text-container">
                    <div className="review-text">
                      Currently no reviews are available
                    </div>
                  </div>
                  <div className="reviews-header">
                    <div className="reviews-header1">
                      <div className="data1">Feedback from Our Customers</div>
                      <div className="data2">
                        Check what our customers has said about Us
                      </div>
                    </div>
                    <div className="reviews-header2">
                      <div className="review-points">4.0</div>
                      <div className="review-stars">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <path
                              d="M16.7289 23.5452L24.9907 28.5317L22.7983 19.1336L30.0975 12.8103L20.4855 11.9948L16.7289 3.13141L12.9724 11.9948L3.36035 12.8103L10.6596 19.1336L8.46715 28.5317L16.7289 23.5452Z"
                              fill="#F8A401"
                            />
                          </svg>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <path
                              d="M16.7289 23.5452L24.9907 28.5317L22.7983 19.1336L30.0975 12.8103L20.4855 11.9948L16.7289 3.13141L12.9724 11.9948L3.36035 12.8103L10.6596 19.1336L8.46715 28.5317L16.7289 23.5452Z"
                              fill="#F8A401"
                            />
                          </svg>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <path
                              d="M16.7289 23.5452L24.9907 28.5317L22.7983 19.1336L30.0975 12.8103L20.4855 11.9948L16.7289 3.13141L12.9724 11.9948L3.36035 12.8103L10.6596 19.1336L8.46715 28.5317L16.7289 23.5452Z"
                              fill="#F8A401"
                            />
                          </svg>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <path
                              d="M16.7289 23.5452L24.9907 28.5317L22.7983 19.1336L30.0975 12.8103L20.4855 11.9948L16.7289 3.13141L12.9724 11.9948L3.36035 12.8103L10.6596 19.1336L8.46715 28.5317L16.7289 23.5452Z"
                              fill="#F8A401"
                            />
                          </svg>
                        </div>
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="33"
                            height="33"
                            viewBox="0 0 33 33"
                            fill="none"
                          >
                            <path
                              d="M29.826 12.8103L20.214 11.9814L16.4574 3.13141L12.7009 11.9948L3.08887 12.8103L10.3881 19.1336L8.19566 28.5317L16.4574 23.5452L24.7192 28.5317L22.5401 19.1336L29.826 12.8103ZM16.4574 21.0453L11.4309 24.08L12.7677 18.3582L8.32935 14.5081L14.1848 14.0001L16.4574 8.61253L18.7435 14.0134L24.5989 14.5214L20.1605 18.3716L21.4974 24.0933L16.4574 21.0453Z"
                              fill="#F8A401"
                            />
                          </svg>
                        </div>
                      </div>
                      <div className="reviews-count">95 Reviews</div>
                    </div>
                  </div>
                  <div className="review-container">
                    <div className="review-data1">
                      <div className="review-left">
                        <div className="review-profileimage">
                          <img
                            src="/assets/images/GarageDetails/review-profile.png"
                            alt=""
                          />
                        </div>
                        <div className="review-name">Name</div>
                      </div>
                      <div className="review-right">
                        <div className="review-stars">
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star2.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star3.png"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="review-points">3.5</div>
                      </div>
                    </div>
                    <div className="review-desc">
                      I visited this garage for my bike regular service.
                    </div>
                  </div>
                  <div className="review-container">
                    <div className="review-data1">
                      <div className="review-left">
                        <div className="review-profileimage">
                          <img
                            src="/assets/images/GarageDetails/review-profile2.png"
                            alt=""
                          />
                        </div>
                        <div className="review-name">Name</div>
                      </div>
                      <div className="review-right">
                        <div className="review-stars">
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star2.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star3.png"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="review-points">3.5</div>
                      </div>
                    </div>
                    <div className="review-desc">
                      “I visited this garage for my bike regular service.
                      Services are good and at reasonable rate.
                    </div>
                  </div>
                  <div className="review-container">
                    <div className="review-data1">
                      <div className="review-left">
                        <div className="review-profileimage">
                          <img
                            src="/assets/images/GarageDetails/review-profile3.png"
                            alt=""
                          />
                        </div>
                        <div className="review-name">Name</div>
                      </div>
                      <div className="review-right">
                        <div className="review-stars">
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star2.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star3.png"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="review-points">3.5</div>
                      </div>
                    </div>
                    <div className="review-desc">
                      “I visited this garage for my bike regular service.
                      Services are good and at reasonable rate. from then I am
                      going to this garage regularly”
                    </div>
                  </div>
                  <div className="review-container">
                    <div className="review-data1">
                      <div className="review-left">
                        <div className="review-profileimage">
                          <img
                            src="/assets/images/GarageDetails/review-profile.png"
                            alt=""
                          />
                        </div>
                        <div className="review-name">Name</div>
                      </div>
                      <div className="review-right">
                        <div className="review-stars">
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star2.png"
                              alt="image"
                            />
                          </div>
                          <div className="star-image">
                            {" "}
                            <img
                              src="/assets/images/GarageDetails/review-star3.png"
                              alt="image"
                            />
                          </div>
                        </div>
                        <div className="review-points">3.5</div>
                      </div>
                    </div>
                    <div className="review-desc">
                      I visited this garage for my bike regular service.
                    </div>
                  </div>
                  <div className="content7-view">
                    <div className="view-all">View all</div>
                  </div>
                </div>
              </div> */}

              {/* <div className="inner-container8" id="qna">
                <div className="content8">
                  <div className="question-content">
                    <div className="left-content">Question & Answers</div>
                    <div className="right-content">Ask Question</div>
                  </div>
                  <div className="question-text-container">
                    <div className="question-text">
                      Currently no question & answers are available
                    </div>
                  </div>
                  <div>
                    <div className="accordion">
                      {accordionData.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <div
                            className={`accordion-item-header ${
                              activeIndex === index ? "active" : ""
                            }`}
                            onClick={() => toggleAccordion(index)}
                          >
                            {item.header}
                          </div>
                          <div
                            className="accordion-item-body"
                            style={{
                              maxHeight: activeIndex === index ? "1000px" : "0",
                            }}
                          >
                            <div className="accordion-item-body-content">
                              {item.body}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
            {/* <div style={{ width: "100%", margin: "0 auto" }}>
              <Topgarages />
            </div> */}
            {/* <div style={{ width: "100%", margin: "0 auto" }}>
        <Topgarages />
      </div> */}
            <div>
              <Survices />
            </div>
            <div className='dashboard_services'>
              <h2 className='operate-cities-title'>
                Car Service Centers Near {capitalizeFirstLetter(searchcity)}
              </h2>
              <OperateCities />
            </div>
            {/* <div style={{ width: "90%", margin: "3rem auto" }}>
              <h3 className="text-center garage-section-title">
                Frequently Asked Questions
              </h3>
              <Row>
                <Collapsedview FAQContent={faq} />
              </Row>
            </div> */}
            <Modal
              visible={modal}
              onCancel={close}
              centered
              footer={null}
              width={520}
              className=''>
              {token != undefined ? (
                <GetForm
                  vehicleData={vehicleData}
                  garageId={garageId}
                  onClose={close}
                />
              ) : (
                <SignUpForm
                  onClose={onCloseSignupModal}
                  onOpenLoginModal={openLoginModal}
                />
              )}
            </Modal>
            <Modal
              visible={modal1}
              onCancel={close}
              centered
              footer={null}
              width={520}
              className=''>
              {token != undefined ? (
                <BookForm vehicleData={vehicleData} garageId={garageId} />
              ) : (
                <SignUpForm
                  onClose={onCloseSignupModal}
                  onOpenLoginModal={openLoginModal}
                />
              )}
            </Modal>
            <Modal
              visible={isSignupModalOpen}
              onCancel={onCloseSignupModal}
              centered
              footer={null}
              className='custom-modal'>
              <SignUpForm
                onClose={onCloseSignupModal}
                onOpenLoginModal={openLoginModal}
              />
            </Modal>
            <Modal
              visible={isLoginModalOpen}
              onCancel={closeLoginModal}
              centered
              footer={null}
              className='custom-modal'>
              <LoginForm
                onClose={closeLoginModal}
                onOpenSignupModal={openSignupModal}
              />
            </Modal>
            <div>
              <Modal
                visible={shareModal}
                onCancel={() => setShareModal(false)}
                centered
                footer={null}>
                <Demo />
              </Modal>
            </div>
            <div>
              <Modal
                visible={showModal}
                onCancel={() => setShowModal(false)}
                centered
                footer={null}>
                {showModal && (
                  <div className='services-modal'>
                    <div className='services-modal-header'>
                      <div className='modal-header-left'>Services</div>
                      <div className='modal-header-right'>
                        Total :{" "}
                        <span className='service-count'>
                          {modalServices.length}
                        </span>
                      </div>
                    </div>
                    <div className='services-column'>
                      {serviceNames.map((item, index) => (
                        <div key={index}>
                          <div className=''>{item}</div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </Modal>
            </div>
          </div>
          <Footer />
        </>
      )}
    </>
  );
};

export default GarageDetails;
