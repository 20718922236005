import React, { useState, useEffect } from "react";
import "./NearMeGarage.css";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Survices from "../Services/Survices";
import Topgarages from "../Top_Garages/Topgarages";
import OperateCities from "./OperateCities";
import WhyChooseUs from "./WhyChooseUs";
import { Row } from "antd";
import Collapsedview from "../Collapse/Collapse";
import NearMeSection from "./NearMeSection";
import NearMeGarageBanner from "./NearMeGarageBanner";
import {
  carPopularSearches,
  bikePopularSearches,
} from "../Homepage/carBikePopularSearches";
import Categories from "../Categories/Categories";
import axios from "axios";
import PriceListing from "./PriceListing";
import { Helmet, HelmetProvider } from 'react-helmet-async';


interface Content {
  title: string,
  description: string
}

interface VehicleDetails {
  vechileType: string,
  price: string,
}

interface Vehicle {
  package: string;
  vehicles: VehicleDetails[];
}

interface SeoData {
  title: string,
  paragraphData: string,
  FAQContent: Content[] | undefined,
  WhyVecrepContent: Content[] | undefined,
  vehicleData: Vehicle[] | undefined
}

const NearMeGarage: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;
  const [resp, setResp] = useState<SeoData | null>(null)

  // alert(pathname);

  // Function to check if a search term is part of the current path
  const isInCurrentPath = (searchTerm: string) => {
    return pathname.includes(searchTerm.replace(/\s+/g, "-").toLowerCase());
  };

  const fetchNearMeSeoData = () => {
    const serviceArr = pathname.replace('/', '').split('-');
    console.log('near-me page', serviceArr);
    let vehicleType = 3;
    if (serviceArr.includes("car")) {
      vehicleType = 1; // Set vehicleType to 1 for car
    } else if (serviceArr.includes("bike")) {
      vehicleType = 2; // Set vehicleType to 2 for bike
    }      
    console.log(vehicleType);
    // Filter out "near" and "me" from the serviceArr and join the remaining parts with "-"
    const serviceName = serviceArr
    .filter(part => part !== "near" && part !== "me")
    .join("-");
    
    const payload = {
      serviceName: serviceName,
      vehicleType: vehicleType 
    }
    var metaTitle = 'Vecrep.com :'+serviceName+' Near me| Trusted Solutions';

    try {
      axios.post(
        "https://api.vecrep.com/seoNearmeData", payload
      ).then((response) => {
        if(response) {
          console.log(response);
          if(response.data) {
            // let test;
            console.log("resp near me", response.data.data);
            setResp(response.data.data);
          }
        }  
      }).catch((error) => {
        console.error("then catch Error fetching data:", error);
      })
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  }
  
  useEffect(() => {
    if(!isInCurrentPath('near-me')) {
      navigate('/');
    }
    fetchNearMeSeoData();
    
  }, []);

  const serviceArr = pathname.replace('/', '').split('-');
    console.log('near-me page', serviceArr);
    let vehicleType = 3;
    if (serviceArr.includes("car")) {
      vehicleType = 1; // Set vehicleType to 1 for car
    } else if (serviceArr.includes("bike")) {
      vehicleType = 2; // Set vehicleType to 2 for bike
    }      
    console.log(vehicleType);
    // Filter out "near" and "me" from the serviceArr and join the remaining parts with "-"
    const serviceName = serviceArr
    .filter(part => part !== "near" && part !== "me")
    .join("-");
    
    const payload = {
      serviceName: serviceName,
      vehicleType: vehicleType 
    }
    
    var metaTitle = 'Vecrep.com : '+serviceName+' Near me | Trusted Solutions';
    var metaDescription = `Vecrep.com Your go-to destination for top ${serviceName} in 2024. Trust us for reliable solutions and quality service every time.`;
  return (
    <>
    <HelmetProvider>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="robots" content="INDEX, FOLLOW"/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>    
      <title>{metaTitle }</title>
      <meta name="description" content={metaDescription} />
      <link rel="canonical" href={window.location.href} />
      </Helmet>
      </HelmetProvider>
      <div>
        <NearMeGarageBanner />
      </div>
      <div>{(isInCurrentPath("car") || isInCurrentPath("cng")) ? <Categories /> : <Survices />}</div>
      {/* <div>
        <Topgarages />
      </div> */}
      <div className="dashboard_services">
        <h2 className="operate-cities-title">Cities we Serve</h2>
        <OperateCities />
      </div>
      <div className="dashboard_services">
        <NearMeSection paragraphData={resp?.paragraphData} />
      </div>
      <div className="dashboard_services">
        <PriceListing />
      </div>
      <div className="dashboard_services">
        <h3 className="section-title">Why Choose VecRep.com?</h3>
        <Row>
          <WhyChooseUs />
        </Row>
      </div>
      {/* <div className="dashboard_services">
        <h2 className="text-center">Frequently Asked Questions</h2>
        <Row>
          <Collapsedview FAQContent={resp?.FAQContent} />
        </Row>
      </div> */}

      <div className="dashboard_services">
        <div>
          <hr />
          <div className="desktop-pSearchTitle"> POPULAR SEARCHES </div>
          {(isInCurrentPath("car") || isInCurrentPath("cng")) ? (
            <>
              <div className="desktop-pSearchlinks">
                {carPopularSearches.map(
                  (search, index) =>
                    !isInCurrentPath(search) && (
                      <a
                        key={index}
                        href={`/${search.replace(/\s+/g, "-").toLowerCase()}`}
                      >
                        {search}
                      </a>
                    )
                )}
              </div>
            </>
          ) : (
            <div className="desktop-pSearchlinks">
              {bikePopularSearches.map(
                (search, index) =>
                  !isInCurrentPath(search) && (
                    <a
                      key={index}
                      href={`/${search.replace(/\s+/g, "-").toLowerCase()}`}
                    >
                      {search}
                    </a>
                  )
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default NearMeGarage;
