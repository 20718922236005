import React from "react";
import Navbar from "../Navbar/Navbar";
import Footer from "../Footer/Footer";
import SimpleSlider from "../Categories/Categories";
import Categories from "../Categories/Categories";
import Topgarages from "../Top_Garages/Topgarages";
import Banner from "../Dashboard/Banner/Banner";
import Offers from "../Offers/Offer";
import Survices from "../Services/Survices";
import Collapsedview from "../Collapse/Collapse";
import Havequestions from "../Havequestions/Havequestions";
import { Row } from "antd";
import WhyChooseUs from "../searchServices/WhyChooseUs";
import HelpGrowSection from "../searchServices/HelpGrowSection";
import OperateCities from "../searchServices/OperateCities";
import Testimonial from "./Testimonial";
import PopularSearch from "./PopularSearch";
import ReactDOM from "react-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";

const HomePage: React.FC = () => {
  return (
    <div>
      <HelmetProvider>
        <Helmet>
          <meta charSet="utf-8" />
          <meta name="robots" content="INDEX, FOLLOW" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <title>
            Top Car & Bike Services Online | Repair, Garage & More | Vecrep
          </title>
          <meta
            name="description"
            content="Discover Vecrep: Your one-stop destination for premier car and bike services online. Easily book repairs, maintenance, and more, all from the convenience of your home"
          />
          <link rel="canonical" href="https://vecrep.com" />
          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              url: "https://vecrep.com/",
              potentialAction: {
                "@type": "SearchAction",
                target: "https://vecrep.com/?s={vecrep}",
                "query-input": "required name=vecrep",
              },
            })}
          </script>

          <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "Organization",
              url: "https://vecrep.com/",
              name: "VecRep",
              logo: "Logo URL",
              address: {
                "@type": "PostalAddress",
                addressLocality: "Chandigarh, Punjab",
                postalCode: "160002",
                streetAddress: "Plot No 57, Industrial Area Phase I",
              },
              email: "vr.vecrep@gmail.com",
              telephone: "+91-9056262909",
              sameAs: [
                "https://www.facebook.com/vrvecrep",
                "https://twitter.com/VR_vecrep",
                "https://www.instagram.com/vr_vecrep/",
                "https://www.linkedin.com/company/vr-vecrep/",
                "https://www.youtube.com/channel/UCmNtrCMVJqU7Ws2fKf1H7eg",
              ],
              contactPoint: [
                {
                  "@type": "ContactPoint",
                  telephone: "+91-9056262909",
                  contactType: "Customer Service",
                },
              ],
              foundingDate: "2020",
              founders: [
                {
                  "@type": "Person",
                  name: "Rajat Soni",
                  id: "https://www.linkedin.com/in/rajat-soni7/",
                },
                {
                  "@type": "Person",
                  name: "Kajal chaudhary",
                  id: "https://www.linkedin.com/in/kajal-chaudhary-09ac/",
                },
              ],
              funder: [
                {
                  "@type": "Corporation",
                  legalName: "put name",
                  url: "Put url",
                  sameAs: "wikipedia link",
                },
              ],
            })}
          </script>

         
        </Helmet>
      </HelmetProvider>
      <Navbar />
      <Banner />
      <Categories />
      <Offers />
      <Survices />
      {/* <Topgarages /> */}
      <Havequestions />

      <div className="dashboard_services">
        <h2 className="operate-cities-title">Cities Where You Can Find Us</h2>
        <OperateCities />
      </div>
      <div className="dashboard_services">
        <h3 className="section-title text-center">Why Choose VecRep.com?</h3>
        <Row>
          <WhyChooseUs />
        </Row>
      </div>

      <div>
        <Testimonial />
      </div>

      <div className="dashboard_services">
        <h2 className="text-center">Book Nearby Garages with Confidence</h2>
        <Row style={{ alignItems: "center" }}>
          <HelpGrowSection />
        </Row>
      </div>

      <div className="dashboard_services">
        {/* <h2>The all-in-one place to book a nearby verified garage</h2> */}
        <Row>
          <PopularSearch />
        </Row>
      </div>

      {/* <div className="dashboard_services">
        <h2>Frequently Asked Questions</h2>
        <Row>
          <Collapsedview />
        </Row>
      </div> */}
      <Footer />
    </div>
  );
};

export default HomePage;
