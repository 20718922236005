import React, { useState } from "react";
import "./testimonial.css";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useNavigate } from "react-router-dom";

interface Garage {
  _id: string;
  distance: number;
  garageName: string;
  phoneNumber: string;
  email: string;
  address: string;
  image: string;
  garageType: number;
  jobsDone: number;
  services: {
    name: string;
    type: string;
    id: string;
  }[];
}

interface ApiResponse {
  nearbyGarages: Garage[];
}

interface SliderSettings {
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  nextArrow: React.ReactElement;
  prevArrow: React.ReactElement;
  dots: boolean;
  arrows: boolean;
  autoplay: boolean;
  autoplaySpeed: number;
  responsive?: {
    breakpoint: number;
    settings: {
      slidesToShow: number;
      slidesToScroll: number;
      infinite: boolean;
      arrows: boolean;
      dots?: boolean;
    };
  }[];
}

const Testimonial: React.FC = () => {
  const [garagesData, setGaragesData] = React.useState<Garage[]>([]);
  const initialDisplayCount = 1;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [expandedCards, setExpandedCards] = useState<number[]>([]);
  const navigate = useNavigate();

  const testimonialData = [
    {
      id: 1,
      text: (
        <>
        Hot air was blowing out from my car's AC, it was clear that my car AC needs to be serviced. Your Facebook ad for <strong>car AC service online</strong> took me to the website to book an AC service garage near me, and they did a great job at fixing the issues. The car's AC works fine now; I appreciate the service.
        </>
      ),
      name: 'IQBAL Singh'
    },
    {
      id: 2,
      text: (
        <>
        I have a very packed-up schedule, which makes it impossible for me to take my car for a periodic service. Thankfully, my friend mentioned VecRep.com, after which I booked a garage from VecRep.com and chose pick & drop service. The team is a pro, right from dealing with vehicle owners to satisfying car periodic service with their professional edge. Highly Recommended.
        </>
      ),
      name: 'Anmol Sharma'
    },
    {
      id: 3,
      text: (
        <>
        When we bought our car, we sincerely planned to wash it every week and did for some weeks on our own but couldn’t continue. So, I opted to <strong>book a car wash service</strong> at one of the garages in Chandigarh and handed it over to them for a few hours. My car's shine got back, maintaining my car is a lot easier now.
        </>
      ),
      name: 'Vipin Kumar'
    },
    {
      id: 4,
      text: (
        <>
        I do a part time job as a delivery boy with my studies, so I got my bike from my hometown. However, issues started developing because I used it a lot, but I was new to this city so I booked a bike repair service at a nearby garage from VecRep.com on my senior's suggestion. The entire process from the time of booking till delivery of my bike was hassle free and trustworthy.
        </>
      ),
      name: 'Anubhav Singla'
    },
    {
      id: 5,
      text: (
        <>
        After buying our car, we were really concerned about where to take it for servicing after the free servicing was over. One of my colleagues suggested VecRep.com. So, to try it, I booked the top rated car garage for periodic service of my car, and I had a really amazing experience. Not only did I experience the professionalism of the garage I chose but also got huge discounts on my service bill.
        </>
      ),
      name: 'Shalu chaudhary'
    }
  ]

  const handleMoreClick = (index: number) => {
    // Toggle the expanded state for the clicked card
    setExpandedCards((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((item) => item !== index)
        : [...prevExpanded, index]
    );
  };

  const handleClick = (GarageId: string) => {
    console.log("garageId:", GarageId);
    navigate("/garagedetails", {
      state: { apiData: GarageId, vehicle: { vehicleType: 2 } },
    });
  };

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.vecrep.com/dashbordGarages", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userLongitude: "0",
            userLatitude: "0",
          }),
        });
        const data: ApiResponse = await response.json();
        console.log(data);
        if (Array.isArray(data.nearbyGarages)) {
          setGaragesData(data.nearbyGarages);
        } else {
          console.log("Invalid response format");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const SamplePrevArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}
      >
        <LeftOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const SampleNextArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}
      >
        <RightOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const settings: SliderSettings = {
    infinite: true,
    speed: 1500,
    slidesToShow: 2,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1500,
    dots: false,
    arrows: false,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className="dashboard_garages">
      <h2 className="section-title text-center">Testimonials</h2>
      <div className="garages_container">
        <Slider {...settings}>
          {testimonialData.map((testimonial, index) => (
            <div className="owl-item" key={index}>
              <div className="item">
                <div className="testimonial-6">
                  <div className="testimonial-text bg-white quote-left quote-right">
                    <p style={{ textAlign: 'justify' }}>
                    {testimonial.text}
                    </p>
                  </div>
                  <div className="testimonial-detail clearfix bg-primary text-white">
                    <h4 className="testimonial-name m-tb0">{testimonial.name}</h4>
                    <div className="testimonial-pic radius">
                      <img
                        src="https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-alt-512.png"
                        alt="Testimonial Image"
                        width="100"
                        height="100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Testimonial;
