// store.js
import { createStore } from "redux";

// Define your initial state
const initialState = {
  latitude1: null,
  longitude1: null,
  city1: "", // Add city property to the initial state
};

// Create a reducer function to handle state changes
const reducer = (state = initialState, action: any) => {
  switch (action.type) {
    case "SET_COORDINATES":
      return {
        ...state,
        latitude1: action.latitude1,
        longitude1: action.longitude1,
      }; 
    case "SET_CITY":
      return {
        ...state,
        city1: action.city1,
      };
    default:
      return state;
  }
};

// Create the Redux store
const store = createStore(reducer);

export default store;

export const SET_COORDINATES = "SET_COORDINATES";
export const SET_CITY = "SET_CITY"; // Define a new action type for setting the city

// Action creators
export const setCoordinates = (
  latitude1: number | null,
  longitude1: number | null
) => ({
  type: SET_COORDINATES,
  latitude1,
  longitude1,
});

// Action creator for setting the city
export const setCity = (city1: string) => ({
  type: SET_CITY,
  city1,
});
