import React, { useState, useEffect } from "react";
import "../Homepage/popularSearch.css";
import parser from 'html-react-parser'

interface NearMeSectionProps {
  paragraphData: string | undefined;
}

const NearMeSection: React.FC<NearMeSectionProps> = ({paragraphData}) => {
  return (
    <>
      <div className="desktop-gyanContainer" data-reactid="354">
        {
          paragraphData && parser(paragraphData)
        }
      </div>
    </>
  );
};

export default NearMeSection;
