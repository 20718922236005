import { SettingOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { Col, CollapseProps } from "antd";
import { Collapse, Select } from "antd";
import "./collapse.css";

const { Option } = Select;

const generatedLinks = (
  <ul>
    <li>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words,{" "}
      </a>
      <a href='#' style={{ color: "black" }}>
        Key words{" "}
      </a>
    </li>

    {/* Add more list items as needed */}
  </ul>
);

type ExpandIconPosition = "start" | "end";

interface Content {
  title: string;
  description: string;
}

interface CollapsedviewProps {
  FAQContent: Content[] | undefined;
}

const Collapsedview: React.FC<CollapsedviewProps> = ({FAQContent}) => {
  console.log('faq content 96', FAQContent);
  const [expandIconPosition, setExpandIconPosition] =
    useState<ExpandIconPosition>("end");

  const onPositionChange = (newExpandIconPosition: ExpandIconPosition) => {
    setExpandIconPosition(newExpandIconPosition);
  };

  const onChange = (key: string | string[]) => {
    console.log(key);
  };

  const items =
    FAQContent?.map((item: Content, index: number) => ({
      key: index.toString(),
      label: item.title,
      children: <p>{item.description}</p>,
    })) || [];

  return (
    <> 
    <Col span={24}>
      <Collapse
        // defaultActiveKey={["1"]}
        onChange={onChange}
        expandIconPosition={expandIconPosition}
        accordion={true}
        style={{ backgroundColor: "#fff", width: "auto" }}
        items={items}
      />
      </Col>
    </>
  );
};

export default Collapsedview;
