import React, { useState } from "react";
import "./Banner.css";
import SearchBar from "./SearchBar";
import Offers from "../../Offers/Offer";
import BannerSlider from "../../BannerSlider/BannerSlider";

const Banner: React.FC = () => {
  return (
    <div className="banner">
      <div className="banner_container">
        <h1 className="hide-on-mobile">Discover the Best Car & Bike Services in India</h1>
        <div className="banner_image">
          <BannerSlider />
        </div>
        <div className="banner_content">
          <SearchBar />
        </div>
      </div>
    </div>
  );
};

export default Banner;
