import React, { useEffect, useState } from "react";
import { Form, Radio, Upload, Button, message } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import axios from "axios"; // Ensure axios is imported

interface EditVechileProps {
  fetchVehicles: () => void;
  closePopup: () => void;
}

const EditVechile: React.FC<EditVechileProps> = ({
  fetchVehicles,
  closePopup,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState("2");
  const [brands, setBrands] = useState<{ name: string }[]>([
    {
      name: "Select vehicle",
    },
  ]);
  const [selectedBrand, setSelectedBrand] = useState("");
  const [modelData, setModelData] = useState<{ name: string }[]>([
    {
      name: "Select vehicle",
    },
  ]);
  const [selectedModel, setSelectedModel] = useState("");
  const [selectedFuel, setSelectedFuel] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [form] = Form.useForm();

  const startYear = 1999;
  const endYear = 2022;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );

  const FuelData = [
    {
      name: "Select Fuel",
      id: "64b8cb117ee76a18e2b409c6",
      type: "2",
    },
    {
      name: "Select Fuel",
      id: "64b8cb117ee76a18e2b409c7",
      type: "1",
    },
    {
      name: "Petrol",
      id: "64b8cb117ee76a18e2b409c5",
      type: "2",
    },
    {
      name: "EV",
      id: "64b8cb117ee76a18e2b409b8",
      type: "2",
    },
    {
      name: "Petrol",
      id: "64b8cb117ee76a18e2b409c5",
      type: "1",
    },
    {
      name: "Diesel",
      id: "64b8cb117ee76a18e2b409c7",
      type: "1",
    },
    {
      name: "CNG",
      id: "64b8cb117ee76a18e2b409b8",
      type: "1",
    },
    {
      name: "EV",
      id: "64b8cb117ee76a18e2b409c9",
      type: 1,
    },
  ];

  useEffect(() => {
    fetchBrands(selectedVehicle);
  }, []);

  const changeVechileType = async (e: any) => {
    const selectedValue = e.target.value;
    setSelectedVehicle(selectedValue);
    setBrands([
      {
        name: "Select Brand",
      },
    ]);
    setModelData([
      {
        name: "Select Model",
      },
    ]);

    form.resetFields();
    setSelectedBrand("");
    setSelectedYear("Select Year");
    setSelectedFuel("Select Fuel");
    setVehicleNumber("");

    // Fetch new brands based on the selected vehicle type
    await fetchBrands(selectedValue);
  };

  const onBrandChange = async (e: any) => {
    const selectedValue = e.target.value;
    setSelectedBrand(selectedValue);
    await fetchModels(selectedValue);
  };

  const handleYear = (e: any) => {
    setSelectedYear(e.target.value);
  };

  const fetchBrands = async (vehicletype: any) => {
    const vehicleType = parseInt(vehicletype, 10);

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_service_list",
        {
          vehicleType,
        }
      );
      if (response.status === 200) {
        setBrands(response.data.data);
      } else if (response.status === 400) {
        // Handle error if needed
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const fetchModels = async (requestData: any) => {
    const makeName = requestData;
    const vehicleType = selectedVehicle;

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_model_list",
        {
          makeName,
          vehicleType,
        }
      );
      console.log(response);
      if (response.status === 200) {
        setModelData(response.data?.data);
      } else if (response.status === 400) {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFuelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setSelectedFuel(selectedValue);
    // Additional logic if needed
  };

  const onFinish = async (values: any) => {
    console.log("Form submitted with values:", values);
    const vehicleType = parseInt(values.vehicletype, 10);
    console.log(vehicleType);
    console.log("Received values:", values);
    console.log(
      values.fileupload && values.fileupload.length > 0
        ? values.fileupload[0].originFileObj
        : ""
    );
    const vehicleImage =
      values.fileupload && values.fileupload.length > 0
        ? values.fileupload[0].thumbUrl
        : "";
    const insurenceImage =
      values.fileUpload && values.fileUpload.length > 0
        ? values.fileUpload[0].thumbUrl
        : "";
    const requestData = {
      vehicleType: vehicleType || 2,
      vehicleNumber: vehicleNumber,
      brand: values.Brandname,
      model: values.ModelName,
      fuelType: values.FuelType,
      year: values.year,
      vehicleImage: vehicleImage,
      chassisNumber: values.Chassisnumber,
      registrationNumber: values.RCnumber,
      companyName: values.Companyname,
      insuranceNumber: values.Insurancenumber,
      insuranceImage: insurenceImage,
    };
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.vecrep.com/addAndUpdateVehicle",
        {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        fetchVehicles();
        closePopup();
        form.resetFields();
        setSelectedBrand("");
        setSelectedModel("");
        setSelectedYear("Select Year");
        setSelectedFuel("Select Fuel");
        setVehicleNumber("");
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleVehicleChange = (event: any) => {
    const inputValue = event.target.value;

    // Check if the input value matches the desired pattern
    const isValidInput = /^[A-Z0-9a-z]*$/.test(inputValue);

    // If valid, update the state
    if (isValidInput) {
      setVehicleNumber(inputValue.toUpperCase());
    }
  };

  return (
    <div className="vehiclepopup-container">
      <Form name="normal_login" form={form} onFinish={onFinish}>
        <div className="vehiclepopup-title">New Vehicle</div>
        <div className="vehiclepopup-radio">
          <Form.Item name="vehicletype">
            <div className="vehicle-change">
              <Radio.Group onChange={changeVechileType} value={selectedVehicle}>
                <Radio value="2">2 Wheeler</Radio>
                <Radio value="1">4 Wheeler</Radio>
              </Radio.Group>
            </div>
          </Form.Item>
        </div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input">
            <Form.Item
              name="vehiclenumber"
              rules={[
                {
                  required: false,
                  message: "Please enter vehicle number",
                },
              ]}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  aria-disabled
                  onChange={handleVehicleChange}
                  value={vehicleNumber}
                  placeholder="Enter Vehicle Number"
                />
                <span> Vehicle Number </span>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input">
            <Form.Item
              name="Brandname"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle brand",
                },
              ]}
            >
              <div className="myvehicle-select">
                <select
                  required
                  className="dropdown-year"
                  value={selectedBrand} // Ensure you bind the selected value to the state
                  onChange={onBrandChange} // Attach the onChange event to the select element
                >
                  <option value="" disabled>
                    {selectedBrand || "Select Brand"}
                  </option>
                  {brands.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
                <span>Select Brand</span>
              </div>
            </Form.Item>
          </div>
        </div>

        <div className="vehiclepopup-contents1">
          <div className="vehiclepopup-input">
            <Form.Item
              name="ModelName"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle model",
                },
              ]}
            >
              <div className="myvehicle-select">
                <select required className="dropdown-year">
                  <option value="" disabled>
                    {selectedModel || "Select Model"}{" "}
                  </option>
                  {modelData.map(({ name }) => (
                    <option key={name} value={name}>
                      {name}
                    </option>
                  ))}
                </select>
                <span>Select Model</span>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input1">
            <Form.Item
              name="FuelType"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle fuel",
                },
              ]}
            >
              <div className="myvehicle-select fuel-input">
                <select
                  required
                  className="dropdown-year"
                  value={selectedFuel}
                  onChange={handleFuelChange}
                >
                  <option value="" disabled>
                    {selectedFuel || "Select Fuel"}
                  </option>

                  {FuelData.filter((fuel) => fuel.type === selectedVehicle).map(
                    ({ name, id }) => (
                      <option key={id} value={name}>
                        {name}
                      </option>
                    )
                  )}
                </select>
                <span>Select Fuel</span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input1">
            <Form.Item
              name="year"
              rules={[
                {
                  required: true,
                  message: "Please select vehicle year",
                },
              ]}
            >
              <div className="myvehicle-select fuel-input">
                <select
                  value={selectedYear}
                  required
                  className="dropdown-year"
                  onChange={handleYear}
                  // placeholder="Select Year"
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </select>
                <span>Select Year</span>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input">
            <Form.Item
              name="RCnumber"
              rules={[
                {
                  required: false,
                  message: "Please enter vehicle RC number",
                },
              ]}
            >
              <div className="myvehicle-input">
                <input type="text" placeholder="Enter RC Number" />
                <span> RC Number </span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-insurence">
          <div className="">
            <Form.Item
              label="Upload Image"
              name="fileupload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="image-upload2"
            >
              <Upload beforeUpload={() => false} listType="picture">
                <Button
                  icon={<CloudUploadOutlined />}
                  className="upload-button"
                >
                  <small>Browse to upload</small>
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-title2">Vehicle Insurance Details</div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input">
            <Form.Item
              name="Companyname"
              rules={[
                {
                  required: false,
                  message: "Please enter company name ",
                },
              ]}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  placeholder="Enter Company Name"
                  // value={vehicleNumber}
                  // onChange={handleInputChange}
                />
                <span> Company Name </span>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input">
            <Form.Item
              name="Insurancenumber"
              rules={[
                {
                  required: false,
                  message: "Please enter policy number",
                },
              ]}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  placeholder="Enter Policy Number"
                  // value={vehicleNumber}
                  // onChange={handleInputChange}
                />
                <span> Policy Number </span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-insurence">
          <div>
            <Form.Item
              label="Upload Image"
              name="fileUpload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="image-upload2"
            >
              <Upload beforeUpload={() => false} listType="picture">
                <Button
                  icon={<CloudUploadOutlined />}
                  className="upload-button"
                >
                  <small>Browse to upload</small>
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <div className="vehiclepopup-submit">
            <div>
              <button className="vehiclepopup-submit-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditVechile;
