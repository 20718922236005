import React, { useEffect, useState } from "react";
import { Form, Radio, Upload, Button, message, Select } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import axios from "axios"; // Ensure axios is imported
// import "antd/dist/antd.css";
import { Menu, Dropdown } from "antd";

interface EditVechileProps {
  fetchVehicles: () => void;
  closePopup: () => void;
  vehicleNum: string;
  vehicleBrand: string;
  vehicleModel: string;
  vehicleFuel: string;
  vehicleYear: string;
  vehicleType: number;
  insuranceImage: string;
  vehicleIamge: string;
  insuranceNumber: string;
  companyName: string;
  RCNumber: string;
}

const VechileEdit: React.FC<EditVechileProps> = ({
  fetchVehicles,
  closePopup,
  vehicleBrand,
  vehicleFuel,
  vehicleModel,
  vehicleNum,
  vehicleYear,
  vehicleType,
  insuranceImage,
  vehicleIamge,
  insuranceNumber,
  companyName,
  RCNumber,
}) => {
  const [selectedVehicle, setSelectedVehicle] = useState(2);
  const [brand, setBrand] = useState("");
  const [model, setModel] = useState("");
  const [fuel, setFuel] = useState("");
  const [year, setYear] = useState("");
  const [brands, setBrands] = useState([]);
  const [modelData, setModelData] = useState([]);
  const [form] = Form.useForm();
  const [company, setCompany] = useState("");
  const [insuranceNum, setInsuranceNum] = useState("");
  const [RCNum, setRCNum] = useState("");

  useEffect(() => {
    setBrand(vehicleBrand);
    setModel(vehicleModel);
    setFuel(vehicleFuel);
    setYear(vehicleYear);
    setCompany(companyName);
    setRCNum(RCNumber);
  }, [vehicleBrand]);

  const startYear = 1999;
  const endYear = 2022;
  const years = Array.from(
    { length: endYear - startYear + 1 },
    (_, index) => startYear + index
  );
  console.log(
    "Vehicle Values: ",
    vehicleBrand,
    vehicleFuel,
    vehicleModel,
    vehicleNum,
    vehicleYear,
    typeof vehicleType,
    companyName,
    insuranceNumber,
    RCNumber,
    vehicleType
  );

  const BikeData = [
    {
      name: "Petrol",
      id: "64b8cb117ee76a18e2b409c5",
      type: "2",
    },
    {
      name: "EV",
      id: "64b8cb117ee76a18e2b409b8",
      type: "2",
    },
  ];

  const CarData = [
    {
      name: "Petrol",
      id: "64b8cb117ee76a18e2b409c5",
      type: "1",
    },
    {
      name: "Diesel",
      id: "64b8cb117ee76a18e2b409c7",
      type: "1",
    },
    {
      name: "CNG",
      id: "64b8cb117ee76a18e2b409b8",
      type: "1",
    },
    {
      name: "EV",
      id: "64b8cb117ee76a18e2b409c9",
      type: 1,
    },
  ];

  useEffect(() => {
    fetchBrands(vehicleType);
  }, [vehicleType]);

  const onBrandChange = async (e: any) => {};

  const handleYear = (e: any) => {
    setYear(e);
  };

  const fetchBrands = async (vehicletype: any) => {
    const vehicleType = parseInt(vehicletype, 10);

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_service_list",
        {
          vehicleType,
        }
      );
      if (response.status === 200) {
        setBrands(response.data.data);
      } else if (response.status === 400) {
        // Handle error if needed
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error if needed
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    if (e && e.fileList) {
      return Array.isArray(e.fileList) ? e.fileList : [];
    }
    return [];
  };

  const fetchModels = async (requestData: any) => {
    const makeName = requestData;
    // const vehicleType = selectedVehicle;

    try {
      const response = await axios.post(
        "https://api.vecrep.com/get_model_list",
        {
          makeName,
          vehicleType,
        }
      );
      console.log(response);
      if (response.status === 200) {
        setModelData(response.data?.data);
      } else if (response.status === 400) {
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleFuelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    setFuel(selectedValue);
    // Additional logic if needed
  };

  const onFinish = async (values: any) => {
    console.log("Form submitted with values:", values);
    console.log("Received values:", values);
    console.log(
      values.fileupload && values.fileupload.length > 0
        ? values.fileupload[0]?.originFileObj
        : ""
    );
    const vehicleImage =
      values.fileupload && values.fileupload.length > 0
        ? values.fileupload[0]?.thumbUrl
        : "";
    const insurenceImage =
      values.fileUpload && values.fileUpload.length > 0
        ? values.fileUpload[0]?.thumbUrl
        : "";
    const requestData = {
      vehicleType: vehicleType,
      vehicleNumber: vehicleNum,
      brand: brand,
      model: model,
      fuelType: fuel,
      year: year,
      vehicleImage: vehicleImage || vehicleIamge,
      registrationNumber: values.RCnumber,
      companyName: values.Companyname,
      insuranceNumber: values.Insurancenumber,
      insuranceImage: insurenceImage || insuranceImage,
    };
    console.log(requestData);
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.vecrep.com/addAndUpdateVehicle",
        {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        fetchVehicles();
        closePopup();
        setModelData([]);
        message.success("Vehicle Edited Successfully");
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleMenuClick = async (selectedBrand: string) => {
    const selectedValue = selectedBrand;
    setBrand(selectedValue);
    setFuel("Select Fuel");
    setModel("Select Model");
    setYear("Select Year");
    fetchModels(selectedValue);
  };

  const handleModalClick = async (selectedBrand: string) => {
    const selectedValue = selectedBrand;
    setModel(selectedValue);
  };
  const handleFuelClick = async (selectedBrand: string) => {
    const selectedValue = selectedBrand;
    setFuel(selectedValue);
  };
  const handleYearClick = async (selectedBrand: string) => {
    setYear(selectedBrand);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCompany(e.target.value);
  };

  const handleRCNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRCNum(e.target.value);
  };

  const handleInsuranceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInsuranceNum(e.target.value);
  };

  const menu = (
    <Menu onClick={({ key }) => handleMenuClick(key)}>
      {brands.map(({ name }) => (
        <Menu.Item key={name}>{name}</Menu.Item>
      ))}
    </Menu>
  );
  const menu2 = (
    <Menu onClick={({ key }) => handleModalClick(key)}>
      {modelData.map(({ name }) => (
        <Menu.Item key={name}>{name}</Menu.Item>
      ))}
    </Menu>
  );
  const menu3 = (
    <Menu onClick={({ key }) => handleFuelClick(key)}>
      {vehicleType === 1
        ? CarData.map(({ name }) => <Menu.Item key={name}>{name}</Menu.Item>)
        : BikeData.map(({ name }) => <Menu.Item key={name}>{name}</Menu.Item>)}
    </Menu>
  );
  const menu4 = (
    <Menu onClick={({ key }) => handleYearClick(key)}>
      {years.map((year) => (
        <Menu.Item key={year}>{year}</Menu.Item>
      ))}
    </Menu>
  );

  return (
    <div className="vehiclepopup-container">
      <Form name="normal_login" form={form} onFinish={onFinish}>
        <div className="vehiclepopup-title">Edit Vehicle</div>
        <div className="vehiclepopup-radio">
          <Form.Item name="vehicletype">
            <div className="vehicle-change">
              <Radio.Group value={vehicleType}>
                {vehicleType === 2 ? (
                  <Radio value={vehicleType}>2 Wheeler</Radio>
                ) : (
                  <Radio value={vehicleType}>4 Wheeler</Radio>
                )}
              </Radio.Group>
            </div>
          </Form.Item>
        </div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input">
            <Form.Item
              name="vehiclenumber"
              rules={[
                {
                  required: false,
                  message: "Please enter vehicle number",
                },
              ]}
              initialValue={vehicleNum}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  aria-disabled
                  value={vehicleNum}
                  style={{ cursor: "no-drop" }}
                />
                <span> Vehicle Number </span>
              </div>
            </Form.Item>
          </div>
          <div className="">
            <Form.Item
              name="Brandname"
              rules={[
                {
                  required: false,
                  message: "Please select vehicle brand",
                },
              ]}
              initialValue={brand}
            >
              <div className="">
                <Dropdown
                  overlay={menu}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <div
                    className=""
                    style={{
                      border: "1px solid #b11226",
                      width: "250px",
                      padding: "6px 0px 6px 4px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    <span>{brand}</span>
                  </div>
                </Dropdown>
              </div>
            </Form.Item>
          </div>
        </div>

        <div className="vehiclepopup-contents1">
          <div className="vehiclepopup-input">
            <Form.Item
              name="ModelName"
              rules={[
                {
                  required: false,
                  message: "Please select vehicle model",
                },
              ]}
              initialValue={model}
            >
              <div className="">
                <Dropdown
                  overlay={menu2}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <div
                    className=""
                    style={{
                      border: "1px solid #b11226",
                      width: "250px",
                      padding: "6px 0px 6px 4px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    <span>{model}</span>
                  </div>
                </Dropdown>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input1">
            <Form.Item
              name="FuelType"
              rules={[
                {
                  required: false,
                  message: "Please select vehicle fuel",
                },
              ]}
              initialValue={fuel}
            >
              <div className="">
                <Dropdown
                  overlay={menu3}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <div
                    className=""
                    style={{
                      border: "1px solid #b11226",
                      width: "250px",
                      padding: "6px 0px 6px 4px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    <span>{fuel}</span>
                  </div>
                </Dropdown>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input1">
            <Form.Item
              name="year"
              rules={[
                {
                  required: false,
                  message: "Please select vehicle year",
                },
              ]}
              initialValue={year}
            >
              <div className="">
                <Dropdown
                  overlay={menu4}
                  trigger={["click"]}
                  placement="bottomLeft"
                >
                  <div
                    className=""
                    style={{
                      border: "1px solid #b11226",
                      width: "250px",
                      padding: "6px 0px 6px 4px",
                      height: "36px",
                      borderRadius: "5px",
                      fontSize: "1rem",
                      fontWeight: "500",
                    }}
                  >
                    <span>{year}</span>
                  </div>
                </Dropdown>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input">
            <Form.Item
              name="RCnumber"
              rules={[
                {
                  required: false,
                  message: "Please enter vehicle RC number",
                },
              ]}
              initialValue={RCNum}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  value={RCNum}
                  onChange={handleRCNumberChange}
                />
                <span> RC Number </span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-insurence">
          <div className="">
            <Form.Item
              label="Upload Image"
              name="fileupload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="image-upload2"
            >
              <Upload beforeUpload={() => false} listType="picture">
                <Button
                  icon={<CloudUploadOutlined />}
                  className="upload-button"
                >
                  <small>Browse to upload</small>
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-title2">Vehicle Insurance Details</div>
        <div className="vehiclepopup-contents">
          <div className="vehiclepopup-input">
            <Form.Item
              name="Companyname"
              rules={[
                {
                  required: false,
                  message: "Please enter company name ",
                },
              ]}
              initialValue={company}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  value={company}
                  onChange={handleInputChange}
                />
                <span> Company Name </span>
              </div>
            </Form.Item>
          </div>
          <div className="vehiclepopup-input">
            <Form.Item
              name="Insurancenumber"
              rules={[
                {
                  required: false,
                  message: "Please enter policy number",
                },
              ]}
              initialValue={insuranceNum}
            >
              <div className="myvehicle-input">
                <input
                  type="text"
                  value={insuranceNum}
                  // value={vehicleNumber}
                  onChange={handleInsuranceChange}
                />
                <span> Policy Number </span>
              </div>
            </Form.Item>
          </div>
        </div>
        <div className="vehiclepopup-insurence">
          <div>
            <Form.Item
              label="Upload Image"
              name="fileUpload"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              className="image-upload2"
            >
              <Upload beforeUpload={() => false} listType="picture">
                <Button
                  icon={<CloudUploadOutlined />}
                  className="upload-button"
                >
                  <small>Browse to upload</small>
                </Button>
              </Upload>
            </Form.Item>
          </div>
        </div>
        <Form.Item>
          <div className="vehiclepopup-submit">
            <div>
              <button className="vehiclepopup-submit-button" type="submit">
                Submit
              </button>
            </div>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default VechileEdit;
