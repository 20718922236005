// LoginForm.tsx
import React, { useState } from "react";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { Link } from "react-router-dom";
import Success from "./Success";
import axios from "axios";
import cn from "classnames";
import "./otp.css";
import PinField from "react-pin-field";
import ApiDomain from "../../api/ApiDomain";

interface LoginFormProps {
  onClose: () => void;
  onOpenSignupModal: () => void; // Add this prop
}
const LoginForm: React.FC<LoginFormProps> = ({
  onClose,
  onOpenSignupModal,
}) => {
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [otp, setOtp] = useState("");
  const [demoCompleted, setDemoCompleted] = useState("");
  const [mobile, setMobile] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const { Option } = Select;

  const onFinish = async (values: any) => {
    try {
      // Make a POST request to the API endpoint for sending OTP
      const response = await axios.post(
        `${ApiDomain.Domain}/customerLogin`,
        values
      );

      // Handle the response as needed
      console.log("API Response:", response.data);
      const data = response.data;

      if (data.responseCode === 200) {
        console.log(data);
        // localStorage.setItem("token", data.token);
        console.log(data.data.customerName);
        setShowSuccessPopup(true);
        setPhoneNumber(data.data.customerPhone);
      } else if (data.responseCode === 400) {
        setErrorMessage("This phone number is not registered please sign up");
      }
    } catch (error) {
      // Handle errors
      console.error("API Error:", error);
    }
    setMobile(values.phoneNumber);
  };

  const otpVerify = async () => {
    try {
      console.log(otp);
      // Make a POST request to the API endpoint for OTP verification
      const response = await axios.post(
        "https://api.vecrep.com/otpVerification",
        {
          otp,
          phoneNumber,
        },
        { headers: { token: true } }
      );

      console.log("OTP Verification Response:", response.data);
      const data = await response.data;
      if (data.responseCode === 200) {
        localStorage.setItem("token", data.data.token);

        window.location.reload();
        console.log(data.data.token);
        setSuccess(true);
        const token = localStorage.getItem("token");
        console.log(token);
      } else if (data.responseCode === 400) {
        setError(data.message);
      }
    } catch (error) {
      // Handle errors
      console.error("OTP Verification Error:", error);
    }
  };

  const resendOtp = async () => {
    try {
      // Make a POST request to the API endpoint for OTP resend
      const response = await axios.post(
        "https://api.vecrep.com/reSendOtp",
        {
          phoneNumber,
        },
        { headers: { token: true } }
      );

      // Handle the response as needed
      console.log("Resend OTP Response:", response.data);
      // You may want to display a success message or handle it in some way
    } catch (error) {
      // Handle errors
      console.error("Resend OTP Error:", error);
    }
  };

  const onOtpFormFinish = async (values: any) => {
    console.log(values);
    setOtp(values);
    otpVerify();
  };

  console.log(mobile);
  console.log(typeof phoneNumber);
  // Get the last four digits of the phone number
  const lastFourDigits = phoneNumber ? phoneNumber.toString().slice(-4) : "";

  // Create a masked phone number with asterisks
  const maskedPhoneNumber = "*******" + lastFourDigits;
  console.log("test");

  const controlModal = () => {
    onClose();
    onOpenSignupModal();
  };

  return (
    <>
      {success ? (
        <Success />
      ) : (
        <>
          <div className="form-head">
            <h1>VecRep.com</h1>
          </div>

          {error && (
            <div className="error-msg">
              <p>{error}</p>
            </div>
          )}

          {showSuccessPopup ? (
            <div>
              <Form
                name="otp"
                onFinish={() => onOtpFormFinish(otp)}
                className="signUp-form"
              >
                <h1 className="otpHead">Verify OTP</h1>
                <p className="otpPara">
                  OTP has sent to the phone number ends with {maskedPhoneNumber}
                </p>
                <div className="pin-field-container">
                  <PinField
                    length={4}
                    className={cn("pin-field", {})}
                    onComplete={(result: string) => {
                      setOtp(result);
                    }}
                    autoFocus
                    // autoComplete='one-time-password'
                    validate={RegExp(/^[0-9]$/)}
                  />
                </div>

                <h3 className="otpdesc">
                  OTP not received ?{" "}
                  <span
                    className="tearms"
                    onClick={resendOtp}
                    style={{ cursor: "pointer" }}
                  >
                    Resend
                  </span>
                </h3>

                <div className="verify-button">
                  <div>
                    <Form.Item>
                      <button type="submit" className="verify-otp-button">
                        Verify
                      </button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          ) : (
            <Form
              name="basic"
              initialValues={{ remember: true }}
              onFinish={onFinish}
              className="signUp-form"
            >
              {errorMessage ? (
                <div
                  style={{
                    color: "red",
                    textAlign: "center",
                    fontSize: "1rem",
                  }}
                >
                  {errorMessage}
                </div>
              ) : (
                ""
              )}
              {/* <h3>LOGIN</h3>
              <h4>Login with OTP</h4>
              <p>Enter registered mobile number</p> */}

              <Form.Item
                name="phoneNumber"
                rules={[
                  {
                    required: true,
                    message: "Please enter your phone number",
                  },
                ]}
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Select
                    defaultValue="+91"
                    style={{ width: "20%", height: "40px" }}
                    onChange={(value) => console.log(value)}
                  >
                    <Option value="+91">+91</Option>
                    {/* Add more options for other country codes */}
                  </Select>
                  <div className="input-login">
                    <input type="text" required />
                    <span>Phone Number *</span>
                  </div>
                </div>
              </Form.Item>

              <Form.Item>
                <div className="login-submit">
                  <div>
                    <button type="submit" className="login-submit-button">
                      Login with OTP
                    </button>
                  </div>
                </div>
              </Form.Item>
              <h3>OR</h3>
              <h3>
                New here ?{" "}
                <span>
                  <span
                    className="tearms"
                    onClick={controlModal}
                    style={{ cursor: "pointer" }}
                  >
                    Create Account
                  </span>
                </span>
              </h3>
            </Form>
          )}
        </>
      )}
    </>
  );
};

export default LoginForm;
