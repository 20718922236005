import { Button, Modal, Form, Input } from "antd";
import "./Havequestions.css";
import { useState, useEffect } from "react";
import group from "./Group.png";
import axios from "axios";
import PinField from "react-pin-field";
import cn from "classnames";
const Havequestions = () => {
  const [modal2Open, setModal2Open] = useState(false);
  const [modal1Open, setModal1Open] = useState(false);
  const { TextArea } = Input;
  const [otp, setOtp] = useState("");
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [successPopup, setSuccessPopup] = useState<boolean>(false);
  const [queryData, setQueryData] = useState([]);
  const [id, setId] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const onOtpFormFinish = async (values: any) => {
    console.log(values);
    setOtp(values);
    otpVerify();
  };

  const otpVerify = async () => {
    try {
      console.log(otp);
      console.log(id);
      const response = await axios.post(
        "https://api.vecrep.com/queryOtpVerification",
        {
          id,
          otp,
        }
      );

      console.log("OTP Verification Response:", response.data);
      const data = await response.data;
      if (data.responseCode === 200) {
        setSuccessPopup(true);
      } else if (data.responseCode === 400) {
        // setError(data.message);
        console.log(data.message);
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
    }
  };

  const resendOtp = async () => {
    try {
      const response = await axios.post(
        "https://api.vecrep.com/queryResendOtp",
        {
          id,
        }
      );
      console.log("Resend OTP Response:", response.data);
      const data = await response.data;
      if (data.responseCode === 200) {
        console.log("otp has been resended to the number");
      }
    } catch (error) {
      console.error("Resend OTP Error:", error);
    }
  };

  const onFinish = async (values: any) => {
    console.log(values);
    setPhoneNumber(values.phoneNumber);
    const requestData = {
      name: customerName,
      phoneNumber: customerPhone,
      email: customerEmail,
      query: values.query,
    };
    try {
      const response = await axios.post(
        "https://api.vecrep.com/query",
        JSON.stringify(requestData),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      console.log("API Response:", response.data);
      const data = response.data;
      if (data.responseCode === 200) {
        console.log(data.data);
        setQueryData(data.data.customerQuery);
        const query = data.data.customerQuery;
        setId(query._id);
        const token = localStorage.getItem("token");
        if (token) {
          setSuccessPopup(true);
        } else {
          setShowSuccessPopup(true);
          setModal1Open(true);
        }
      } else if (data.responseCode === 400) {
        // setError(data.message);
        console.log("query 400 error");
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const lastFourDigits = phoneNumber ? phoneNumber.toString().slice(-4) : "";
  const maskedPhoneNumber = "*******" + lastFourDigits;

  const closePopups = () => {
    setSuccessPopup(false);
    setModal1Open(false);
    setModal2Open(false);
  };
  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/getSingleCustomer", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        const Data = data.data;
        setCustomerName(Data?.customerName);
        setCustomerEmail(Data?.email);
        setCustomerPhone(Data?.phoneNumber);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const handleEmail = (e: any) => {
    setCustomerEmail(e.target.value);
  };
  const handlePhone = (e: any) => {
    setCustomerPhone(e.target.value);
  };
  const handleName = (e: any) => {
    setCustomerName(e.target.value);
  };
  return (
    <div className='question'>
      <div className='imageq'>
        <img src='assets/images/Have-query.png' className='img' alt='' />
      </div>
      <div className='Questions'>
        <div className='Questions1'>Have any Questions?</div>
        <ul className='Questions2'>
          <li className='Questions3'>
            Send a message request to know about the garage, services and other
            information.
          </li>
          <li className='answers'>
            Get answers to all your queries with just one click
          </li>
        </ul>
        <div className='message'>
          <button className='send-message' onClick={() => setModal2Open(true)}>
            Send Message
          </button>
          <Modal
            centered
            open={modal2Open}
            onOk={() => setModal2Open(false)}
            footer={null}
            onCancel={() => setModal2Open(false)}
            className='query_modal'
            width={650}>
            <div className='query_container'>
              <div className='data_container'>
                <div className='query_heading'>Have any questions ? </div>
                <p>
                  Get your doubts cleared with <br></br> just one click
                </p>
                <Form
                  name='basic'
                  initialValues={{ remember: true }}
                  onFinish={onFinish}
                  labelCol={{ span: 4 }}
                  wrapperCol={{ span: 18 }}
                  className=''>
                  <Form.Item
                    name='name'
                    rules={[
                      { required: false, message: "Please enter your name" },
                    ]}>
                    <div className='input-bx'>
                      <input
                        type='text'
                        defaultValue={customerName}
                        required
                        onChange={handleName}
                      />
                      <span>Name *</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name='phoneNumber'
                    rules={[
                      {
                        required: false,
                        message: "Please enter your phone number",
                      },
                    ]}>
                    <div className='input-bx'>
                      <input
                        type='text'
                        defaultValue={customerPhone}
                        required
                        onChange={handlePhone}
                      />
                      <span>phone number *</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name='email'
                    rules={[
                      { required: false, message: "Please enter your email" },
                      {
                        type: "email",
                        message: "Please enter a valid email address",
                      },
                    ]}>
                    <div className='input-bx'>
                      <input
                        type='email'
                        defaultValue={customerEmail}
                        onChange={handleEmail}
                      />
                      <span>Email</span>
                    </div>
                  </Form.Item>
                  <Form.Item
                    name='query'
                    rules={[
                      {
                        required: false,
                        message: "Please input your query",
                      },
                    ]}>
                    <TextArea
                      placeholder='Write your query...'
                      rows={4}
                      className='query_input'
                    />
                  </Form.Item>

                  <Form.Item>
                    <button className='query_submit' type='submit'>
                      Submit
                    </button>
                  </Form.Item>
                </Form>
              </div>
              <div className='image_container'>
                <img src={group} alt='source_image' />
              </div>
            </div>
          </Modal>
        </div>
        <Modal
          centered
          open={modal1Open}
          onOk={() => setModal1Open(false)}
          footer={null}
          onCancel={() => setModal1Open(false)}
          className='query_modal'>
          {showSuccessPopup && (
            <div>
              <div className='form-heading'>
                <h1>VecRep.com</h1>
              </div>
              <Form
                name='otp'
                onFinish={() => onOtpFormFinish(otp)}
                className='signUp-form'>
                <h1 className='otpHead'>Verify OTP</h1>
                <p className='otpPara'>
                  OTP has sent to the phone number ends with {maskedPhoneNumber}
                </p>

                <div className='pin-field-container'>
                  <PinField
                    length={6}
                    className={cn("pin-field", {})}
                    onComplete={(result: string) => {
                      setOtp(result);
                    }}
                    autoFocus
                    validate={RegExp(/^[0-9]$/)}
                  />
                </div>
                <h3 className='otpdesc'>
                  OTP not recevied ?{" "}
                  <span
                    className='tearms'
                    onClick={resendOtp}
                    style={{ cursor: "pointer" }}>
                    Resend
                  </span>
                </h3>

                <div className='verify-btn'>
                  <Form.Item>
                    <div className='query-submit'>
                      <div>
                        <button type='submit' className='query-submit-button'>
                          Verify
                        </button>
                      </div>
                    </div>
                  </Form.Item>
                </div>
              </Form>
            </div>
          )}
        </Modal>
      </div>
      <Modal
        visible={successPopup}
        onCancel={closePopups}
        centered
        footer={null}
        className='custom-modal'>
        <div className='query-head'>
          <div className='query-image'>
            <img
              src={"assets/images/success-check.png"}
              className='query-icon'
            />
          </div>
        </div>
        <div className='query-info'>
          <div className='query-congrats'>Congrats!</div>
          <div className='query-data'>Query Sent Successfully</div>
          <div>
            <Form.Item>
              <button
                className='query-submit'
                type='submit'
                onClick={closePopups}>
                <span className='tearms'>Ok</span>
              </button>
            </Form.Item>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Havequestions;
