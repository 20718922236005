import { Button, Col, DatePicker, Form, Row, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./getForm.css";
import { useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import { useState } from "react";
import Schedule from "./schedule";
import axios from "axios";
import { CalendarOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";

dayjs.locale("en");
interface BookFormProps {
  vehicleData: {
    vehicleType: number;
    makeName: string;
    modelName: string;
    fuelType: string;
  };
  garageId: string;
}
const BookForm: React.FC<BookFormProps> = ({ vehicleData, garageId }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [vehicleId, setVehicleId] = useState("");
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [addNote, setAddNote] = useState("");
  const [vehicleNumber, setVehicleNumber] = useState("");

  const navigate = useNavigate();

  const handleSlotClick = (slot: string) => {
    setSelectedSlots([slot]);
  };
  const handleInputChange = (event: any) => {
    const inputValue = event.target.value;

    // Check if the input value matches the desired pattern
    const isValidInput = /^[A-Z0-9a-z]*$/.test(inputValue);

    // If valid, update the state
    if (isValidInput) {
      setVehicleNumber(inputValue.toUpperCase());
    }
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date ? date.format("DD-MM-YYYY") : null);
  };
  console.log(vehicleData);
  const vehicletype = vehicleData.vehicleType;
  const vehiclebrand = vehicleData.makeName;
  const vehiclemodel = vehicleData.modelName;
  const vehiclefuel = vehicleData.fuelType;

  const handleCalendarClick = () => {
    setDatePickerVisible(!isDatePickerVisible);
  };
  const handleTabClick = (key: any) => {
    setActiveTab(key);
  };
  const disabledDate = (current: Dayjs) => {
    // Disable dates before today
    return current && current.isBefore(dayjs().startOf("day"));
  };
  const handleNoteChange = (e: any) => {
    setAddNote(e.target.value);
  };
  const onFinishBooking = async (values: any) => {
    console.log(values);
    // setAddNote(values.Note);
    // const note = values.Note;
    const todayDate = values.time.format("DD-MM-YYYY");
    console.log(todayDate);
    const choosedate = selectedDate === null ? todayDate : selectedDate;
    const requestData = {
      garageId: garageId,
      chooseDate: choosedate,
      chooseTime: selectedTime,
      note: addNote,
      vehicleType: vehicleType,
      brand: vehicleBrand === "undefined" ? "" : vehicleBrand,
      model: vehicleModel === "undefined" ? "" : vehicleModel,
      vehicleNumber: vehicleNumber,
      fuelType: vehicleFuel === "undefined" ? "" : vehicleFuel,
    };
    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await fetch(
        "https://api.vecrep.com/createServiceBooking",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("API response:", data);
        navigate("/confirmBooking", {
          state: { apiData: data.serviceBookingId },
        });
      } else {
        console.error("Error fetching data from the API");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const selectedTime = selectedSlots[0];
  console.log(selectedTime);
  console.log(selectedSlots);
  console.log(selectedDate);
  console.log(garageId);
  console.log(vehicleId);

  const currentDate = dayjs();
  const vehicleType = localStorage.getItem("vehicleType");
  const vehicleBrand = localStorage.getItem("brand");
  const vehicleModel = localStorage.getItem("model");
  const vehicleFuel = localStorage.getItem("fuelType");

  return (
    <>
      <div style={{ border: " 10px solid #f5f5f5" }}>
        <Form onFinish={onFinishBooking}>
          <div className='book-header'>
            <div className='book-title'>
              <p>Book Appointment</p>
            </div>
            <div className='vehicle-details'>
              <p>
                <span style={{ color: "grey" }}>Vehicle: </span>
                {vehicleBrand === "undefined"
                  ? "Maruti Suzuki"
                  : `${vehicleBrand}`}
                {vehicleModel === "undefined"
                  ? " / Swift"
                  : ` / ${vehicleModel}`}
                {vehicleFuel === "undefined"
                  ? " / petrol"
                  : ` / ${vehicleFuel}`}
              </p>
            </div>
          </div>
          <div className='vehicle-section'>
            <Form.Item
              name='vehicle'
              rules={[
                { required: true, message: "Please enter vehicle number" },
              ]}>
              <div className='vehicle-input'>
                <input
                  type='text'
                  value={vehicleNumber}
                  onChange={handleInputChange}
                />
                <span>Vehicle Number * </span>
              </div>
            </Form.Item>
          </div>

          <div
            style={{
              border: "solid 0.1px grey",
              margin: "30px",
              marginTop: "1px",
              borderRadius: "10px",
            }}>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}>
                <FormItem name='time' initialValue={currentDate}>
                  <Col xl={24} lg={24} xs={24} sm={24}>
                    <div className='main-form'>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: "15px",
                        }}>
                        {/* <span style={{ marginRight: "8px" }}>Select Date:</span> */}
                        {/* <CalendarOutlined
                        required
                        style={{
                          marginRight: "8px",
                          fontSize: "18px",
                          color: "#B11226",
                          cursor: "pointer",
                        }}
                        onClick={handleCalendarClick}
                      /> */}

                        <DatePicker
                          onChange={handleDateChange}
                          // value={
                          //   selectedDate ? dayjs(selectedDate) : currentDate
                          // }
                          defaultValue={currentDate}
                          disabledDate={disabledDate}
                          format='DD-MM-YYYY'
                        />
                      </div>
                    </div>
                    <Tabs
                      activeKey={activeTab}
                      onTabClick={handleTabClick}
                      style={{}}>
                      <TabPane tab='Morning' key='1'>
                        <Row>
                          <Col xl={24}>
                            {/* Today */}
                            <div className='morning-slot'>
                              {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                              {[
                                "09:00 AM",
                                "09:30 AM",
                                "10:00 AM",
                                "10:30 AM",
                                "11:00 AM",
                                "11:30 AM",
                              ].map((slot) => (
                                <div
                                  key={slot}
                                  style={{ flex: "0 0 calc(16% )" }}>
                                  <Button
                                    className={`slot ${
                                      selectedSlots.includes(slot)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleSlotClick(slot)}
                                    style={{
                                      backgroundColor: selectedSlots.includes(
                                        slot
                                      )
                                        ? "#B11226"
                                        : "white",
                                      color: selectedSlots.includes(slot)
                                        ? "white"
                                        : "black",
                                    }}>
                                    {slot}
                                  </Button>
                                </div>
                              ))}
                            </div>
                            {/* Tomorrow */}
                            {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["10:00-10:30", "10:30-11:00", "11:00-11:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}
                                style={{
                                  backgroundColor: selectedSlots.includes(slot)
                                    ? "#B11226"
                                    : "white",
                                  color: selectedSlots.includes(slot)
                                    ? "white"
                                    : "black",
                                }}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab='Afternoon' key='2'>
                        <Row>
                          <Col xl={24}>
                            {/* Today */}
                            <div className='morning-slot'>
                              {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                              {[
                                "12:00 PM",
                                "12:30 PM",
                                "01:00 PM",
                                "01:30 PM",
                                "02:00 PM",
                                "02:30 PM",
                                "03:00 PM",
                                "03:30 PM",
                                "04:00 PM",
                              ].map((slot) => (
                                <div
                                  key={slot}
                                  style={{ flex: "0 0 calc(16% )" }}>
                                  <Button
                                    className={`slot ${
                                      selectedSlots.includes(slot)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleSlotClick(slot)}
                                    style={{
                                      backgroundColor: selectedSlots.includes(
                                        slot
                                      )
                                        ? "#B11226"
                                        : "white",
                                      color: selectedSlots.includes(slot)
                                        ? "white"
                                        : "black",
                                    }}>
                                    {slot}
                                  </Button>
                                </div>
                              ))}
                            </div>
                            {/* Tomorrow */}
                            {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["14:00-14:30", "14:30-15:00", "15:00-15:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tab='Evening' key='3'>
                        <Row>
                          <Col xl={24}>
                            {/* Today */}
                            <div className='morning-slot'>
                              {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                              {[
                                "04:30 PM",
                                "05:00 PM",
                                "05:30 PM",
                                "06:00 PM",
                                "06:30 PM",
                                "07:00 PM",
                              ].map((slot) => (
                                <div
                                  key={slot}
                                  style={{ flex: "0 0 calc(16% )" }}>
                                  <Button
                                    className={`slot ${
                                      selectedSlots.includes(slot)
                                        ? "selected"
                                        : ""
                                    }`}
                                    onClick={() => handleSlotClick(slot)}
                                    style={{
                                      backgroundColor: selectedSlots.includes(
                                        slot
                                      )
                                        ? "#B11226"
                                        : "white",
                                      color: selectedSlots.includes(slot)
                                        ? "white"
                                        : "black",
                                    }}>
                                    {slot}
                                  </Button>
                                </div>
                              ))}
                            </div>
                            {/* Tomorrow */}
                            {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["17:00-17:30", "17:30-18:00", "18:00-18:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                          </Col>
                        </Row>
                      </TabPane>
                    </Tabs>
                  </Col>
                </FormItem>
              </div>
            </Row>
          </div>
          <div style={{ margin: "30px", marginBottom: 10 }}>
            <Form.Item name='Note' rules={[]}>
              <TextArea
                rows={4}
                placeholder='Add Note'
                value={addNote}
                onChange={handleNoteChange}
              />
            </Form.Item>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}>
              <Form.Item>
                <button className='booking-submit' type='submit'>
                  Book Appointment
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default BookForm;
