import { Button, Col, DatePicker, Form, Row, Tabs } from "antd";
import TextArea from "antd/es/input/TextArea";
// import "./getForm.css";
import { useNavigate } from "react-router-dom";
import TabPane from "antd/es/tabs/TabPane";
import { useState } from "react";
// import Schedule from "./schedule";
import axios from "axios";
import { CalendarOutlined } from "@ant-design/icons";
import FormItem from "antd/es/form/FormItem";
import dayjs, { Dayjs } from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/es";

dayjs.locale("en");

interface RescheduleBookingProps {
  customerData: {
    customerName: string;
    email: string;
    phoneNumber: string;
    vehicle: {
      brand: string;
      fuelType: string;
      model: string;
    };
  };
}
interface vehicledetails {
  customerVehicleBrand: string;
  customerVehicleModel: string;
  customerVehicleFuel: string;
  garageName: string;
  apiData: string;
  closeModal: () => void;
  fetchData: () => void;
  handleMessage: (data: string) => void;
}
const RescheduleBooking: React.FC<vehicledetails> = ({
  customerVehicleBrand,
  customerVehicleModel,
  customerVehicleFuel,
  garageName,
  apiData,
  closeModal,
  fetchData,
  handleMessage,
}) => {
  const [activeTab, setActiveTab] = useState("1");
  const [isDatePickerVisible, setDatePickerVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<string | null>(null);
  const [vehicleId, setVehicleId] = useState("");
  const [selectedSlots, setSelectedSlots] = useState<string[]>([]);
  const [addNote, setAddNote] = useState("");
  const navigate = useNavigate();
  //   console.log(customerData);
  //   const brandToDisplay = customerVehicleBrand || "Default Brand";
  console.log(customerVehicleBrand);
  console.log(customerVehicleModel);
  console.log(customerVehicleFuel);
  console.log(garageName);
  console.log(apiData);

  const handleSlotClick = (slot: string) => {
    setSelectedSlots([slot]); // Select only the clicked slot
  };

  const handleDateChange = (date: Dayjs | null) => {
    setSelectedDate(date ? date.format("DD-MM-YYYY") : null);
  };

  const handleCalendarClick = () => {
    setDatePickerVisible(!isDatePickerVisible);
  };
  const handleTabClick = (key: any) => {
    setActiveTab(key);
  };
  const disabledDate = (current: Dayjs) => {
    return current && current.isBefore(dayjs().startOf("day"));
  };
  const onFinishUpdate = async (values: any) => {
    console.log(values);
    const todayDate = values.time.format("DD-MM-YYYY");
    console.log(todayDate);
    const choosedate = selectedDate === null ? todayDate : selectedDate;
    const requestData = {
      serviceBookingId: apiData,
      date: choosedate,
      time: selectedTime,
    };

    const token = localStorage.getItem("token");
    console.log(token);
    try {
      const response = await fetch(
        "https://api.vecrep.com/updateServiceBooking",
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(requestData),
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        closeModal();
        fetchData();
        // window.location.reload();
        const messageData = data.message;
        handleMessage(messageData);
        console.log(data.message);
        console.log("Booking updated");
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const selectedTime = selectedSlots[0];
  console.log(selectedTime);
  console.log(selectedSlots);
  console.log(selectedDate);

  const currentDate = dayjs();
  const vehicleType = localStorage.getItem("vehicleType");
  const vehicleBrand = localStorage.getItem("brand");
  const vehicleModel = localStorage.getItem("model");
  const vehicleFuel = localStorage.getItem("fuelType");

  return (
    <>
      <div style={{ border: " 8px solid #f5f5f5" }}>
        <div className='book-header'>
          <div className='book-title'>
            <p>Reschedule Booking</p>
          </div>
          <div className='vehicle-details'>
            <p>
              <span style={{ color: "grey" }}>Vehicle: </span>
              {customerVehicleBrand ? `${customerVehicleBrand} ` : ""}
              {customerVehicleModel ? ` / ${customerVehicleModel}` : ""}
              {customerVehicleFuel ? ` / ${customerVehicleFuel}` : ""}
            </p>
          </div>
        </div>
        {/* <div className=''>
          <p
            style={{
              padding: "10px 0px 0px 0px",
              fontSize: "1.5rem",
              fontWeight: "700",
              textAlign: "center",
            }}>
            Reschedule Appointment
          </p>
        </div>
        <Row>
          <Col xl={24} sm={24} xs={24}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                gap: 15,
                alignItems: "center",
                paddingTop: "10px",
              }}>
              <div>
                <h2
                  style={{
                    fontSize: "14px",
                    color: "#b11226",
                    textAlign: "center",
                  }}>
                  <span style={{ color: "grey" }}>Vehicle: </span>
                  {customerVehicleBrand ? `${customerVehicleBrand} ` : ""}
                  {customerVehicleModel ? ` / ${customerVehicleModel}` : ""}
                  {customerVehicleFuel ? ` / ${customerVehicleFuel}` : ""}
                </h2>
              </div>
            </div>
          </Col>
        </Row> */}

        <div className='reschedule-garageName'>
          <div>{garageName}</div>
        </div>
        <Form
          name='basic'
          initialValues={{ remember: true }}
          onFinish={onFinishUpdate}
          className=''>
          <div
            style={{
              border: "solid 0.1px grey",
              margin: "30px",
              marginTop: "10px",
              borderRadius: "10px",
            }}>
            <Row>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-around",
                }}>
                <Col xl={24} lg={24} xs={24} sm={24}>
                  <FormItem name='time' initialValue={currentDate}>
                    <div className='main-form'>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          paddingTop: "15px",
                        }}>
                        {/* <CalendarOutlined
                      style={{
                        marginRight: "8px",
                        fontSize: "18px",
                        color: "#B11226",
                        cursor: "pointer",
                      }}
                      onClick={handleCalendarClick}
                    /> */}

                        <DatePicker
                          onChange={handleDateChange}
                          // value={
                          //   selectedDate ? dayjs(selectedDate) : currentDate
                          // }
                          defaultValue={currentDate}
                          disabledDate={disabledDate}
                          format='DD-MM-YYYY'
                        />
                      </div>
                    </div>
                  </FormItem>
                  <Tabs
                    activeKey={activeTab}
                    onTabClick={handleTabClick}
                    style={{}}>
                    <TabPane tab='Morning' key='1'>
                      <Row>
                        <Col xl={24}>
                          {/* Today */}
                          <div className='morning-slot'>
                            {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                            {[
                              "09:00 AM",
                              "09:30 AM",
                              "10:00 AM",
                              "10:30 AM",
                              "11:00 AM",
                              "11:30 AM",
                            ].map((slot) => (
                              <div
                                key={slot}
                                style={{ flex: "0 0 calc(16% )" }}>
                                <Button
                                  className={`slot ${
                                    selectedSlots.includes(slot)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotClick(slot)}
                                  style={{
                                    backgroundColor: selectedSlots.includes(
                                      slot
                                    )
                                      ? "#B11226"
                                      : "white",
                                    color: selectedSlots.includes(slot)
                                      ? "white"
                                      : "black",
                                  }}>
                                  {slot}
                                </Button>
                              </div>
                            ))}
                          </div>
                          {/* Tomorrow */}
                          {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["10:00-10:30", "10:30-11:00", "11:00-11:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}
                                style={{
                                  backgroundColor: selectedSlots.includes(slot)
                                    ? "#B11226"
                                    : "white",
                                  color: selectedSlots.includes(slot)
                                    ? "white"
                                    : "black",
                                }}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab='Afternoon' key='2'>
                      <Row>
                        <Col xl={24}>
                          {/* Today */}
                          <div className='morning-slot'>
                            {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                            {[
                              "12:00 PM",
                              "12:30 PM",
                              "01:00 PM",
                              "01:30 PM",
                              "02:00 PM",
                              "02:30 PM",
                              "03:00 PM",
                              "03:30 PM",
                              "04:00 PM",
                            ].map((slot) => (
                              <div
                                key={slot}
                                style={{ flex: "0 0 calc(16% )" }}>
                                <Button
                                  className={`slot ${
                                    selectedSlots.includes(slot)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotClick(slot)}
                                  style={{
                                    backgroundColor: selectedSlots.includes(
                                      slot
                                    )
                                      ? "#B11226"
                                      : "white",
                                    color: selectedSlots.includes(slot)
                                      ? "white"
                                      : "black",
                                  }}>
                                  {slot}
                                </Button>
                              </div>
                            ))}
                          </div>
                          {/* Tomorrow */}
                          {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["14:00-14:30", "14:30-15:00", "15:00-15:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                    <TabPane tab='Evening' key='3'>
                      <Row>
                        <Col xl={24}>
                          {/* Today */}
                          <div className='morning-slot'>
                            {/* <div>
                          <h3 className='slot-today'>Today</h3>
                        </div> */}
                            {[
                              "04:30 PM",
                              "05:00 PM",
                              "05:30 PM",
                              "06:00 PM",
                              "06:30 PM",
                              "07:00 PM",
                            ].map((slot) => (
                              <div
                                key={slot}
                                style={{ flex: "0 0 calc(16% )" }}>
                                <Button
                                  className={`slot ${
                                    selectedSlots.includes(slot)
                                      ? "selected"
                                      : ""
                                  }`}
                                  onClick={() => handleSlotClick(slot)}
                                  style={{
                                    backgroundColor: selectedSlots.includes(
                                      slot
                                    )
                                      ? "#B11226"
                                      : "white",
                                    color: selectedSlots.includes(slot)
                                      ? "white"
                                      : "black",
                                  }}>
                                  {slot}
                                </Button>
                              </div>
                            ))}
                          </div>
                          {/* Tomorrow */}
                          {/* <div style={{ display: "flex", gap: 11, padding: 10 }}>
                        <div>
                          <h3 className='slot-today'>Tomorrow</h3>
                        </div>
                        {["17:00-17:30", "17:30-18:00", "18:00-18:30"].map(
                          (slot) => (
                            <div key={slot}>
                              <Button
                                className={`slot ${
                                  selectedSlots.includes(slot) ? "selected" : ""
                                }`}
                                onClick={() => handleSlotClick(slot)}>
                                {slot}
                              </Button>
                            </div>
                          )
                        )}
                      </div> */}
                        </Col>
                      </Row>
                    </TabPane>
                  </Tabs>
                </Col>
              </div>
              {/* <div className='main-form'>
            <FormItem label='Select'>
              <CalendarOutlined
                style={{
                  marginRight: "8px",
                  fontSize: "18px",
                  color: "#1890ff",
                  cursor: "pointer",
                }}
                onClick={handleCalendarClick}
              />
              {isDatePickerVisible && (
                <DatePicker
                  onChange={handleDateChange}
                  // value={selectedDate}
                  disabledDate={disabledDate}
                  format='DD-MM-YYYY'
                />
              )}
            </FormItem>
          </div> */}
            </Row>
          </div>
          <div style={{ margin: "30px", marginBottom: 10 }}>
            {/* <Form.Item name='Note' rules={[]}>
            <TextArea
              style={{ height: 100, marginBottom: 21 }}
              placeholder='Add Note'
            />
          </Form.Item> */}
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
              }}>
              <Form.Item>
                <button className='reschedule-submit' type='submit'>
                  Update Booking
                </button>
              </Form.Item>
            </div>
          </div>
        </Form>
      </div>
    </>
  );
};
export default RescheduleBooking;
