import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Myfavourites.css";
import { HeartFilled, HeartOutlined } from "@ant-design/icons";
import { Divider } from "antd";

interface FavouriteData {
  garageName: string;
  garageType: number;
  services: [];
  isFavorite: boolean;
  myFavoriteId: string;
  garageId: string;
  myFavoriteStatus: boolean;
  jobsDone: number;
  distance: number;
}

const Myfavourites = () => {
  const [isFavorite, setIsFavorite] = useState(true);
  const [loading, setLoading] = useState(false);
  const [favouritesData, setFavouritesData] = useState<FavouriteData[]>([]);
  const [favouriteId, setFavouriteId] = useState("");
  const [garageId, setGarageId] = useState("");
  const [numOfFav, setNumofFav] = useState("");
  const navigate = useNavigate();
  const initialDisplayCount = 1;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [expandedCards, setExpandedCards] = useState<number[]>([]);

  useEffect(() => {
    fetchFavourites();
  }, []);

  const handleMoreClick = (index: number) => {
    // Toggle the expanded state for the clicked card
    setExpandedCards((prevExpanded) =>
      prevExpanded.includes(index)
        ? prevExpanded.filter((item) => item !== index)
        : [...prevExpanded, index]
    );
  };

  const handleRoute = (
    GarageId: string,
    myFavoriteStatus: boolean,
    myFavoriteId: string
  ) => {
    console.log("garageId:", GarageId);
    navigate("/garagedetails", {
      state: {
        apiData: GarageId,
        vehicle: 1,
        favouriteStatus: myFavoriteStatus,
        favouriteId: myFavoriteId,
      },
    });
  };

  const handleToggleFavorite = async (index: number, myFavoriteId: string) => {
    if (loading) return;

    setLoading(true);

    try {
      const updatedData = [...favouritesData];

      if (updatedData[index].isFavorite) {
        removeFavourites(myFavoriteId);
        console.log("Removing from favourites");
      } else {
        addFavourites();
        console.log("Adding to favourites");
      }

      // Toggle the favorite status locally for the specific element
      updatedData[index].isFavorite = !updatedData[index].isFavorite;
      setFavouritesData(updatedData);
    } catch (error) {
      console.error("Error updating favorites:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchFavourites = async () => {
    const token = localStorage.getItem("token");
    const requestData = {
      location: [17.4065, 78.4772],
    };
    try {
      const response = await fetch("https://api.vecrep.com/listOfFavorites", {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        const updatedData = data.data.map((item: FavouriteData) => ({
          ...item,
          isFavorite: true, // Set the initial value for isFavorite
        }));
        setFavouritesData(updatedData);
        setNumofFav(updatedData?.length);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const addFavourites = async () => {
    const token = localStorage.getItem("token");
    const neededData = {
      garageId: garageId,
    };
    try {
      const response = await fetch("https://api.vecrep.com/myFavoite", {
        method: "POST",
        body: JSON.stringify(neededData),
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        fetchFavourites();
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const removeFavourites = async (myFavoriteId: string) => {
    const token = localStorage.getItem("token");
    const requestData = {
      myFavoriteId: myFavoriteId,
    };
    try {
      const response = await fetch(
        "https://api.vecrep.com/cancelleMyFavorite",
        {
          method: "PUT",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        fetchFavourites();
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  return (
    <div className="myfav-container">
      <div className="myfav-title-row">
        <div className="myfav-title">My Favourites</div>
        <div className="fav-count">
          {numOfFav ? numOfFav : 0} <HeartFilled style={{ color: "#b11226" }} />
        </div>
      </div>
      <div className="myfav-contents">
        {favouritesData && favouritesData.length > 0 ? (
          favouritesData.map(
            (
              {
                garageName,
                garageType,
                services,
                myFavoriteId,
                garageId,
                myFavoriteStatus,
                jobsDone,
                distance,
              },
              index
            ) => (
              <div className="garages" key={index}>
                <div
                  className="image_garage"
                  onClick={() =>
                    handleRoute(garageId, myFavoriteStatus, myFavoriteId)
                  }
                >
                  <img src="assets/images/dashboard-garage.png" alt="" />
                  <div className="image_overlay"></div>
                </div>
                <div className="rating-content">
                  <div className="rating">
                    <p className="rating-text">4.0</p>
                    <div className="rating-image">
                      <img src="assets/images/star-image.png" alt="" />
                    </div>
                  </div>
                </div>
                <div className="content">
                  <div className="fav-row">
                    <div
                      className="garage-row"
                      onClick={() =>
                        handleRoute(garageId, myFavoriteStatus, myFavoriteId)
                      }
                    >
                      <h4 className="garage-name">
                        {garageName && garageName}
                      </h4>
                      <p className="wheeler">
                        ({garageType && garageType === 1 ? "4w" : "2w"})
                      </p>
                    </div>
                    <div>
                      <p>
                        {favouritesData[index].isFavorite ? (
                          <HeartFilled
                            style={{ color: "#b11226" }}
                            onClick={() => {
                              setFavouriteId(myFavoriteId);
                              setGarageId(garageId);
                              handleToggleFavorite(index, myFavoriteId);
                              console.log("Additional code executed");
                            }}
                          />
                        ) : (
                          <HeartOutlined
                            onClick={() =>
                              handleToggleFavorite(index, myFavoriteId)
                            }
                          />
                        )}
                      </p>
                    </div>
                  </div>
                  <p
                    className="font"
                    onClick={() =>
                      handleRoute(garageId, myFavoriteStatus, myFavoriteId)
                    }
                  >
                    <img src="assets\icons\map-icon.svg" alt="" />
                    <span>{distance && distance} km away</span>
                  </p>
                  <p
                    className="font"
                    onClick={() =>
                      handleRoute(garageId, myFavoriteStatus, myFavoriteId)
                    }
                  >
                    {jobsDone && jobsDone > 0
                      ? `${jobsDone} jobs done`
                      : "No jobs done"}
                  </p>
                  <ul className="service-list">
                    {services.length > 0 ? (
                      <ul>
                        {services
                          .slice(
                            0,
                            expandedCards.includes(index)
                              ? services.length
                              : displayCount
                          )
                          .map(({ serviceName, serviceId }) => (
                            <li key={serviceId}>
                              <p>{serviceName}</p>
                            </li>
                          ))}
                      </ul>
                    ) : (
                      <p>No services found</p>
                    )}
                    {services.length > initialDisplayCount &&
                      displayCount < services.length && (
                        <div
                        // onClick={() => handleMoreClick(index)}
                        // style={{ cursor: "pointer" }}
                        >
                          {expandedCards.includes(index) ? "..Less" : "..."}
                        </div>
                      )}
                  </ul>
                </div>
              </div>
            )
          )
        ) : (
          <div className="handle-nofav">
            <h4 className="handle-fav">My Favourites are not available</h4>
          </div>
        )}
      </div>
    </div>
  );
};

export default Myfavourites;
