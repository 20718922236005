import React, { useState } from "react";
import "./popularSearch.css";
import { Col, Row } from "antd";
import Collapsedview from "../Collapse/Collapse";
import { Link, useLocation } from "react-router-dom";
import ExtraPerks from "./ExtraPerks";
import { carPopularSearches, bikePopularSearches } from "./carBikePopularSearches";


const PopularSearch: React.FC = () => {
  const [clickedLinks, setClickedLinks] = useState<string[]>([]);

  const handleLinkClick = (searchTerm: string) => {
    setClickedLinks((prevClickedLinks) => [...prevClickedLinks, searchTerm]);
  };

  const faq = [
    {
      title:
        "What types of car and bike repair services does VecRep offer online?",
      description:
        "VecRep.com has partnered with more than 40 verified garages across different cities so that you can choose anyone closest to your location. You can avail of repair and maintenance services for all car and bike models of every brand. Visit our services page for all the details, or call us for assistance.",
    },
    {
      title:
        "Can I schedule a vehicle service for a specific date and time that suits my convenience?",
      description:
        "We are available round the clock. You can choose any of our verified garages, closer to your home or office, and even the date and time according to your convenience. The idea is to serve your vehicle requirements and any emergency situation. VecRep.com is open 24x7. Please feel free to call us anytime to assist you.",
    },
    {
      title: "How can I contact customer support at VecRep?",
      description:
        "You can call us on 9056262909 anytime you need assistance. Our customer service representatives are available round the clock to hear you and provide the needed assistance.",
    },
    {
      title:
        "What payment options are available for booking car and bike services online?",
      description:
        "We accept, debit and credit cards, payments through cash, and UPI transactions as well. You can pay us anyhow you are comfortable with.",
    },
    {
      title: "How can I book a car or bike service online on your website?",
      description:
        "To book car or bike services online, you need to visit our website, choose your location and select the type of service you want. You can choose your schedule as well. It’s a simple process and hardly takes a minute to book VecRep.com’s car or bike services online.",
    },
    {
      title: "Is it possible to track the status of my vehicle service online?",
      description:
        "You can track the progress of your vehicle’s servicing process in real-time and plan your schedule accordingly. Updates of every step get automatically updated on our system enabling you to track it in real time and suit your convenience.",
    },
  ];

  return (
    <>
      <div className="desktop-popularSearch">
        <div>
          <hr />
          <div className="desktop-pSearchTitle"> POPULAR SEARCHES </div>
          <div className="desktop-pSearchlinks">
            {carPopularSearches.map((search, index) => (
              !clickedLinks.includes(search) && (
                <Link
                  key={index}
                  to={`/${search.replace(/\s+/g, "-").toLowerCase()}`}
                  onClick={() => handleLinkClick(search)}
                >
                  {search}
                </Link>
              )
            ))}
          </div>

          <div className="desktop-pSearchlinks">
            {bikePopularSearches.map((search, index) => (
              !clickedLinks.includes(search) && (
                <Link
                  key={index}
                  to={`/${search.replace(/\s+/g, "-").toLowerCase()}`}
                  onClick={() => handleLinkClick(search)}
                >
                  {search}
                </Link>
              )
            ))}
          </div>
        </div>
      </div>

      <div className="dashboard_services" style={{ width: "100%" }}>
        <h2 className="text-center">Frequently Asked Questions</h2>
        <Row>
          <Collapsedview FAQContent={faq} />
        </Row>
      </div>

      <ExtraPerks />
    </>
  );
};

export default PopularSearch;
