import React, { useState } from "react";
import "./whyChooseUs.css";
import { Col } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown, faAngleUp } from "@fortawesome/free-solid-svg-icons";

const WhyChooseUs: React.FC = () => {
  // Array of texts for dynamic rendering
  const reasons = [
    "Best verified garages all around the city",
    "Unlock diversified expert car and bike services",
    "Compare prices, verify; get the best deals on services",
    "Heavy discount & cashbacks on every service bill",
    "Reviews and ratings from verified users",
    "Authentic spare parts at affordable rates",
    "Personalized garage recommendations",
    "Flexible scheduling and provisions to reschedule",
    "Hassle-free garage booking with user-friendly interfaces",
    "Stay updated with vehicle inspection reports and job cards",
    "Multiple payment options through secured gateways",
    "Enjoy service warranty on every job done",
    "Dedicated and round-the-clock customer support",
  ];
  // State to manage visibility of reasons
  const [showAllReasons, setShowAllReasons] = useState(false);

  // Slice reasons array based on the showAllReasons state
  const reasonsToShow = showAllReasons ? reasons : reasons.slice(0, 4);

  return (
    <>
      <Col span={24}>
        <div className="why-choose-repairpal ng-full-width" style={{ margin: 'auto' }}>
          <div className="why-choose-repairpal-content">
            <div className="ng-flex ng-space-between">
              <div className="why-choose-header ng-flex ng-flex-column">
                {/* <div className="why-choose-title text-center">Why Choose VecRep.com?</div> */}
                <div className="why-choose-subtitle">
                  See how we compare to the other repair shops.
                </div>
              </div>
              <div className="ng-flex ng-align-end">
                <div className="ng-flex ng-align-center">
                  <img
                    className="rp-certified-logo"
                    src="/assets/images/logo.png"
                    alt="Vecrep"
                  />
                  <div className="why-choose-vs hide-on-mobile">vs</div>
                  <div className="traditional-shops ng-flex ng-flex-column ng-align-center hide-on-mobile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      fill="#8795A1"
                      viewBox="0 0 256 256"
                    >
                      <path d="M226.76,69a8,8,0,0,0-12.84-2.88l-40.3,37.19-17.23-3.7-3.7-17.23,37.19-40.3A8,8,0,0,0,187,29.24,72,72,0,0,0,88,96,72.34,72.34,0,0,0,94,124.94L33.79,177c-.15.12-.29.26-.43.39a32,32,0,0,0,45.26,45.26c.13-.13.27-.28.39-.42L131.06,162A72,72,0,0,0,232,96,71.56,71.56,0,0,0,226.76,69ZM160,152a56.14,56.14,0,0,1-27.07-7,8,8,0,0,0-9.92,1.77L67.11,211.51a16,16,0,0,1-22.62-22.62L109.18,133a8,8,0,0,0,1.77-9.93,56,56,0,0,1,58.36-82.31l-31.2,33.81a8,8,0,0,0-1.94,7.1L141.83,108a8,8,0,0,0,6.14,6.14l26.35,5.66a8,8,0,0,0,7.1-1.94l33.81-31.2A56.06,56.06,0,0,1,160,152Z"></path>
                    </svg>
                    <div className="traditional-shops-text">
                      Traditional Shops
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="why-choose-reason-list ng-flex ng-flex-column">
              {reasonsToShow?.map((reason: any, index: number) => (
                <div className="why-choose-reason ng-flex ng-align-center ng-space-between">
                  <div className="why-choose-reason-text">{reason}</div>
                  <div className="why-choose-icons ng-flex ng-space-between">
                    <div className="why-choose-icon ng-flex ng-align-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#0098D3"
                        viewBox="0 0 256 256"
                      >
                        <path d="M173.66,98.34a8,8,0,0,1,0,11.32l-56,56a8,8,0,0,1-11.32,0l-24-24a8,8,0,0,1,11.32-11.32L112,148.69l50.34-50.35A8,8,0,0,1,173.66,98.34ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                    <div className="why-choose-icon ng-flex ng-align-center hide-on-mobile">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        fill="#8795A1"
                        viewBox="0 0 256 256"
                      >
                        <path d="M165.66,101.66,139.31,128l26.35,26.34a8,8,0,0,1-11.32,11.32L128,139.31l-26.34,26.35a8,8,0,0,1-11.32-11.32L116.69,128,90.34,101.66a8,8,0,0,1,11.32-11.32L128,116.69l26.34-26.35a8,8,0,0,1,11.32,11.32ZM232,128A104,104,0,1,1,128,24,104.11,104.11,0,0,1,232,128Zm-16,0a88,88,0,1,0-88,88A88.1,88.1,0,0,0,216,128Z"></path>
                      </svg>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div
                className="btn-view-locations-container ng-flex"
                data-testid="view-directory"
              >
                <div className="DesignSystemButton">
                  <button className="btn ripple ng-body-2 btn-view-locations DesignSystemButton--variant-primary DesignSystemButton--size-regular ng-clickable ng-width-max-content" onClick={() => setShowAllReasons(!showAllReasons)} style={{ cursor: 'pointer' }}>
                    <div className="ng-flex ng-center">
                      <div>
                        {showAllReasons ? "Read Less" : "Read More"}
                        <FontAwesomeIcon
                            style={{ marginLeft: '0.5rem' }}
                            icon={showAllReasons ? faAngleUp : faAngleDown}
                        />
                      </div>
                    </div>
                  </button>
                </div>
              </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default WhyChooseUs;
