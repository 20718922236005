import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import "./Mybooking.css";
import { Space, Dropdown, Menu, Divider } from "antd";
import { DownOutlined, LineChartOutlined } from "@ant-design/icons";
import MybookData from "./MybookData";

const Mybooking = () => {
  const [activeButton, setActiveButton] = useState("Upcoming");
  const [upcomingData, setUpcomingData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const [cancelledData, setCancelledData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [parentShowBookingPage, setParentShowBookingPage] = useState(false);

  const mybookDataRef = useRef<any>(null);

  const closehandle = () => {
    if (mybookDataRef.current && mybookDataRef.current.close) {
      mybookDataRef.current.close();
    }
  };

  const handleButtonClick = (button: string) => {
    setActiveButton(button);
    closehandle();
    console.log(button);
    if (button === "Upcoming") {
      fetchUpcoming();
      setMainData(upcomingData);
    } else if (button === "Completed") {
      fetchCompleted();
      setMainData(completedData);
    } else if (button === "Cancelled") {
      fetchCancelled();
      setMainData(cancelledData);
    }
  };

  useEffect(() => {
    fetchCancelled();
    fetchCompleted();
    fetchUpcoming();
  }, []);
  useEffect(() => {
    setMainData(upcomingData);
  }, [upcomingData]);
  const fetchUpcoming = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.vecrep.com/upcomingBookAppointments",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setUpcomingData(data.data);
      } else if (data.responseCode === 400) {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchCompleted = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.vecrep.com/completedBookAppointments",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setCompletedData(data.data);
      } else if (data.responseCode === 400) {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchCancelled = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        "https://api.vecrep.com/cancelledBookAppointments",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        setCancelledData(data.data);
      } else if (data.responseCode === 400) {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const items = [
    {
      label: (
        <div className={` ${activeButton === "Upcoming" ? "active" : ""}`}>
          <button
            className={`dropdown-button ${
              activeButton === "Upcoming" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("Upcoming")}>
            Upcoming
          </button>
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div className={`${activeButton === "Completed" ? "active" : ""}`}>
          <button
            className={` dropdown-button ${
              activeButton === "Completed" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("Completed")}>
            Completed
          </button>
        </div>
      ),
      key: "1",
    },
    // {
    //   type: "divider",
    // },
    {
      label: (
        <div className={` ${activeButton === "Cancelled" ? "active" : ""}`}>
          <button
            className={`dropdown-button ${
              activeButton === "Cancelled" ? "active-button" : ""
            }`}
            onClick={() => handleButtonClick("Cancelled")}>
            Cancelled
          </button>
        </div>
      ),
      key: "3",
    },
  ];

  return (
    <div className='mybooking-container'>
      <div className='mybooking-title-row'>
        <div className='mybooking-title'>My Bookings</div>
        <Dropdown
          overlay={
            <Menu
            //   onClick={handleMenuSelect}
            //   selectedKeys={[selectedDay]}
            >
              {items.map((item) => (
                <Menu.Item key={item.key}>{item.label}</Menu.Item>
              ))}
            </Menu>
          }
          trigger={["click"]}>
          <a onClick={(e) => e.preventDefault()}>
            <div
              className={`mobile-dropdown ${
                activeButton === "Completed"
                  ? "completed-border"
                  : activeButton === "Cancelled"
                  ? "cancelled-border"
                  : "upcoming-border"
              }`}>
              <Space>
                {activeButton}
                <DownOutlined style={{ fontSize: "14px" }} />
              </Space>
            </div>
          </a>
        </Dropdown>
        <div className='mybooking-nav'>
          <div
            className={`mybooking-upcoming ${
              activeButton === "Upcoming" ? "active" : ""
            }`}>
            <button
              className={`upcoming-button ${
                activeButton === "Upcoming" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("Upcoming")}>
              Upcoming
            </button>
          </div>
          <div
            className={`mybooking-completed ${
              activeButton === "Completed" ? "active" : ""
            }`}>
            <button
              className={`completed-button ${
                activeButton === "Completed" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("Completed")}>
              Completed
            </button>
          </div>
          <div
            className={`mybooking-cancelled ${
              activeButton === "Cancelled" ? "active" : ""
            }`}>
            <button
              className={`cancelled-button ${
                activeButton === "Cancelled" ? "active-button" : ""
              }`}
              onClick={() => handleButtonClick("Cancelled")}>
              Cancelled
            </button>
          </div>
        </div>
      </div>
      <MybookData
        mainData={mainData}
        activeButton={activeButton}
        ref={mybookDataRef}
        fetchUpcoming={fetchUpcoming}
        fetchCompleted={fetchCompleted}
        fetchCancelled={fetchCancelled}
      />
    </div>
  );
};

export default Mybooking;
