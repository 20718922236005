// App.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import HomePage from "./components/Homepage";
import Navbar from "./components/Navbar/Navbar";
import SearchServices from "./components/searchServices";
import Footer from "./components/Footer/Footer";
import ConfirmBooking from "./components/ConfirmBooking";
import GarageDetails from "./components/GarageDetails";
import Myvehicle from "./components/Myvehicle/Myvehicle";
import Myfavourites from "./components/MyFavorites/Myfavourites";
import Mybooking from "./components/Mybooking/Mybooking";
import Myprofile from "./components/Myprofile/Myprofile";
import ListGarages from "./components/ListGarages/ListGarages";
import NotFoundPage from "./components/Auth/404/404";
import NearMeGarage from "./components/searchServices/NearMeGarage";
import Terms from "./components/Terms/Terms";
const App: React.FC = () => {
const baseURL = 'https://api.vecrep.com/';
  return (
    <Routes>
      <Route
        path="/:city/:garage/:area/:id/:section"
        element={
          <>
            {/* <Navbar /> */}
            <GarageDetails />
            {/* <Footer /> */}
          </>
        }
      />
      <Route
        path="/:city/:garage/:area/:id"
        element={
          <>
            {/* <Navbar /> */}
            <GarageDetails />
            {/* <Footer /> */}
          </>
        }
      />
      
      <Route
        path="/:city/:area/:service"
        element={
          <>
            {/* <Navbar /> */}
            <SearchServices />
            {/* <Footer /> */}
          </>
        }
      />
      <Route
        path="/:service-near-me"
        element={
          <>
            <Navbar />
            <NearMeGarage />
            <Footer />
          </>
        }
      />
      <Route
        path="/:city/:service"
        element={
          <>
            {/* <Navbar /> */}
            <SearchServices />
            {/* <Footer /> */}
          </>
        }
      />
      <Route
        path="/:city"
        element={
          <>
            <Navbar />
            <HomePage />
            <Footer />
          </>
        }
      />
      <Route
        path="/services"
        element={
          <>
            {/* <Navbar /> */}
            <SearchServices />
            {/* <Footer /> */}
          </>
        }
      />
      <Route
        path="/confirmBooking"
        element={
          <>
            <Navbar />
            <ConfirmBooking />
          </>
        }
      />
      <Route
        path="/garagedetails"
        element={
          <>
            {/* <Navbar /> */}
            <GarageDetails />
            {/* <Footer /> */}
          </>
        }
      />
      <Route
        path="/myvehicle"
        element={
          <>
            <Navbar />
            <Myvehicle />
          </>
        }
      />
      <Route
        path="/myfavourites"
        element={
          <>
            <Navbar />
            <Myfavourites />
          </>
        }
      />
      <Route
        path="/mybooking"
        element={
          <>
            <Navbar />
            <Mybooking />
          </>
        }
      />
      <Route
        path="/myprofile"
        element={
          <>
            <Navbar />
            <Myprofile />
          </>
        }
      />
      <Route
        path="/terms-conditions"
        element={
          <>
            <Navbar />
            <Terms />
            <Footer />
          </>
        }
      />
      <Route path="/listgarages" element={<ListGarages />} />
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
