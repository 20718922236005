import React, { useEffect, useRef, useState } from "react";
import { EnvironmentFilled, EnvironmentOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { useDispatch } from "react-redux";
import { setCoordinates } from "../../../Redux/store";

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface Address {
  area: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  plain(): string;
}

const initialCoordinates: Coordinates = {
  latitude: 0, // Replace with your default latitude
  longitude: 0, // Replace with your default longitude
};

const initialAddress: Address = {
  area: "",
  city: "Hyderabad",
  state: "Telangana",
  zip: "500036",
  country: "INDIA",
  plain() {
    return `${this.city}, ${this.zip}, ${this.state}, ${this.country}`;
  },
};

interface LocationSearchProps {
  onLocationChange: (coords: Coordinates, address: Address) => void;
}

const LocationSearchRes: React.FC<LocationSearchProps> = ({
  onLocationChange,
}) => {
  const searchInputRef = useRef<HTMLInputElement>(null);
  const [address, setAddress] = useState<Address>(initialAddress);
  const [modalVisible, setModalVisible] = useState(false);
  const [suggestions, setSuggestions] = useState<string[]>([]); // Added state for suggestions

  const apiKey = "AIzaSyD0pb4Bwj8KYUWRimZfmNuZQ9BOotkezF4";
  const mapApiJs = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&v=weekly`;
  const geocodeJson = "https://maps.googleapis.com/maps/api/geocode/json";

  const dispatch = useDispatch();

  const loadAsyncScript = (src: string): Promise<HTMLScriptElement> => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      Object.assign(script, {
        type: "text/javascript",
        async: true,
        src,
      });
      script.addEventListener("load", () => resolve(script));
      document.head.appendChild(script);
    });
  };

  const initAutocomplete = () => {
    if (!searchInputRef.current) return;

    const autocomplete = new google.maps.places.Autocomplete(
      searchInputRef.current
    );
    autocomplete.setFields(["address_component", "geometry"]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  useEffect(() => {
    loadAsyncScript(mapApiJs).then(() => {
      initAutocomplete();
      findMyLocation(); // Fetch the user's location by default when entering the page
    });
  }, []);

  const onChangeAddress = (autocomplete: google.maps.places.Autocomplete) => {
    const place = autocomplete.getPlace();

    if (!place || !place.geometry || !place.geometry.location) {
      console.error(
        "Invalid place object or no geometry found for place:",
        place
      );
      return;
    }

    const newAddress = extractAddress(place);
    setAddress(newAddress);

    const { lat, lng } = place.geometry.location;
    const latitude = lat?.() ?? 0;
    const longitude = lng?.() ?? 0;

    console.log("Latitude:", latitude);
    console.log("Longitude:", longitude);
    dispatch(setCoordinates(latitude, longitude));

    // Pass both coordinates and address to the parent component
    onLocationChange({ latitude, longitude }, newAddress);
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log("Latitude:", latitude + ", Longitude:", longitude);

          const newCoords = { latitude, longitude };
          reverseGeocode(newCoords);
        },
        (error) => {
          console.error("Error getting geolocation:", error);
        }
      );
    }
  };

  const reverseGeocode = (coords: Coordinates) => {
    const { latitude, longitude } = coords;
    const url = `${geocodeJson}?key=${apiKey}&latlng=${latitude},${longitude}`;

    if (searchInputRef.current) {
      searchInputRef.current.value = "Getting your location...";
    }
    console.log("Fetching address from:", url);

    fetch(url)
      .then((response) => response.json())
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);
        setAddress(_address);

        if (searchInputRef.current) {
          searchInputRef.current.value = _address.plain();
        }
      })
      .catch((error) => {
        console.error("Error fetching address:", error);
      });
  };

  const extractAddress = (place: google.maps.places.PlaceResult): Address => {
    const address: Address = {
      area: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        const area = this.area ? this.area + ", " : "";
        const city = this.city ? this.city + ", " : "";
        const zip = this.zip ? this.zip + ", " : "";
        const state = this.state ? this.state + ", " : "";
        return area + city + zip + state + this.country;
      },
    };

    if (!Array.isArray(place?.address_components)) {
      return address;
    }

    place.address_components?.forEach((component) => {
      const types = component.types;
      const value = component.long_name;

      if (types.includes("locality")) {
        address.city = value;
      }

      if (types.includes("administrative_area_level_1")) {
        address.state = value;
      }

      if (types.includes("postal_code")) {
        address.zip = value;
      }

      if (types.includes("country")) {
        address.country = value;
      }
    });

    return address;
  };

  const handleInputChange = () => {
    if (searchInputRef.current) {
      const inputValue = searchInputRef.current.value;

      if (inputValue.trim() !== "") {
        // Perform autocomplete suggestions based on the input value
        const autoCompleteService =
          new window.google.maps.places.AutocompleteService();
        autoCompleteService.getPlacePredictions(
          { input: inputValue },
          (predictions) => {
            setSuggestions(
              predictions?.map((prediction) => prediction.description)
            );
          }
        );
      } else {
        setSuggestions([]); // Clear suggestions when input is empty
      }
    }
  };

  useEffect(() => {
    loadAsyncScript(mapApiJs).then(() => initAutocomplete());
    findMyLocation(); // Fetch the user's location by default when entering the page
  }, []);

  const mobile_locat = () => {
    setModalVisible(true);
  };

  return (
    <div className="loac-res">
      <div
        className=""
        style={{
          display: "flex",
          alignItems: "center",
          padding: "6px",
        }}
      >
        <button onClick={findMyLocation} style={{ width: 30, height: 40 }}>
          <EnvironmentOutlined size={50} />
        </button>
        <input
          style={{ padding: 10, width: "80%" }}
          ref={searchInputRef}
          type="text"
          placeholder="Search location...."
          onChange={handleInputChange}
        />
        {/* Display suggestions */}
      </div>
    </div>
  );
};

export default LocationSearchRes;
