// SignUpForm.tsx
import React, { useState } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Link, Route } from "react-router-dom";
import axios from "axios";

// import "antd/dist/antd.css";

const Success: React.FC = () => {
 
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const Route = () => {
    window.location.reload();
  };

  return (
    <>
      <div className='success-head'>
        <div className='success-image'>
          <img
            src={"assets/images/success-check.png"}
            className='success-icon'
          />
        </div>
      </div>
      <div className='success-info'>
        <div className='successs-congrats'>Congrats!</div>
        <div className='success-data'>User logged in successfully</div>
        <div>
          <Form.Item>
            <button
              className="success-submit"
              type='submit'
              onClick={Route}>
              <span className='tearms'>Ok</span>
            </button>
          </Form.Item>
        </div>
      </div>
    </>
  );
};

export default Success;
