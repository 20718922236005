import { Button, Col, Form, Input, Row, Select, Modal, message } from "antd";
import Navbar from "../Navbar/Navbar";
import "./ListGarages.css";
import { useState } from "react";
import PinField from "react-pin-field";
import axios from "axios";
import cn from "classnames";

const ListGarages = () => {
  const { Option } = Select;
  const [otpModal, setOtpModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [otpPopup, setOtpPopup] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [error, setError] = useState(false);

  const onFinish = async (values: any) => {
    console.log(values);
    const garage_type = values.garageType === "4wheeler" ? "1" : "2";
    const requestData = {
      garageName: values.garageName,
      garageType: garage_type,
      garageOwnerName: values.garageOwnerName,
      email: values.email,
      address: values.address,
      phoneNumber: values.phoneNumber,
    };
    try {
      const response = await fetch("https://api.vecrep.com/addGarage", {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      console.log("API response:", data);

      if (data.responceCode === 200) {
        console.log(data);
        setOtpPopup(true);
        setOtpModal(true);
        setPhoneNumber(data.data.phoneNumber);
      } else if (data.responseCode === 400) {
        console.log("400", data.message);
      }
    } catch (error) {
      console.error("API Error:", error);
    }
  };
  const otpVerify = async () => {
    try {
      console.log(otp);
      const requestData = {
        otp: otp,
        phoneNumber: phoneNumber,
      };
      const response = await fetch(
        "https://api.vecrep.com/garageOtpVerification",
        {
          method: "POST",
          body: JSON.stringify(requestData),
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        setOtpPopup(false);
        console.log(data.data);
        setSuccess(true);
      } else if (data.responseCode === 400) {
        setError(data.message);
      }
    } catch (error) {
      console.error("OTP Verification Error:", error);
    }
  };
  const resendOtp = async () => {
    try {
      const requestData = {
        phoneNumber: phoneNumber,
      };
      const response = await fetch("https://api.vecrep.com/reSendGarageOtp", {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: {
          "Content-Type": "application/json",
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.message);
        message.success(data.message);
      } else {
        console.log(data.message);
      }
    } catch (error) {
      console.error("Resend OTP Error:", error);
    }
  };

  const handleSuccess = () => {
    setOtpModal(false);
    setSuccess(false);
  };

  console.log(typeof phoneNumber);
  const lastFourDigits = phoneNumber ? phoneNumber.toString().slice(-4) : "";

  const maskedPhoneNumber = "*******" + lastFourDigits;

  return (
    <div>
      <Navbar />

      <div className='d-main-container-list-garage'>
        <Row>
          <Col>
            <h1 className='d-list-garage-name'>List Garage</h1>
          </Col>
        </Row>

        <div className='d-list-garage-mini-container'>
          <div>
            <h1 className='d-list-garage-name-info'>Garage Information</h1>
            <Form
              name='basic'
              initialValues={{ remember: true }}
              onFinish={onFinish}
              // onFinishFailed={onFinishFailed}
              className=''>
              <Row className='d-list-garage-row'>
                <Col xl={10} xs={24} sm={24}>
                  <label className='d-list-garage-label'>Garage Name</label>
                  <Form.Item
                    name='garageName'
                    rules={[
                      {
                        required: true,
                        message: "Please enter your garage name",
                      },
                    ]}>
                    <Input
                      type='text'
                      placeholder='Name'
                      className='d-list-garage-input'
                      style={{ borderColor: "#b11226" }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={10} sm={24} xs={24}>
                  <label className='d-list-garage-label'>Address</label>

                  <Form.Item
                    name='address'
                    rules={[
                      {
                        required: true,
                        message: "Please enter your address",
                      },
                    ]}>
                    <Input
                      type='text'
                      placeholder='Address'
                      className='d-list-garage-input'
                      style={{ borderColor: "#b11226" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='d-list-garage-row'>
                <Col xl={10} sm={24} xs={24}>
                  <label className='d-list-garage-label'>Garage Type</label>

                  <Form.Item
                    name='garageType'
                    rules={[
                      { required: true, message: "Please select garage type" },
                    ]}>
                    <Select
                      placeholder='Select Garage Type'
                      style={{ height: "47px", borderColor: "#b11226" }}>
                      <Option value='2wheeler'>2 Wheeler</Option>
                      <Option value='4wheeler'>4 Wheeler</Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col xl={10} sm={24} xs={24}>
                  {/* <label style={{padding:15,fontWeight:500}}>Name</label>
                <Form.Item
                  name='phoneNumber'
                  rules={[
                    {
                      required: true,
                      message: "Please enter your phone Number!",
                    },
                  ]}>
                                 <Input type="text" placeholder="Name" style={{padding:15}}/>
 
                 
                </Form.Item> */}
                </Col>
              </Row>

              <h1 className='d-list-garage-name-info'>Garage Owner Details</h1>

              <Row style={{ justifyContent: "space-around" }}>
                <Col xl={10} xs={24} sm={24}>
                  <label className='d-list-garage-label'>Owner Name</label>

                  <Form.Item
                    name='garageOwnerName'
                    rules={[
                      { required: true, message: "Please enter owner name" },
                    ]}>
                    <Input
                      type='text'
                      placeholder='Name'
                      className='d-list-garage-input'
                      style={{ borderColor: "#b11226" }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={10} sm={24} xs={24}>
                  <label className='d-list-garage-label'>Phone Number</label>

                  <Form.Item
                    name='phoneNumber'
                    rules={[
                      {
                        required: true,
                        message: "Please enter your phone Number!",
                      },
                    ]}>
                    <Input
                      type='text'
                      placeholder='Number'
                      className='d-list-garage-input'
                      style={{ borderColor: "#b11226" }}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row className='d-list-garage-row'>
                <Col xl={10} sm={24} xs={24}>
                  <label className='d-list-garage-label'>Email</label>

                  <Form.Item
                    name='email'
                    rules={[
                      { required: true, message: "Please input your email" },
                    ]}>
                    <Input
                      type='email'
                      placeholder='Email'
                      className='d-list-garage-input'
                      style={{ borderColor: "#b11226" }}
                    />
                  </Form.Item>
                </Col>
                <Col xl={10} xs={24} sm={24}>
                  {/* <label style={{padding:15,fontWeight:500}}>Name</label>
   
                    <Form.Item
                      name='phoneNumber'
                      rules={[
                        {
                          required: true,
                          message: "Please enter your phone Number!",
                        },
                      ]}>
                                     <Input type="text" placeholder="Name" style={{padding:15}}/>
   
                     
                    </Form.Item> */}
                </Col>
              </Row>

              <Form.Item className='d-list-garage-button-container'>
                <button className='d-list-garage-button' type='submit'>
                  Submit
                </button>
              </Form.Item>
            </Form>
          </div>
          <div></div>
        </div>
      </div>
      <Modal
        visible={otpModal}
        onCancel={() => setOtpModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        {success ? (
          <>
            <div className='success-head'>
              <div className='success-image'>
                <img
                  src={"assets/images/success-check.png"}
                  className='success-icon'
                  alt='Success Icon'
                />
              </div>
            </div>
            <div className='success-info'>
              <div className='successs-congrats'>Congrats!</div>
              <div className='success-data'>
                Your Garage listing request send successfully
              </div>
              <div>
                <Form.Item>
                  <button
                    className='success-submit'
                    type='submit'
                    onClick={handleSuccess}>
                    <span className='terms'>Ok</span>
                  </button>
                </Form.Item>
              </div>
            </div>
          </>
        ) : (
          otpPopup && (
            <div>
              <div className='form-head'>
                <h1>VecRep.com</h1>
              </div>

              <div className='error-msg'>
                <p>{error}</p>
              </div>
              <Form
                name='otp'
                onFinish={() => otpVerify()}
                className='garageotp-form'>
                <div className='listgarage-otptitle'>Verify OTP</div>
                <div className='listgarage-otppara'>
                  OTP has sent to the phone number ends with {maskedPhoneNumber}
                </div>
                <div className='pin-field-container'>
                  <PinField
                    length={6}
                    className={cn("pin-field", {})}
                    onComplete={(result: string) => {
                      setOtp(result);
                    }}
                    autoFocus
                    // autoComplete='one-time-password'
                    validate={RegExp(/^[0-9]$/)}
                  />
                </div>

                <div className='listgarage-otpdesc'>
                  OTP not received ?{" "}
                  <span
                    className='listgarage-resendtext'
                    onClick={resendOtp}
                    style={{ cursor: "pointer" }}>
                    Resend
                  </span>
                </div>

                <div className='verify-button'>
                  <div>
                    <Form.Item>
                      <button type='submit' className='verify-otp-button'>
                        Verify
                      </button>
                    </Form.Item>
                  </div>
                </div>
              </Form>
            </div>
          )
        )}
      </Modal>
    </div>
  );
};
export default ListGarages;
