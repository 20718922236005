import React, { useState, forwardRef, useImperativeHandle } from "react";
import BookingPage from "./BookingPage";
interface MybookDataProps {
  mainData: Array<{
    bookingId: string;
    chooseTime: string;
    chooseDate: string;
    _id: string;
    serviceStatus: string;
    garageDetails: {
      garageName: string;
      address: string;
    };
  }>;
  activeButton: string;
  fetchCompleted: () => void;
  fetchCancelled: () => void;
  fetchUpcoming: () => void;
}

const MybookData = forwardRef(
  (
    {
      mainData,
      activeButton,
      fetchCancelled,
      fetchUpcoming,
      fetchCompleted,
    }: MybookDataProps,
    ref
  ) => {
    const [showBookingPage, setShowBookingPage] = useState(false);
    const [bookingPageData, setBookingPageData] = useState({
      apiData: "",
      servicestatus: "",
    });
    useImperativeHandle(ref, () => ({
      close,
    }));

    const close = () => {
      setShowBookingPage(false);
    };

    const fetchData = () => {
      fetchUpcoming();
      fetchCancelled();
      fetchCompleted();
    };

    const handleViewDetailsClick = (_id: string, serviceStatus: string) => {
      console.log("HandleView");
      // Set the state to show BookingPage and pass data
      setBookingPageData({ apiData: _id, servicestatus: serviceStatus });
      setShowBookingPage(true);
    };

    return (
      <div className='mybooking-contents'>
        {showBookingPage ? (
          <BookingPage
            apiData={bookingPageData.apiData}
            servicestatus={bookingPageData.servicestatus}
            close={close}
            Datafetch={fetchData}
          />
        ) : mainData && mainData.length > 0 ? (
          mainData.map(
            (
              {
                bookingId,
                chooseTime,
                chooseDate,
                _id,
                serviceStatus,
                garageDetails,
              },
              Index
            ) => (
              <div className='mybooking-code' key={Index}>
                <div className='code-image'>
                  <img
                    src='assets/images/myfavourites/code-image.png'
                    alt='image'
                  />
                </div>
                <div className='code-contents'>
                  <div className='service-col'>
                    <div className='service-name'>Service Name</div>
                    <div className='servicecol-bookid'>
                      Booking ID :{" "}
                      <span className='servicecol-id'>
                        {bookingId ? bookingId : "-"}
                      </span>
                    </div>
                  </div>
                  <div className='garage-col'>
                    <div className='garage-name'>
                      {garageDetails && garageDetails.garageName
                        ? garageDetails.garageName
                        : " "}
                    </div>
                    <div className='garage-rate'>
                      <div className='garage-rate-text'>4.0</div>
                      <div className='garage-rate-star'>
                        <img
                          src='assets/images/GarageDetails/review-star.png'
                          alt='rate-star'
                        />
                      </div>
                    </div>
                  </div>
                  <div className='location-col'>
                    <div className='location-icon'>
                      <img
                        src='assets/images/myfavourites/myfav-location.png'
                        alt='Location'
                      />
                    </div>
                    <div className='location-text'>
                      {garageDetails && garageDetails.address
                        ? garageDetails.address
                        : " "}
                    </div>
                  </div>
                  <div className='timeline-col'>
                    {chooseDate ? chooseDate : "-"}{" "}
                    {chooseTime ? chooseTime : "-"}
                  </div>
                  <div className='discount-col'>
                    <div
                      className={` ${
                        activeButton === "Upcoming"
                          ? "discount-percent"
                          : "discount-handling"
                      }`}>
                      {" "}
                      15% OFF{" "}
                    </div>
                    <div
                      className={` ${
                        activeButton === "Completed"
                          ? "discount-completed"
                          : "discount-handling"
                      }`}>
                      {" "}
                      Completed{" "}
                    </div>
                    <div
                      className={` ${
                        activeButton === "Cancelled"
                          ? "discount-cancelled"
                          : "discount-handling"
                      }`}>
                      {" "}
                      Cancelled{" "}
                    </div>
                    <div
                      className={` ${
                        activeButton === "Upcoming"
                          ? "location-col2"
                          : "discount-handling"
                      }`}>
                      <div className='location-icon2'>
                        <img
                          src='assets/images/myfavourites/myfav-location.png'
                          alt='Location'
                        />
                      </div>
                      <div className='location-text2'>
                        {garageDetails && garageDetails.address
                          ? garageDetails.address
                          : " "}
                      </div>
                    </div>
                    <div
                      className='view-details'
                      onClick={() =>
                        handleViewDetailsClick(_id, serviceStatus)
                      }>
                      View Details
                    </div>
                  </div>
                </div>
              </div>
            )
          )
        ) : (
          <div className='mybooking-error'>
            <div className='mybooking-message'>
              {" "}
              {activeButton ? activeButton : ""} Bookings are not available
            </div>
          </div>
        )}
      </div>
    );
  }
);

export default MybookData;
