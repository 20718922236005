import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import LocationSearch from "./LocationSearch";
import VehicleSearch from "./VechileSearch";
import KeyWordSearch from "./KeyWordSearch";
import { Col, Row } from "antd";
import { Avatar, Modal, Dropdown, Menu, Space } from "antd";

interface Coordinates {
  latitude: number;
  longitude: number;
}

interface MyObject {
  makeName: string;
  vehicleType: number;
  modelName: string;
  fuelType: string;
}

interface Address {
  area: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  plain(): string;
}

const SearchBar: React.FC = () => {
  const navigate = useNavigate();
  const [isModelPopup, setModelPopup] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string>("");
  const [selectedType, setSelectedType] = useState<string>("");
  const [responsiveData, setResponsiveData] = useState([]);
  const [locationData, setLocationData] = useState<{
    coords: Coordinates;
    address: Address;
  } | null>(null);
  const [vehicleData, setVehicleData] = useState<MyObject | null>(null);
  const [value, setValue] = useState<string>("");
  const handleSearch = (value: string, type: string) => {
    console.log(value);
    setSelectedValue(value);
    setSelectedType(type);
    console.log("Received value in parent:", value);
    // Do whatever you need to do with the selected value in the parent component
  };
  const split = value.split(",");
  const handleLocationChange = (coords: Coordinates, address: Address) => {
    // Do something with the coordinates and address in the parent component
    console.log("Received coordinates in parent:", coords);
    console.log("Received address in parent:", address);
    localStorage.setItem('currentCity', address.city);
    localStorage.setItem('currentArea', address.area);
    setLocationData({ coords, address });
  };

  const handleVehicleDetailsChange = (vehicleDetails: MyObject) => {
    // Do something with the vehicle details in the parent component
    console.log("Received vehicle details in parent:", vehicleDetails);

    setVehicleData(vehicleDetails);
  };

  const closeModalPopup = () => {
    setModelPopup(false);
  };

  const routeGarage = async (itemName: string) => {
    console.log('topheader listgarages', itemName);
    // setIsLoading(true);
    const vehicleType = itemName.split(" ");
    let vehicle;
    if(vehicleType[0] == 'Car') {
      vehicle = 1;
    }
    else if(vehicleType[0] == 'Bike') {
      vehicle = 2;
    }
    console.log('locationData  => ',locationData);
    const requestBody = {
      city: locationData?.address.city === undefined
      ? split.slice(0, 1)
      : locationData?.address.city || "",  // If city is undefined, an empty string will be used
      area: "",
      vechileType: vehicle || "",
      searchKey: itemName ? itemName : selectedValue || "",
      pageCount: 10,
      pageNumber: 0,
    };
    console.log('requestBodyrequestBody 164', requestBody);
    try {
      const response = await fetch("https://api.vecrep.com/fetchGaragesByService", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      console.log('@@@@@',response);
      if (response.ok) {
        const data = await response.json();
        console.log('fetchGaragesByService', data);
        if(data.data) {
            let searchKey = itemName.split(" ").map((word) => word.toLowerCase())
            .join("-");
            let city = (requestBody.city as string).split(" ").map((word) => word.toLowerCase())
            .join("-");
            let area = (data.data?.area as string).split(" ").map((word) => word.toLowerCase()).join("-");
            console.log(`/${city}/${searchKey}/${data.data?.area}/${data.data.garageId}`);
            // return;
            var URL = `/${city}/${searchKey}`;
            if(data.data?.area){
              URL += `/${area}`;
            } else {
              URL += '/-';
            }
            URL += `/${data.data.garageId}`;
            navigate(URL, {
              state: { apiData: data.data },
            });
        } else {
          setModelPopup(true);          
        }
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  }

  const handleSearchButtonClick = async (itemName: string) => {
    // console.log('topheader listgarages', selectedService);
    // setIsLoading(true);
    console.log(itemName);
    const vehicleType = itemName.split(" ");
    let vehicle = 3;
    if(vehicleType[0] == 'Car') {
      vehicle = 1;
    }
    else if(vehicleType[0] == 'Bike') {
      vehicle = 2;
    }
    console.log('locationData  => ',locationData);
    const requestBody = {
      city: locationData?.address.city === undefined
      ? split.slice(0, 1)
      : locationData?.address.city || "",  // If city is undefined, an empty string will be used
      
      area: locationData?.address?.area === undefined
      ? split.slice(0, 1)
      : locationData?.address?.area || "",

      vechileType: vehicle || "",
      searchKey: itemName ? itemName : selectedValue || "",
      pageCount: 10,
      pageNumber: 0,
    };
    console.log('requestBodyrequestBody 164', requestBody);
    try {
      const response = await fetch("https://api.vecrep.com/fetchGaragesByService", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });
      console.log('@@@@@');
      if (response.ok) {
        const data = await response.json();
        console.log('fetchGaragesByService', data.data.length);
        // return;
        let searchKey = itemName.split(" ").map((word) => word)
        .join("-") || null;
        let localStorageSearch = localStorage.getItem('searchKeyWord');
        if(localStorageSearch) {
          localStorageSearch = localStorageSearch.split(" ").map((word) => word)
          .join("-");
        }
        // return;
        if(searchKey == null) {
          searchKey = localStorageSearch;
        }
        console.log(`${requestBody.city}/${searchKey}`, data.data);
        // return;
        if(data.data.length > 0) {
          let area = (requestBody.area as string).split(" ").map((word) => word.toLowerCase()).join("-");
          var URL = "/" + requestBody.city;
          URL += (area) ? "/" + area : "";
          URL += (searchKey) ? "/"+ searchKey : "";
          navigate(URL, {
            state: { apiData: data.data },
          });
        } else {
          setModelPopup(true);          
        }
        
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      // setIsLoading(false);
    }
  }
  console.log(value);

  return (
    <>
    <div className="search">
      <div className="search_container">
        <Row className="mobile-bg">
          <h3>Find The Best For Your Vehicle</h3>
          <Col xl={8} lg={8} sm={12} xs={12} className="custom-padding">
            <LocationSearch
              onLocationChange={handleLocationChange}
              updateValue={setValue}
            />
          </Col>
          {/* <Col xl={5} lg={5} sm={24} xs={24} className="custom-padding">
            <VehicleSearch
              onVehicleDetailsChange={handleVehicleDetailsChange}
            />
          </Col> */}
          <Col xl={10} lg={10} sm={12} xs={12} className="custom-padding">
            <KeyWordSearch
              onSearch={handleSearch}
              handleSearchButtonClick={handleSearchButtonClick}
              routeGarage={routeGarage}
            />
          </Col>
          <Col xl={6} lg={6} sm={24} xs={24} className="custom-padding">
            <div className="input_box search">
              <button onClick={() => handleSearchButtonClick(selectedValue)}>
                Search
              </button>
            </div>
           </Col>
        </Row>
      </div>
    </div>

    <Modal
      visible={isModelPopup}
      onCancel={closeModalPopup}
      centered
      footer={null}
      className='custom-modal no-data-found'>
          
        <div className="no-garage-found">
          <h1> vecrep.com </h1>  
          <p>We are not serving in your city</p>
        </div>
    </Modal>
    </>
  );
};

export default SearchBar;
