import React, { useState, useEffect } from "react";
import "./Banner.css";
import {
  SearchOutlined,
  SettingOutlined,
  CarOutlined,
} from "@ant-design/icons";

interface KeyWordSearchProps {
  onSearch: (value: string, type: string) => void;
  handleSearchButtonClick: (itemName: string) => void;
  routeGarage: (value: string) => void;
}

interface GarageService {
  name: string;
  type: string;
  id: string;
}

const KeyWordSearch: React.FC<KeyWordSearchProps> = ({
  onSearch,

  handleSearchButtonClick,
  routeGarage,
}) => {
  const [inputValue, setInputValue] = useState<string>("");
  const [suggestions, setSuggestions] = useState<GarageService[]>([]);

  useEffect(() => {
    // Fetch data from the API and update suggestions
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://api.vecrep.com/gaageAndServiceList"
        );
        const data = await response.json();
        console.log(data, "liste");
        setSuggestions(data.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array to fetch data only once on component mount

  useEffect(() => {
    // Check if values exist in localStorage
    const key = localStorage.getItem("searchKeyWord");

    if (key != "undefined" && key) {
      // Parse the JSON string from localStorage and set the state
      setInputValue(key);
    }
  }, []);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    console.log('handle input change');
    setInputValue(event.target.value);
    // checkIfSetWorking(event.target.value);
  };

  const handleSuggestionClick = (selectedItem: GarageService) => {
    const selectedValue = selectedItem.name;
    const selectedType = selectedItem.type;
    console.log('suggestion click', selectedItem.name);
    
    setInputValue(selectedValue);
    console.log('handle suggestion click');

    // checkIfSetWorking(selectedValue);
    console.log('set input value', inputValue);
    onSearch(selectedValue, selectedType);
  };

  const checkIfSetWorking = (value: any) => {
    console.log('input  value before', value, inputValue);
    setInputValue(value);
    console.log('input  value after', value, inputValue);
  }

  const handleCustomInput = () => {
    // Handle custom input when user doesn't select from suggestions
    onSearch(inputValue, '');
  };

  const handle = (item: string, id: string, itemName: string) => {
    console.log("item",item);
    if (item === "service") {
      handleSearchButtonClick(itemName);
    } else {
      routeGarage(itemName);
    }
  };
  return (
    // Return the JSX for the component here
    <div className='input_box KeyWordSearch'>
      <SearchOutlined />
      <input
        type='text'
        placeholder='Search Garages, Services'
        value={inputValue}
        onChange={handleInputChange}
        // onBlur={handleCustomInput} // Add onBlur to handle custom input when user clicks outside the input
      />
      {inputValue && suggestions && suggestions.length > 0 ? (
        <div className='suggestions invisible-scrollbar'>
          {suggestions
            .filter((item) =>
              `${item.name} ${item.type}`
                .toLowerCase()
                .includes(inputValue.toLowerCase())
            )
            .map((item) => (
              <div
                key={item.id}
                className='Suggestion_box'
                onClick={() => handleSuggestionClick(item)}>
                <div className='image-suggestion'>
                  {item.type === "garage" ? (
                    <CarOutlined />
                  ) : (
                    <SettingOutlined />
                  )}
                </div>
                <div
                  className='content'
                  onClick={() => handle(item.type, item.id, item.name)}>
                  <h3 style={{ display: 'block' }}>{item.name}</h3>
                  <p style={{ marginTop: '0' }}>{item.type}</p>
                </div>
              </div>
            ))}
        </div>
      ) : null}
    </div>
  );
};

export default KeyWordSearch;
