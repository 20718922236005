import React from "react";
import "./Offer.css";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import Slider, { CustomArrowProps } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

interface Category {
  couponImage: string;
  name: string;
  // Add other properties as needed
}

interface ApiResponse {
  responseCode: number;
  data: {
    bestOffers: Category[];
  };
}

interface SliderSettings {
  infinite: boolean;
  speed: number;
  slidesToShow: number;
  slidesToScroll: number;
  nextArrow: React.ReactElement;
  prevArrow: React.ReactElement;
  autoplay: boolean;
  autoplaySpeed: number;
  arrows: boolean;
  dots: boolean;
  responsive?: {
    breakpoint: number;
    settings: {
      slidesToShow: number;
      slidesToScroll: number;
      infinite: boolean;
      arrows: boolean;
      dots: boolean;
    };
  }[];
}

function Offer() {
  const [offersData, setOffersData] = React.useState<Category[]>([]);

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.vecrep.com/coupons");
        const data: ApiResponse = await response.json();
        console.log(data);
        if (data.responseCode === 200) {
          setOffersData(data.data.bestOffers);
        } else if (data.responseCode === 400) {
          console.log("error in list of categories");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const SamplePrevArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}>
        <LeftOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const SampleNextArrow: React.FunctionComponent<CustomArrowProps> = (
    props
  ) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", zIndex: 1 }}
        onClick={onClick}>
        <RightOutlined style={{ fontSize: "20px", color: "black" }} />
      </div>
    );
  };

  const settings: SliderSettings = {
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    arrows: false,
    dots: false,
    autoplay: true, // Enable autoplay
    autoplaySpeed: 1000,
    responsive: [
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          arrows: false,
          dots: false,
        },
      },
    ],
  };

  return (
    <div className='dashboard_offers'>
      <h2>Best Offers</h2>
      <div className='offers_container'>
        <Slider {...settings}>
          {offersData.map((offer, index) => (
            <div key={index} className='offer'>
              <div className='image_offer offers_img'>
                <img src={offer.couponImage} alt='' />
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

export default Offer;
