import { Button, Col, Form, Row, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import "./getForm.css";
import { useEffect, useState } from "react";

interface GetQuoteProps {
  vehicleData: {
    vehicleType: number;
    makeName: string;
    modelName: string;
    fuelType: string;

  };
  garageId: string;
  selectServices:string;
  onClose: () => void;
}

const CallGetForm: React.FC<GetQuoteProps> = ({
  vehicleData,
  selectServices,
  onClose,
  garageId,
}) => {
  console.log(vehicleData);
  const [successModal, setSuccessModal] = useState(false);
  const [success, setSuccess] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");

  useEffect(() => {
    getCustomer();
  }, []);

  const getCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/getSingleCustomer", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        const Data = data.data;
        setCustomerName(Data?.customerName);
        setCustomerEmail(Data?.email);
        setCustomerPhone(Data?.phoneNumber);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  console.log()

  const onFinish = async (values: any) => {
    console.log(values);
    console.log(garageId);
    console.log(vehicleData);
    const requestData = {
      // name: customerName,
      // phoneNumber: customerPhone,
      // email: customerEmail,
      // note: values.note,
      garageId: garageId,
      // serviceName:selectServices,
      // vehicleId:"",
      source:"Direct Call"


    };
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/addQuote", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        onClose();
        setSuccess(true);
        setSuccessModal(true);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const handleEmail = (e: any) => {
    setCustomerEmail(e.target.value);
  };
  const handlePhone = (e: any) => {
    setCustomerPhone(e.target.value);
  };
  const handleName = (e: any) => {
    setCustomerName(e.target.value);
  };

  const handleSuccess = () => {
    setSuccessModal(false);
    setSuccess(false);
  };
  const vehicleType = localStorage.getItem("vehicleType");
  const vehicleBrand = localStorage.getItem("brand");
  const vehicleModel = localStorage.getItem("model");
  const vehicleFuel = localStorage.getItem("fuelType");

  return (
    <div>
      <div className='form-head'>
        <h1>VecRep.com</h1>
      </div>

      <div className='quote-container'>
        <div className='quote-header'>Get Call</div>
        <div className='quote-desc'>
          Get the Call you want by requesting a Call
        </div>
        {/* <div className='vehicle-info'>
          {vehicleBrand === "undefined" ? "Maruti Suzuki" : `${vehicleBrand}`}
          {vehicleModel === "undefined" ? " / Swift" : ` / ${vehicleModel}`}
          {vehicleFuel === "undefined" ? " / petrol" : ` / ${vehicleFuel}`}
        </div> */}
      </div>

      <div className=''>
        <Row>
          <Col xl={24} lg={24} sm={24} xs={24}>
            <div>
              <Form
                name='basic'
                onFinish={onFinish}
                // onFinishFailed={onFinishFailed}
                className='signUp-form'>
                {/* <Form.Item
                  name='name'
                  rules={[
                    { required: false, message: "Please input your name!" },
                  ]}>
                  <div className='input-quote'>
                    <input
                      type='text'
                      defaultValue={customerName}
                      required
                      onChange={handleName}
                    />
                    <span>Name *</span>
                  </div>
                </Form.Item>
                <Form.Item
                  name='phoneNumber'
                  rules={[
                    {
                      required: false,
                      message: "Please enter your phone Number!",
                    },
                  ]}>
                  <div className='input-quote'>
                    <input
                      type='text'
                      defaultValue={customerPhone}
                      required
                      onChange={handlePhone}
                    />
                    <span>Phone number *</span>
                  </div>
                </Form.Item>
                <Form.Item
                  name='email'
                  rules={[
                    { required: false, message: "Please enter your email!" },
                    {
                      type: "email",
                      message: "Please enter a valid email address",
                    },
                  ]}>
                  <div className='input-quote'>
                    <input
                      type='email'
                      defaultValue={customerEmail}
                      onChange={handleEmail}
                    />
                    <span>Email</span>
                  </div>
                </Form.Item>
                <Form.Item name='note'>
                  <TextArea rows={4} placeholder='Enter your quote' />
                </Form.Item> */}

                <Form.Item  style={{}}>
                  <div className='quote-submit'>
                    <div>
                      <button type='submit' className='quote-submit-button' >
                        Submit
                      </button>
                    </div>
                  
                  </div>
                </Form.Item>
              </Form>
            </div>
          </Col>
          {/* <Col xl={8} lg={8}>
            <div className='get-container'>
              <div className='get-quote-image'>
                <img src='assets/images/get-quote.png' className='get-image' />
              </div>
            </div>
          </Col> */}
        </Row>
      </div>
      <Modal
        visible={successModal}
        onCancel={() => setSuccessModal(false)}
        centered
        footer={null}
        className='custom-modal'>
        {success && (
          <>
            <div className='success-head'>
              <div className='success-image'>
                <img
                  src={"assets/images/success-check.png"}
                  className='success-icon'
                  alt='Success Icon'
                />
              </div>
            </div>
            <div className='success-info'>
              <div className='successs-congrats'>Congrats!</div>
              <div className='success-data'>Request sent successfully</div>
              <div>
                <Form.Item>
                  <button
                    className='success-submit'
                    type='submit'
                    onClick={handleSuccess}>
                    <span className='terms'>Ok</span>
                  </button>
                </Form.Item>
              </div>
            </div>
          </>
        )}
      </Modal>
    </div>
  );
};
export default CallGetForm;
