import {
  CalendarFilled,
  CameraFilled,
  EditFilled,
  EnvironmentFilled,
  MailFilled,
  PhoneFilled,
} from "@ant-design/icons";
import { Avatar, Row, Col, Modal } from "antd";
import { useState, useEffect, useRef } from "react";
import EditProfile from "./EditProfile";
import { useNavigate } from "react-router-dom";
import "./myprofile.css";
const Myprofile = () => {
  const [modal, showModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [address, setAddress] = useState("");
  const [dob, setDob] = useState("");
  const [gender, setGender] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [imageurl, setImageurl] = useState("");
  const [day, setDay] = useState("");
  const [month, setMonth] = useState("");
  const [year, setYear] = useState("");

  const navigate = useNavigate();

  const modal1 = () => {
    showModal(true);
  };
  const close = () => {
    showModal(false);
  };

  useEffect(() => {
    getCustomer();
  }, []);

  const callcustomer = () => {
    getCustomer();
  };

  const getCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/getSingleCustomer", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        const Data = data.data;
        setAddress(Data?.address);
        setEmail(Data?.email);
        setDob(Data?.dateOfBirth);
        const dateParts = Data?.dateOfBirth.split("-");
        setDay(dateParts.length > 0 && dateParts[0]);
        setMonth(dateParts.length > 0 && dateParts[1]);
        setYear(dateParts.length > 0 && dateParts[2]);
        setGender(Data?.gender);
        setPhoneNumber(Data?.phoneNumber);
        setCustomerName(Data?.customerName);
        setImageurl(Data?.customerImage);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const fetchLogout = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/customerLogOut", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      console.log(data.message);
      localStorage.removeItem("token");
      navigate("/");
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div className='main-container-view-profile1'>
      <div style={{}} className='main-first-container'>
        <Row className='row-view-deatails'>
          <Col xl={20} sm={18} xs={18}>
            <div className='row-mini-container'>
              <div className='profile-picture'>
              <Avatar
  src={imageurl || (customerName ? customerName.charAt(2) : '')}
  shape='circle'
  className='avatar-class' style={{fontSize:30,fontWeight:"bold",backgroundColor:"#b11226"}}
>
  {customerName.charAt(0)}
</Avatar>


              </div>
              <div className='heading-view-details'>
                <h1 className='heading-view-font'>
                  {customerName ? customerName : ""}
                </h1>
              </div>
            </div>
          </Col>
          <Col xl={4} xs={6} sm={6}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 30,
              }}>
              {/* <div>
                    <h2 style={{color:"#b11226"}}>Forgot password</h2>
                </div> */}
              <div onClick={fetchLogout} className='logout-class'>
                <h2 className='logout-color' style={{ cursor: "pointer" }}>
                  Logout
                </h2>
              </div>
              <div>
                <button onClick={modal1} className='edit-view-detials'>
                  Edit <EditFilled />
                </button>
              </div>
            </div>
          </Col>
        </Row>

        <Modal
          visible={modal}
          onCancel={close}
          centered
          footer={null}
          className='custom-modal'>
          <EditProfile
            email={email}
            address={address}
            day={day}
            month={month}
            year={year}
            gender={gender}
            phoneNumber={phoneNumber}
            imageUrl={imageurl}
            customerName={customerName}
            callcustomer={callcustomer}
            close={close}
          />
        </Modal>

        <div className='main-container-view-profile'>
          <Row className='image-reverse'>
            <Col xl={16} sm={24} xs={24} style={{}}>
              <div
                className='view-details-card
'>
                <div>
                  <div
                    className='view-details-card-container
'>
                    <p
                      className='view-details-card-container-para
'>
                      <PhoneFilled
                        style={{
                          color: "grey",
                          paddingRight: 5,
                          transform: "rotate(110deg)",
                        }}
                      />
                      <span
                        className='span-element
                                                    '>
                        Mobile:-{" "}
                      </span>{" "}
                      <span style={{}}>{phoneNumber ? phoneNumber : ""}</span>
                    </p>
                    <hr />
                  </div>
                </div>
                <div>
                  <div
                    className='view-details-card-container
'>
                    <p className='view-details-card-container-para'>
                      <MailFilled style={{ color: "grey", paddingRight: 5 }} />
                      <span className='span-element'>Email:- </span>{" "}
                      {email ? email : ""} <span></span>
                    </p>
                    <hr />
                  </div>
                </div>
                <div>
                  <div
                    className='view-details-card-container
'>
                    <p className='view-details-card-container-para text-capital'>
                      <EnvironmentFilled
                        style={{ color: "grey", paddingRight: 5 }}
                      />
                      <span
                        className='span-element 
'>
                        Address:-{" "}
                      </span>{" "}
                      <span>{address ? address : ""}</span>
                    </p>
                    <hr />
                  </div>
                </div>
                <div>
                  <div
                    className='view-details-card-container
'>
                    <p className='view-details-card-container-para'>
                      <CalendarFilled
                        style={{ color: "grey", paddingRight: 5 }}
                      />
                      <span
                        className='span-element
'>
                        Dob:-
                      </span>{" "}
                      <span>{dob ? dob : ""}</span>
                    </p>
                    <hr />
                  </div>
                </div>
                <div>
                  <div className='view-details-card-container'>
                    <p className='view-details-card-container-para text-capital'>
                      <span className='span-element'>
                        {" "}
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='16'
                          height='18'
                          viewBox='0 0 14 21'
                          fill='none'>
                          <path
                            d='M13 1L8 6M13 1H9M13 1V5M5 14V20M2 17H8M1 9C1 10.0609 1.42143 11.0783 2.17157 11.8284C2.92172 12.5786 3.93913 13 5 13C6.06087 13 7.07828 12.5786 7.82843 11.8284C8.57857 11.0783 9 10.0609 9 9C9 7.93913 8.57857 6.92172 7.82843 6.17157C7.07828 5.42143 6.06087 5 5 5C3.93913 5 2.92172 5.42143 2.17157 6.17157C1.42143 6.92172 1 7.93913 1 9Z'
                            stroke='#79747E'
                            stroke-width='2'
                            stroke-linecap='round'
                            stroke-linejoin='round'
                          />
                        </svg>
                        Gender:-{" "}
                      </span>{" "}
                      <span>{gender ? gender : ""}</span>
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={6}>
              <div className='myprofile-image'>
                <img src='assets/images/myprofile.png' />
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div onClick={fetchLogout} className='logout-mobile-div'>
        <h2
          className='logout-color logout-mobile'
          style={{ cursor: "pointer" }}>
          Logout
        </h2>
      </div>
    </div>
  );
};
export default Myprofile;
