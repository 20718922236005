// Navbar.tsx
import React, { useState, useEffect } from "react";
import { NavLink, Link,useLocation } from "react-router-dom";
import SignUpForm from "../Auth/signup";
import LoginForm from "../Auth/Login";
import Success from "../Auth/Success";
import { Avatar, Modal, Dropdown, Menu, Space } from "antd";
// import "antd/dist/antd.css";
import "./Navbar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserPlus,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import {
  UserOutlined,
  CarFilled,
  BookOutlined,
  HeartFilled,
} from "@ant-design/icons";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Navbar: React.FC = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isSignupModalOpen, setisSignupModelOpen] = useState(false);
  const [logout, setLOgout] = useState(false);
  const [customerName, setCustomerName] = useState("");
  

  const token = localStorage.getItem("token");
  const showMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };
  const navigate = useNavigate();

  const showDrawer = () => {
    setisSignupModelOpen(true);
  };

  const onCloseSignupModal = () => {
    setisSignupModelOpen(false);
  };

  const showLoginModal = () => {
    setIsLoginModalOpen(true);
  };
  const openSignupModal = () => {
    setisSignupModelOpen(true);
  };
  const openLoginModal = () => {
    setIsLoginModalOpen(true);
  };

  const closeLoginModal = () => {
    setIsLoginModalOpen(false);
  };
  const getCustomer = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch("https://api.vecrep.com/getSingleCustomer", {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      console.log("API response:", data);
      if (data.responseCode === 200) {
        console.log(data.data);
        const Data = data.data;
        setCustomerName(Data?.customerName);
      } else {
        console.error("Error fetching data from the API");
        console.log("400");
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  useEffect(() => {
    if (token !== undefined) {
      getCustomer();
    }
  }, [token]);

  const capitalizeFirstLetter = (str: string) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  const location = useLocation();
  const { pathname } = location;
  const serviceArr = pathname.replace('/', '').split('/');
  var now = new Date();
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  console.log(serviceArr);
  if(serviceArr[0]) {
    var metaDescription = `Discover the top ${serviceArr[1].replaceAll('-', ' ')} Services in ${serviceArr[0]} (${months[now.getMonth()]} 2024) at Vecrep! Trust us for reliable repairs and maintenance. Book now for expert service!`;
    var metaTitle = `Best ${capitalizeFirstLetter(serviceArr[1].replaceAll('-', ' '))} Services In ${serviceArr[0]} (${months[now.getMonth()]} 2024) | Vecrep.com`;
  } else {
    var metaDescription = "Discover Vecrep: Your one-stop destination for premier car and bike services online. Easily book repairs, maintenance, and more, all from the convenience of your home";
    var metaTitle = "Top Car & Bike Services Online | Repair, Garage & More | Vecrep.com";
  }
  const items = [
    {
      label: (
        <div onClick={() => navigate("/myprofile")}>
          <UserOutlined /> My Profile
        </div>
      ),
      key: "0",
    },
    {
      label: (
        <div onClick={() => navigate("/myvehicle")}>
          <CarFilled /> My Vehicles
        </div>
      ),
      key: "1",
    },
    {
      label: (
        <div onClick={() => navigate("/myfavourites")}>
          <HeartFilled /> My Favourites
        </div>
      ),
      key: "3",
    },
    {
      label: (
        <div onClick={() => navigate("/mybooking")}>
          <BookOutlined /> My Bookings
        </div>
      ),
      key: "4",
    },
  ];

  const items2 = [
    /*{
      label: (
        <li className='line'>
          <NavLink to='/' style={{ color: "black" }}>
            Home
          </NavLink>
        </li>
      ),
      key: "0",
    },
    {
      label: (
        <li className='line'>
          <NavLink to='' style={{ color: "black" }}>
            Blog
          </NavLink>
        </li>
      ),
      key: "1",
    },*/
    {
      label: (
        <li>
          <NavLink to='/listgarages' style={{ color: "black" }}>
            List Garage
          </NavLink>
        </li>
      ),
      key: "3",
    },
  ];

  return (
    <>
    <HelmetProvider>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="INDEX, FOLLOW"/>
        <meta name="viewport" content="width=device-width, initial-scale=1"/>    
        <title>{metaTitle }</title>
        <meta name="description" content={metaDescription} />
        <link rel="canonical" href={window.location.href} />
        
        // Organization
        <script type="application/ld+json">
            {JSON.stringify({
                "@context": "http://schema.org",
                "@type": "Organization",
                "url": "https://vecrep.com/",
                "name": "VecRep",
                "logo": "Logo URL",
                "address": {
                    "@type": "PostalAddress",
                    "addressLocality": "Chandigarh, Punjab",
                    "postalCode": "160002",
                    "streetAddress": "Plot No 57, Industrial Area Phase I"
                },
                "email": "vr.vecrep@gmail.com",
                "telephone": "+91-9056262909",
                "sameAs": [
                    "https://www.facebook.com/vrvecrep",
                    "https://twitter.com/VR_vecrep",
                    "https://www.instagram.com/vr_vecrep/",
                    "https://www.linkedin.com/company/vr-vecrep/",
                    "https://www.youtube.com/channel/UCmNtrCMVJqU7Ws2fKf1H7eg"
                ],
                "contactPoint": [{
                    "@type": "ContactPoint",
                    "telephone": "+91-9056262909",
                    "contactType": "Customer Service"
                }],
                "foundingDate": "2020",
                "founders": [
                    {
                        "@type": "Person",
                        "name": "Rajat Soni",
                        "id": "https://www.linkedin.com/in/rajat-soni7/"
                    },
                    {
                        "@type": "Person",
                        "name": "Kajal chaudhary",
                        "id": "https://www.linkedin.com/in/kajal-chaudhary-09ac/"
                    }
                ],
                "funder": [
                    {
                        "@type": "Corporation",
                        "legalName": "put name",
                        "url": "Put url",
                        "sameAs": "wikipedia link"
                    }
                ]
            })}
        </script>

        // Webpage
        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebPage",
            "name": metaTitle,
            "description": metaDescription,
            "url": window.location.href
          })}
        </script>

        <script type="application/ld+json">
            {JSON.stringify({
              "@context": "http://schema.org",
              "@type": "WebSite",
              url: "https://vecrep.com/",
              potentialAction: {
                "@type": "SearchAction",
                target: "https://vecrep.com/?s={vecrep}",
                "query-input": "required name=vecrep",
              },
            })}
          </script>
          
      </Helmet>
    </HelmetProvider>
    
      <nav className="navbar">
        <div className='logo'>
          <Dropdown
            overlay={
              <Menu>
                {items2.flatMap((item, index, array) =>
                  index < array.length - 1 ? (
                    [
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>,
                      <hr key={`separator${index}`} />,
                    ]
                  ) : (
                    <Menu.Item key={item.key}>{item.label}</Menu.Item>
                  )
                )}
              </Menu>
            }
            trigger={["click"]}>
            <a onClick={(e) => e.preventDefault()}>
              <div>
                <Space>
                  <div className='menu-icon'>&#9776;</div>
                </Space>
              </div>
            </a>
          </Dropdown>
          <a onClick={() => navigate("/")} className="navbar-brand">
            <img src="/assets/images/logo2.png" width={100} height={50} className="d-inline-block align-top" />
          </a>
        </div>
        <div className='navigation'>
          <ul className={`navbar ${isMobileMenuOpen ? "open" : ""}`}>
            {/* <li className='line'>
              <NavLink to='/'>Home</NavLink>
            </li>
            <li className='line'>
              <NavLink to=''>Blog</NavLink>
            </li> */}
            <li>
              <NavLink to='/listgarages'>List Garage</NavLink>
            </li>
          </ul>
          {token != undefined ? (
            <Dropdown
              overlay={
                <Menu>
                  {items.flatMap((item, index, array) =>
                    index < array.length - 1 ? (
                      [
                        <Menu.Item key={item.key}>{item.label}</Menu.Item>,
                        <hr key={`separator${index}`} />,
                      ]
                    ) : (
                      <Menu.Item key={item.key}>{item.label}</Menu.Item>
                    )
                  )}
                </Menu>
              }
              trigger={["click"]}>
              <a onClick={(e) => e.preventDefault()}>
                <div>
                  <Space>
                    <Avatar
                      style={{ backgroundColor: "grey", cursor: "pointer" }}
                      className='gx-size-40 gx-pointer gx-mr-3'
                      alt=''>
                      {customerName && customerName.charAt(0).toUpperCase()}
                    </Avatar>
                  </Space>
                </div>
              </a>
            </Dropdown>
          ) : (
            <div className='main'>
              <Link to='' onClick={showLoginModal}>
                Login
              </Link>
              {/* <Link to='' onClick={showDrawer}>
                Sign up
              </Link> */}
            </div>
          )}
        </div>
      </nav>

      <Modal
        visible={isSignupModalOpen}
        onCancel={onCloseSignupModal}
        centered
        footer={null}
        className='custom-modal'>
        <SignUpForm
          onClose={onCloseSignupModal}
          onOpenLoginModal={openLoginModal}
        />
      </Modal>
      <Modal
        visible={isLoginModalOpen}
        onCancel={closeLoginModal}
        centered
        footer={null}
        className='custom-modal'>
        <LoginForm
          onClose={closeLoginModal}
          onOpenSignupModal={openSignupModal}
        />
      </Modal>
    </>
  );
};

export default Navbar;
