export const carPopularSearches = [
    "Car Washing Near Me",
    "Car Service Near Me",
    "Car Tire Alignment Near Me",
    "Car Ac Repair Near Me",
    "Car Repair Near Me",
    "Car Ac Repair Near Me",
    "Car Detailing Near Me",
    "Car Painting Near Me",
    "Car Cleaning Near Me",
    "Car Denting Painting Near Me",
    "Car Modification Near Me",
    "Car Wheel Alignment Near Me",
    "Car Wheel Alignment Near Me",
    "Car Interior Cleaning Near Me",
    "Car Dry Cleaning Near Me",
    "Car Foam Washing Near Me",
    "CNG Cylinder Testing Near Me",
    "Car Ac Gas Filling Near Me",
    "Car Ppf Near Me",
    "Car Battery Jump Start Near Me",
    "Car Window Glass Repair Near Me",
    "Car Scanning Near Me",
    "Car Rubbing Polishing Near Me",
    "Car Ceramic Coating Near Me",
    "Car Central Locking Repair Near Me",
    "Car Headlight Repair Near Me",
    "Car Steam Wash Near Me",
    "Car Lock Repair Near Me",
    "Car Vacuum Cleaning Near Me",
    "Car Suspension Repair Near Me",
    "Car Shocker Repair Near Me",
    "Car Battery Replacement Near Me",
    "Car Restoration Near Me",
    "Car Balancing And Alignment Near Me",
    "Car Inspection Services Near Me",
    "Car Tyre Repair Near Me",
    "Anti Rust Coating For Cars Near Me",
    "Accidental Car Repair Near Me",
    "Car Engine Decarbonizing Near Me",
    "Car Cng Installation Near Me",
    "Car Tail Light Repair Near Me",
    "Car Clutch Repair Near Me",
    "Car Engine Scanning Near Me",
    "Prime Car Wash Near Me",
    "Car Gearbox Repair Near Me",
    "Car Brake Pad Replacement Near Me",
    "Pre-purchase Car Inspection Near Me",
    "Pre Trip Car Inspection Near Me",
  ];
  
  export const bikePopularSearches = [
    "Bike Service Near Me",
    "Bike Washing Near Me",
    "Bike Painting Near Me",
    "Bike Denting Near Me",
    "Bike Painting Near Me",
    "Bike Lamination Near Me",
    "Bike PPF Coating Near Me",
    "Bike Wrapping Near Me",
    "Bike Shocker Repair Near Me",
    "Bike Suspension Repair Near Me",
    "Bike Lock Repair Near Me",
    "Bike Teflon Coating Near Me",
    "Bike Engine Repair Near Me",
    "Bike Tail Light Repair Near Me",
    "Bike Tyre Replacement Near Me",
    "Bike Battery Repair Near Me",
    "Bike Brake Pad Repair Near Me",
    "Bike Restoration Near Me",
    "Bike Clutch Repair Near Me",
    "Bike Headlight Repair Near Me",
    "Accidental Bike Repair Near Me",
  ];